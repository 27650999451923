<template>
	<AppModal
		modal-id="confirmation"
		:confirm-label="confirmLabel"
		:cancel-label="cancelLabel"
		:title="title"
		:description="description"
		@confirm="onConfirm"
		@cancel="onCancel"
	/>
</template>

<script lang="ts" setup>
import AppModal from '../AppModal.vue';

defineProps({
	cancelLabel: {
		type: String,
		required: true,
	},
	confirmLabel: {
		type: String,
		required: true,
	},
	title: {
		type: String,
		required: true,
	},
	description: {
		type: String,
		required: true,
	},
});

const emit = defineEmits([
	'update:modelValue',
	'cancel',
	'confirm',
]);

const onCancel = () => {
	emit('cancel');
};

const onConfirm = () => {
	emit('confirm');
};
</script>
