import { isTrueValue } from '../utilities/Helpers';
import logger from '../plugins/Logger';
import { PartialRecord } from './PartialRecord';

export enum FeatureFlag {
	ADVANCED_EDIT = 'advanced_edit',
	HOW_CONFIDENT = 'how_convident',
	LOG_MISSING_NOTIFICATION = 'log_missing_notification',
	ACTIVITY_ORDER = 'activity_order',
	FAVORITE_TOPICS = 'favorite_topics'
}

export type FeatureFlags = Map<FeatureFlag, boolean>

export interface FeatureFlagsState {
	features: FeatureFlags;
}

const FEATURE_FLAG_ENV_MAP: PartialRecord<FeatureFlag, string> = {
	[FeatureFlag.ADVANCED_EDIT]: 'VITE_FEATURE_ADVANCED_EDIT',
	[FeatureFlag.HOW_CONFIDENT]: 'VITE_FEATURE_HOW_CONFIDENT',
	[FeatureFlag.LOG_MISSING_NOTIFICATION]: 'VITE_FEATURE_LOG_MISSING_NOTIFICATION',
	[FeatureFlag.ACTIVITY_ORDER]: 'VITE_FEATURE_ACTIVITY_ORDER',
	[FeatureFlag.FAVORITE_TOPICS]: 'VITE_FEATURE_FAVORITE_TOPICS',
};

export const composeFeatureFlagUrlParam = (featureName: FeatureFlag): string => `feature_${featureName}`;

export const composeFeatureFlagUrlParamFromEnum = (featureFlag: FeatureFlag): string => composeFeatureFlagUrlParam(featureFlag);

export const getFeatureStateFromUrlParam = (featureFlag: FeatureFlag): boolean | null => {
	const urlParams = new URLSearchParams(window.location.search);
	const featureParameter = urlParams.get(composeFeatureFlagUrlParamFromEnum(featureFlag));

	return featureParameter ? featureParameter === 'true' : null;
};

const FEATURE_FLAG_RULES: PartialRecord<FeatureFlag, () => boolean> = {
	[FeatureFlag.ADVANCED_EDIT]: () => {
		const urlParams = new URLSearchParams(window.location.search);
		const featureParameter = urlParams.get(composeFeatureFlagUrlParam(FeatureFlag.ADVANCED_EDIT));

		return featureParameter === 'true' || isTrueValue(import.meta.env.VITE_FEATURE_ADVANCED_EDIT);
	},
};

const createFeatureFlagsState = (): FeatureFlagsState => {
	const envVars = Object.keys(import.meta.env);

	const features = (Object.keys(FEATURE_FLAG_ENV_MAP) as (keyof typeof FEATURE_FLAG_ENV_MAP)[]).reduce((map, key) => {
		const customRule = FEATURE_FLAG_RULES[key];

		if (customRule) {
			map.set(key, customRule());
			return map;
		}

		const featureFlagUrlParamState = getFeatureStateFromUrlParam(key);

		if (featureFlagUrlParamState !== null) {
			map.set(key, featureFlagUrlParamState);
			return map;
		}

		const envVar = FEATURE_FLAG_ENV_MAP[key];

		if (!envVar || !envVars.includes(envVar)) {
			return map;
		}

		const keyValue = import.meta.env[envVar];

		if (typeof keyValue === 'undefined') {
			return map;
		}

		if (typeof keyValue === 'boolean') {
			map.set(key, keyValue);
			return map;
		}

		map.set(key, !!isTrueValue(keyValue));

		return map;
	}, new Map() as FeatureFlags);

	logger.info('🐸 Created feature flag state', features.entries());

	return {
		features,
	};
};

export const featureFlagsState: FeatureFlagsState = createFeatureFlagsState();

export const isFeatureFlagEnabled = (featureName: FeatureFlag) => {
	const feature = featureFlagsState.features.get(featureName);

	if (typeof feature !== 'boolean') {
		return false;
	}

	return feature;
};
