import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useActivitiesQuery } from '../composables/queries/useActivitiesQuery';
import { useActivitiesMutation } from '../composables/queries/useActivitiesMutation';

export const useActivitiesStore = defineStore('activities', () => {
	const { data, isFetching } = useActivitiesQuery();

	const activities = computed(() => data.value ?? []);

	const {
		createActivity: createActivityMutation,
	} = useActivitiesMutation();

	const createActivity = (name: string) => createActivityMutation.mutateAsync({ name });

	return {
		activities,
		isFetching,
		createActivity,
	};
});
