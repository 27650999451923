import { DateTime } from 'luxon';
import { PublicQuota } from './PublicQuota';

export interface QuotaBeat {
	month: DateTime;
	clientId: string;
	projectId: string;
	quotaInfoId: string;
	isExtra: boolean;
	isQuota: boolean;
	userId: string;
	userName: string;
	activityId: string;
	durationHours: number;
}

export type SupabaseQuotaBeat = {
	month: string,
	client_id: string,
	project_id: string,
	quota_info_id: string,
	is_extra: boolean,
	is_separate: boolean,
	user_id: string,
	user_name: string,
	activity_id: string,
	duration_hours: number,
};

export const createQuotaBeatFromSupabase = (quotaBeat: SupabaseQuotaBeat): QuotaBeat => ({
	month: DateTime.fromISO(quotaBeat.month).startOf('month'),
	clientId: quotaBeat.client_id,
	projectId: quotaBeat.project_id,
	quotaInfoId: quotaBeat.quota_info_id,
	isExtra: quotaBeat.is_extra,
	isQuota: quotaBeat.is_separate,
	userId: quotaBeat.user_id,
	userName: quotaBeat.user_name,
	activityId: quotaBeat.activity_id,
	durationHours: quotaBeat.duration_hours,
});

export const getTotalTimeInHours = (beats: QuotaBeat[]) => beats.reduce((prev, curr) => prev + curr.durationHours, 0);

export const getBeatsForQuota = (quota: PublicQuota, beats: QuotaBeat[]): QuotaBeat[] => beats.filter((beat) => {
	if (quota.isExtra !== beat.isExtra) {
		return false;
	}

	if (!quota.projectId) {
		return beat.clientId === quota.clientId;
	}

	return beat.clientId === quota.clientId && beat.projectId === quota.projectId && beat.projectId;
});
