<template>
	<div>
		<Multiselect
			ref="multiselectRef"
			v-bind="$attrs"
			:options="filteredOptions"
			:appearance="appearance"
			object
			searchable
			native-support
			:can-deselect="canDeselect"
			:filter-results="false"
			:classes="{
				container: {
					'relative mx-auto box-border cursor-pointer bg-transparent text-base flex justify-center items-center h-full outline-none text-center tracking-wider': appearance === 'small',
					'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer bg-transparent text-xl leading-none h-8 outline-none': appearance === 'regular',
					'relative w-full box-border cursor-pointer text-sm outline-none text-center tracking-wider h-7 mt-2 rounded-full': appearance === 'smallRounded',
				},
				containerDisabled: 'cursor-default bg-gray-100',
				containerOpen: 'rounded-b-none',
				containerOpenTop: 'rounded-t-none',
				containerActive: '',
				singleLabel: {
					'text-center flex h-full absolute inset-0 pointer-events-none bg-transparent leading-snug justify-center items-center mx-auto underline underline-offset-4 whitespace-normal w-full text-white text-opacity-50': appearance === 'small',
					'text-center flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug': appearance === 'regular',
					'bg-primary-400': isAnyOptionSelected,
					'text-center flex absolute px-3 py-1 left-1/2 transform -translate-x-1/2 pointer-events-none bg-primary-400 rounded-full leading-snug justify-center items-center whitespace-nowrap text-white text-opacity-60 text-sm top-0': appearance === 'smallRounded',
				},
				multipleLabel: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug',
				search: {
					'w-full absolute inset-0 outline-none underline underline-offset-4 appearance-none box-border border-0 text-base font-sans bg-transparent rounded text-center': appearance === 'small',
					'w-full absolute inset-0 outline-none appearance-none box-border border-0 text-base phablet:text-lg font-sans bg-transparent rounded': appearance === 'regular',
					'flex inset-0 outline-none w-full bg-primary-400 rounded-full leading-snug justify-center items-center whitespace-normal text-white text-opacity-60 text-sm text-center h-7 top-[18px]': appearance === 'smallRounded',
				},
				tags: 'flex-grow flex-shrink flex flex-wrap mt-1 pl-2',
				tag: 'bg-green-500 text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap',
				tagDisabled: 'pr-2 !bg-gray-400 text-white',
				tagRemove: 'flex items-center justify-center p-1 mx-0.5 rounded-sm hover:bg-black hover:bg-opacity-10 group',
				tagRemoveIcon: 'bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60',
				tagsSearch: 'h-full border-0 outline-none appearance-none p-0 text-base phablet:text-lg font-sans mx-1 mb-1 box-border flex-grow flex-shrink',
				placeholder: {
					'placeholder text-base border-b border-solid border-white border-opacity-50 leading-none tracking-wider text-white text-opacity-50 truncate': appearance === 'small',
					'flex items-center h-full absolute left-0 pl-7 top-0 pointer-events-none bg-multiselect-lense bg-no-repeat leading-snug tracking-wider text-white text-opacity-50 truncate': appearance === 'regular',
					'absolute transform w-full h-full top-0 pointer-events-none rounded-full leading-snug flex justify-center items-center whitespace-normal text-white text-opacity-60 text-sm': appearance === 'smallRounded',
				},
				caret: {
					hidden: appearance === 'small' || appearance === 'smallRounded',
					'pointer-events-none bg-multiselect-caret bg-center text-white bg-no-repeat w-5 h-2.5 py-px box-content relative z-10 flex-shrink-0 flex-grow-0 transition-transform transform': appearance === 'regular',
				},
				caretOpen: 'rotate-180',
				spinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0',
				dropdown: {
					'has-custom-scrollbar absolute top -left-px -right-px -bottom-3 transform translate-y-full -mt-3 overflow-y-scroll z-[99999] bg-primary-300 flex flex-col rounded-lg max-h-[12rem]': appearance === 'small' || appearance === 'smallRounded',
					'has-custom-scrollbar absolute top -left-px -right-px -bottom-3 transform translate-y-full -mt-3 overflow-y-scroll z-[99999] bg-primary-300 flex flex-col rounded-lg max-h-[25rem]': appearance === 'regular',
				},
				dropdownTop: '-translate-y-full top-px bottom-auto flex-col-reverse rounded-b-none rounded-t',
				dropdownHidden: 'hidden',
				options: 'flex flex-col p-0 m-0',
				optionsTop: 'flex-col-reverse',
				option: {
					'flex items-center box-border cursor-pointer leading-snug px-3 border-b-2 border-solid border-primary-500 border-opacity-10 text-[14px] py-2 text-center justify-center truncate whitespace-normal': appearance === 'small' || appearance === 'smallRounded',
					'flex items-center box-border cursor-pointer phablet:text-lg leading-snug px-3 border-b border-solid border-primary-400 text-[14px] h-20 text-left justify-start': appearance === 'regular',
				},
				optionPointed: 'text-white bg-primary-500 bg-opacity-30',
				optionSelected: 'text-white bg-primary-500 bg-opacity-10',
				optionDisabled: 'text-gray-300 cursor-not-allowed',
				optionSelectedPointed: 'text-white bg-primary-500 bg-opacity-10 opacity-90',
				optionSelectedDisabled: 'text-gray-100 bg-primary-500  bg-opacity-10 bg-opacity-50 cursor-not-allowed',
				noOptions: 'py-2 px-3 text-gray-600 bg-transparent',
				noResults: 'py-2 px-3 text-gray-600 bg-transparent',
				fakeInput: 'bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent',
				spacer: 'h-9 py-px box-content',
				assist: 'hidden',
			}"
			@search-change="onSearchChange"
			@open="onOpen"
		>
			<template #option="{ option, isPointed }: { option: T, isPointed: (option: T) => boolean }">
				<slot
					name="optionAttributes"
					:attributes="option"
					:is-pointed="isPointed(option)"
				/>
			</template>
			<template #singlelabel="{ value }: { value: T }">
				<slot
					name="valueAttributes"
					:value="value"
				/>
			</template>
			<template
				#clear="{ clear }"
			>
				<button
					v-if="appearance !== 'small' && appearance !== 'smallRounded'"
					type="button"
					class="absolute right-0 top-1/2 transform -translate-y-1/2"
					@keydown.enter="clear"
					@mousedown.stop="clear"
				>
					<XMarkIcon class="bg-multiselect-remove bg-center bg-no-repeat rotate-90 w-2.5 h-2.5 box-content inline-block opacity-50 hover:opacity-30" />
				</button>
				<span v-else />
			</template>
		</Multiselect>
		<div
			class="w-full h-[2px]"
			:class="{
				hidden: appearance === 'small' || appearance === 'smallRounded',
				'bg-white bg-opacity-50': isOpen,
				'bg-primary-300': !isOpen,
			}"
		/>
	</div>
</template>

<script setup lang="ts" generic="T">
import Multiselect from '@vueform/multiselect';
import {
	computed, nextTick, PropType, ref, watch,
} from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/solid';

const props = defineProps({
	appearance: {
		type: String,
		default: 'regular',
	},
	options: {
		type: Array as PropType<T[]>,
		required: true,
	},
	canDeselect: {
		type: Boolean,
		default: false,
	},
	searchFunction: {
		type: Function as PropType<((query: string, options: T[]) => T[])>,
		default: undefined,
	},
});

const multiselectRef = ref<Multiselect>();

const filteredOptions = computed(() => (props.searchFunction ? props.searchFunction(multiselectRef.value?.search ?? '', props.options) : props.options));

const isOpen = computed(() => multiselectRef.value?.isOpen ?? false);

const isAnyOptionSelected = computed(() => !!multiselectRef.value?.hasSelected);

const focus = () => {
	multiselectRef.value?.activate();
	multiselectRef.value?.focus();
};

const onOpen = (instance: Multiselect) => {
	nextTick(instance.setPointerFirst);
};

const onSearchChange = (query: string, instance: Multiselect) => {
	nextTick(instance.setPointerFirst);
};

watch(() => props.options, () => {
	nextTick(multiselectRef.value?.setPointerFirst);
});

defineExpose({
	focus,
});
</script>

<style lang="scss" scoped>
:root {
    --ms-max-height: 25rem;
}

.is-hidden {
    display: none;
}

input:placeholder-shown {
    text-align: center;
}

input::placeholder {
    text-align: center;
}

input.placeholder {
    text-align: center;
    margin: 0 auto;
}

input[placeholder] {
    text-align: center;
}

input::-webkit-input-placeholder {
    text-align: center;
}

input:-moz-placeholder {
    text-align: center;
}

li>span {
    @apply truncate max-w-[180px];
}
</style>
