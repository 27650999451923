import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { err, ok } from 'neverthrow';
import { createNewActivity } from '@/api/activity';

export const useActivitiesMutation = () => {
	const queryClient = useQueryClient();

	const createActivity = useMutation({
		async mutationFn(
			variables: {
				name: string,
			},
		) {
			try {
				const response = await createNewActivity(variables.name);

				return ok(response);
			} catch (error) {
				return err(error);
			}
		},
		onSuccess(result) {
			if (result.isErr()) {
				return null;
			}

			return Promise.all([
				queryClient.invalidateQueries({ queryKey: ['activities'] }),
			]);
		},
	});

	return {
		createActivity,
	};
};
