import { useWindowFocus } from '@vueuse/core';
import { DateTime } from 'luxon';
import { ref, watchEffect } from 'vue';

export const useToday = () => {
	const today = ref(DateTime.now());

	const focused = useWindowFocus();

	watchEffect(() => {
		if (focused.value) {
			today.value = DateTime.now();
		}
	});

	return today;
};
