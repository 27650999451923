<template>
	<form>
		<div class="grid grid-cols-11 gap-x-4 relative">
			<div
				v-if="showHeader"
				class="col-span-3"
			>
				<div class="text-xs text-gray mb-1">
					Startdate
				</div>
			</div>

			<div
				v-if="showHeader"
				class="col-span-3"
			>
				<div class="text-xs text-gray mb-1">
					Enddate(optional)
				</div>
			</div>
			<div
				v-if="showHeader"
				class="col-span-3"
			>
				<div class="text-xs text-gray mb-1">
					Quota
				</div>
			</div>
			<div
				v-if="showHeader"
				class="col-span-1"
			/>
			<div class="col-span-3">
				<DatePicker
					v-model="startDate"
					class="custom-datepicker"
					mode="date"
					:popover="{ visibility: 'focus' }"
				>
					<template #default="{ inputValue, inputEvents }">
						<DateInput
							size="md"
							:model-value="inputValue"
							placeholder="dd.mm.yyyy"
							:disabled="quotaStart && todayDay >= quotaStart ? true : false"
							v-on="inputEvents"
						/>
					</template>
				</DatePicker>
			</div>

			<div class="col-span-3">
				<DatePicker
					v-model="endDate"
					class="custom-datepicker"
					mode="date"
					:min-date="startDate"
					:popover="{ visibility: 'focus' }"
				>
					<template #default="{ inputValue, inputEvents }">
						<DateInput
							size="md"
							:model-value="inputValue"
							placeholder="dd.mm.yyyy"
							v-on="inputEvents"
						/>
					</template>
				</DatePicker>
			</div>
			<div class="col-span-3 flex items-center space-x-2">
				<TimeTrackInput
					v-model="quotaInMs"
					appearance="xs"
					:unlimited="true"
					:disabled="quotaStart && todayDay >= quotaStart ? true : false"
				/>
				<span>h</span>
			</div>
			<div class="col-span-2">
				<div class="text-xs text-gray h-full flex items-center justify-end space-x-3">
					<button
						v-tippy="{ content: 'Save', theme: 'sunhealth-mt' }"
						type="button"
						class="fill-primary-100 hover:fill-primary-200"
						@click="onSubmit"
					>
						<CheckIcon
							class="w-5 h-5 object-contain focus:outline-none"
							tabindex="-1"
						/>
					</button>
					<button
						v-tippy="{ content: 'Cancel', theme: 'sunhealth-mt' }"
						type="button"
						class="fill-primary-100 hover:fill-primary-200"
						@click="cancel"
					>
						<TimesIcon
							class="focus:outline-none"
							tabindex="-1"
						/>
					</button>
				</div>
			</div>
			<div class="col-span-full">
				<div
					v-if="computedErrorMessage"
					class="text-xs text-secondary mt-1"
				>
					{{ computedErrorMessage }}
				</div>
			</div>
			<div
				v-if="showExtraOption"
				class="col-span-full mt-4"
			>
				<AppCheckbox v-model="isExtraRef">
					Project should be charged seperately
				</AppCheckbox>
			</div>
		</div>
	</form>
</template>

<script lang="ts" setup>
import { PropType, ref, watch } from 'vue';
import { useToday } from '@/composables/useToday';
import {
	Quota, QuotaSubmitData,
} from '@/models/Quota';
import { DatePicker } from 'v-calendar';
import { getDateTime } from '@/utilities/Helpers';
import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import { hourToMs, msToHours } from '@/shared/times';
import { useUnsavedChanges } from '@/composables/useUnsavedChanges';
import CheckIcon from '../assets/check-icon.svg?component';
import TimesIcon from '../assets/times-icon.svg?component';
import DateInput from './DateInput.vue';
import TimeTrackInput from './TimeTrackInput.vue';
import AppCheckbox from './AppCheckbox.vue';

import 'v-calendar/dist/style.css';

const props = defineProps({
	quota: {
		type: Object as PropType<Quota | null>,
		default: null,
	},
	activeQuotas: {
		type: Array as PropType<Quota[]>,
		default: () => ([]),
	},
	showHeader: {
		type: Boolean,
		default: false,
	},
	showExtraOption: {
		type: Boolean,
		default: false,
	},
	errorMessage: {
		type: String,
		default: '',
	},
	isExtra: {
		type: Boolean,
		default: false,
	},
});

const today = useToday();
const todayDay: string = today.value.startOf('day').toISODate() ?? '';
const quotaStart: string = props.quota?.from.startOf('day').toISODate() ?? '';
const computedErrorMessage = ref(props.errorMessage);
const isExtraRef = ref(false);

watch(() => props.errorMessage, (newErrorMessage) => {
	computedErrorMessage.value = newErrorMessage;
});

const emit = defineEmits<{
	(e: 'create', quota: QuotaSubmitData): void;
	(e: 'update', quota: QuotaSubmitData): void;
	(e: 'cancel'): void;
}>();

interface SubmitData {
	startDate: Date;
	endDate?: Date;
	quotaInMs: number;
}

const { meta, values, validate } = useForm<SubmitData>({
	validationSchema: yup.object({
		startDate: yup.date().required('Startdate is required'),
		endDate: yup.date().min(yup.ref('startDate')).nullable(),
		quotaInMs: yup.number().min(1, 'Quota is required').required('Quota is required'),
	}),
	initialValues: {
		startDate: props.quota ? getDateTime(props.quota.from).toJSDate() : undefined,
		endDate: props.quota?.till ? getDateTime(props.quota.till).toJSDate() : undefined,
		quotaInMs: props.quota?.monthlyHours ? hourToMs(props.quota.monthlyHours) : 0,
	},
});

const { value: startDate } = useField<string>('startDate');
const { value: endDate } = useField<string>('endDate');
const { value: quotaInMs } = useField<number>('quotaInMs');

const { toggle } = useUnsavedChanges();

watch(() => meta.value.dirty, toggle);

const cancel = () => {
	emit('cancel');
};

const onSubmit = async () => {
	const validationResult = await validate();

	if (!validationResult.valid) {
		[computedErrorMessage.value] = Object.values(validationResult.errors);
		return;
	}

	const startDateToSubmit = getDateTime(values.startDate);
	const endDateToSubmit = values.endDate ? getDateTime(values.endDate) : undefined;
	const monthlyHours = msToHours(Number(values.quotaInMs));

	if (props.quota?.quotaId) {
		emit('update', {
			monthlyHours,
			isExtra: isExtraRef.value,
			from: startDateToSubmit,
			till: endDateToSubmit,
		});
		return;
	}

	emit('create', {
		monthlyHours,
		isExtra: props.isExtra ? props.isExtra : isExtraRef.value,
		from: startDateToSubmit,
		till: endDateToSubmit,
	});
};
</script>
<style lang="scss" scoped>
:deep(.custom-datepicker) {
	.vc-container {
		@apply bg-primary-500 border-primary-800 rounded-lg z-50;

		.vc-title {
			@apply text-white;
		}

		.vc-weekday {
			@apply text-white;
		}

		.vc-day {
			.vc-day-content {
				@apply text-primary-900;
			}

			&.weekday-1,
			&.weekday-7 {
				.vc-day-content {
					@apply text-primary-800;
				}
			}

			&:hover {
				.vc-day-content {
					@apply text-white bg-primary-800;
				}

			}
		}

		.vc-arrow {
			@apply text-white;

			&:hover {
				@apply bg-primary-800;
			}
		}

		.vc-popover-caret {
			@apply bg-primary-500;
		}

		.vc-highlight {
			@apply bg-primary-100 #{!important};
		}
	}
}
</style>
