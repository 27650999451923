<template>
	<SidepanelWrapper>
		<div class="mb-10">
			<p class="text-2xl font-semibold">
				Add client
			</p>
		</div>
		<ClientForm
			:editable="true"
			@cancel="onCancelEdit"
			@save="onSave"
		/>
	</SidepanelWrapper>
</template>

<script setup lang="ts">
import ClientForm from '@/components/ClientForm.vue';
import SidepanelWrapper from '@/components/SidepanelWrapper.vue';
import { ClientMeta } from '@/models/Client';
import { useClientsStore } from '@/store/clients';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

const router = useRouter();

const onCancelEdit = () => {
	router.replace({ name: 'Admin' });
};

const toast = useToast();
const clientsStore = useClientsStore();

const onSave = async ({ name, description, isInternal }: ClientMeta) => {
	await clientsStore.createClient({ name, description, isInternal });
	toast.success(`Successfully created client "${name}"`);
	const newClient = clientsStore.allClients.find((client) => client.name === name);

	if (newClient) {
		router.replace({ name: 'Client', params: { clientId: newClient.id } });
	} else {
		router.replace({ name: 'Admin' });
	}
};
</script>
