import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useQuotaBeatsQuery } from '@/composables/queries/useQuotaBeatsQuery';
import { useToday } from '@/composables/useToday';
import { DateTime } from 'luxon';

export const useQuotaBeatsStore = defineStore('quotaBeats', () => {
	const today = useToday();
	const month = ref(today.value.month);
	const startOfPreviousMonth = computed(() => DateTime.now().set({ month: month.value }).minus({ month: 1 }).startOf('month'));
	const excludedTeams = ref<string[]>([]);

	const { data } = useQuotaBeatsQuery({
		fromDate: startOfPreviousMonth,
		excludedTeams,
	});

	const quotaBeats = computed(() => data.value ?? []);

	return {
		quotaBeats,
		excludedTeams,
	};
});
