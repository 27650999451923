<template>
	<button
		ref="buttonRef"
		v-click-pulse="color === 'transparent' ? '' : 'dark'"
		v-bind="$attrs"
		class="relative flex items-center box-border rounded-full transition-all duration-300 ease-in-out focus:outline-none outline-none disabled:cursor-not-allowed"
		:class="classObject"
		:disabled="isDisabled"
		:active="isActive"
		type="button"
		@click="onClick"
	>
		<span
			:class="{
				'flex-row-reverse': iconPosition === 'right',
			}"
			class="transition flex items-center transform relative whitespace-nowrap leading-none"
		>
			<AppIcon
				v-if="icon"
				:icon="icon"
				:class="{
					'mr-4': iconPosition === 'left' && label.length > 0,
					'ml-4': iconPosition === 'right' && label.length > 0,
				}"
			/>
			<slot name="default">{{ label }}</slot>
		</span>
	</button>
</template>

<script setup lang="ts">
import {
	computed, PropType, ref,
} from 'vue';
import * as Sentry from '@sentry/vue';
import type { IconType } from '@/models/Icon';
import AppIcon from './AppIcon.vue';
import vClickPulse from '../plugins/ClickPulseDirective';

type ButtonColor = 'primary' | 'gray' | 'transparent' | 'outline'

type ButtonSize = 'sm' | 'md' | 'base' | 'medium'

type ButtonWidth = 'full' | 'sm' | 'md'

type ButtonIconPosition = 'left' | 'right'

const colorClassMap: Record<ButtonColor, String> = {
	primary: 'bg-primary-100 focus:ring-2 focus:ring-offset-2 focus:ring-offset-primary-400 focus:ring-primary-200 hover:bg-primary-200 disabled:hover:bg-primary-100',
	gray: 'bg-primary-300 disabled:hover:bg-primary-300',
	transparent: 'bg-transparent disabled:hover:bg-transparent',
	outline: 'bg-transparent border-2 border-primary-800 hover:border-primary-900 disabled:hover:bg-transparent',
};

const sizeClassMap: Record<ButtonSize, String> = {
	base: 'p-2 text-sm tracking-wide font-regular',
	sm: 'p-2 text-sm tracking-wide font-semibold',
	md: 'py-4 text-md mobile:text-xl tracking-wide font-bold',
	medium: 'py-3 text-sm mobile:text-md tracking-wide font-bold',
};

const widthClassMap: Record<ButtonWidth, String> = {
	full: 'w-full',
	sm: 'px-4',
	md: 'px-6',
};

const props = defineProps({
	color: {
		type: String as PropType<ButtonColor>,
		default: 'primary',
	},
	size: {
		type: String as PropType<ButtonSize>,
		default: 'md',
	},
	width: {
		type: String as PropType<ButtonWidth>,
		default: 'md',
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	active: {
		type: Boolean,
		default: false,
	},
	label: {
		type: String,
		default: '',
	},
	icon: {
		type: String as PropType<IconType>,
		default: '',
	},
	iconPosition: {
		type: String as PropType<ButtonIconPosition>,
		default: 'left',
	},
});

const emit = defineEmits([
	'click',
]);

const buttonRef = ref<HTMLButtonElement>();

const isDisabled = computed(() => props.disabled);
const isActive = computed(() => props.active);

const colorClass = computed(() => colorClassMap[props.color]);
const sizeClass = computed(() => sizeClassMap[props.size]);
const widthClass = computed(() => widthClassMap[props.width]);

const classObject = computed(() => [
	{
		'button-disabled': isDisabled.value,
		'button-active': isActive.value,
		'justify-start': props.iconPosition === 'left',
		'justify-end': props.iconPosition === 'right',
		'justify-center': !props.icon,
	},
	sizeClass.value,
	widthClass.value,
	colorClass.value,
]);

const onClick = ($event: Event) => {
	if (!isDisabled.value) {
		emit('click', $event);

		Sentry.addBreadcrumb({
			category: 'Button',
			message: `Clicked ${props.label} Button`,
			level: 'info',
		});
	}
};
</script>

<style lang="scss" scoped>
.button-active {
	@apply bg-primary-300 focus:ring-0;
}

.button-disabled {
    @apply opacity-30 cursor-not-allowed;
}
</style>
