<template>
	<SidepanelWrapper>
		<SidepanelNavigation
			class="mb-10"
			:navigation="navigation"
		/>
		<RouterView
			v-if="project && client"
			:project="project"
			:client="client"
		/>
	</SidepanelWrapper>
</template>

<script setup lang="ts">
import SidepanelNavigation from '@/components/SidepanelNavigation.vue';
import SidepanelWrapper from '@/components/SidepanelWrapper.vue';
import { SidepanelNavigationItem } from '@/models/SidepanelNavigationItem';
import { useClientsStore } from '@/store/clients';
import { useProjectsStore } from '@/store/projects';
import { computed } from 'vue';

const props = defineProps({
	projectId: {
		type: String,
		required: true,
	},
});

const projectsStore = useProjectsStore();

const project = computed(() => projectsStore.allProjects.find((_project) => _project.id === props.projectId));

const clientsStore = useClientsStore();

const client = computed(() => clientsStore.allClients.find((_client) => _client.id === project.value?.clientId));

const navigation: SidepanelNavigationItem[] = [
	{
		name: 'General',
		to: { name: 'ProjectGeneral' },
	},
	{
		name: 'Quotas',
		to: { name: 'ProjectQuotas' },
	},
];
</script>
