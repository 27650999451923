import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { err, ok } from 'neverthrow';
import {
	archiveClientById, restoreClientById, createNewClient, updateClientById,
} from '@/api/client';
import { ClientMeta } from '@/models/Client';

export const useClientsMutation = () => {
	const queryClient = useQueryClient();

	const createClient = useMutation({
		async mutationFn(
			variables: {
				client: ClientMeta,
			},
		) {
			try {
				const response = await createNewClient(variables.client.name, variables.client.description ?? '');

				return ok(response);
			} catch (error) {
				return err(error);
			}
		},
		onSuccess(result) {
			if (result.isErr()) {
				return null;
			}

			return Promise.all([
				queryClient.invalidateQueries({ queryKey: ['clients'] }),
			]);
		},
	});

	const archiveClient = useMutation({
		async mutationFn(
			variables: {
				clientId: string,
			},
		) {
			try {
				const response = await archiveClientById(variables.clientId);

				return ok(response);
			} catch (error) {
				return err(error);
			}
		},
		onSuccess(result) {
			if (result.isErr()) {
				return null;
			}

			return Promise.all([
				queryClient.invalidateQueries({ queryKey: ['clients'] }),
				queryClient.invalidateQueries({ queryKey: ['projects'] }),
			]);
		},
	});

	const restoreClient = useMutation({
		async mutationFn(
			variables: {
				clientId: string,
			},
		) {
			try {
				const response = await restoreClientById(variables.clientId);

				return ok(response);
			} catch (error) {
				return err(error);
			}
		},
		onSuccess(result) {
			if (result.isErr()) {
				return null;
			}

			return Promise.all([
				queryClient.invalidateQueries({ queryKey: ['clients'] }),
			]);
		},
	});

	const updateClient = useMutation({
		async mutationFn(
			variables: {
				clientId: string,
				client: ClientMeta,
			},
		) {
			try {
				const response = await updateClientById(variables.clientId, {
					archived: variables.client.archived,
					description: variables.client.description,
					name: variables.client.name,
					is_internal: variables.client.isInternal,
					quota_info_id: variables.client.quotaInfoId,
				});

				return ok(response);
			} catch (error) {
				return err(error);
			}
		},
		onSuccess(result) {
			if (result.isErr()) {
				return null;
			}

			return Promise.all([
				queryClient.invalidateQueries({ queryKey: ['clients'] }),
			]);
		},
	});

	const addQuotaInfoToClient = useMutation({
		async mutationFn(
			variables: {
				clientId: string,
				quotaInfoId: string,
			},
		) {
			try {
				const response = await updateClientById(variables.clientId, { quota_info_id: variables.quotaInfoId });

				return ok(response);
			} catch (error) {
				return err(error);
			}
		},
		onSuccess(result) {
			if (result.isErr()) {
				return null;
			}

			return Promise.all([
				queryClient.invalidateQueries({ queryKey: ['clients'] }),
			]);
		},
	});

	return {
		createClient,
		archiveClient,
		restoreClient,
		updateClient,
		addQuotaInfoToClient,
	};
};
