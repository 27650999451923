<template>
	<VueFinalModal
		:modal-id="modalId"
		class="fixed h-fit w-fit bottom-4 right-4 phablet:right-10 desktop:right-12 widescreen:right-16"
		content-class="absolute bg-success text-primary-500 w-fit max-w-3xl rounded-xl px-4 py-8 shadow-modal bottom-0 right-0 outline-none"
		:content-transition="{
			enterFromClass: 'mobile:opacity-0 translate-y-full mobile:translate-y-0 mobile:scale-90',
			enterActiveClass: 'transition-all transform-gpu ease-smooth mobile:ease-bounce duration-300',
			enterToClass: 'translate-y-0',
			leaveFromClass: 'translate-y-0',
			leaveActiveClass: 'transition-all transform-gpu ease-smooth mobile:ease-bounce duration-200',
			leaveToClass: 'mobile:opacity-0 translate-y-full mobile:translate-y-0 mobile:scale-90',
		}"
		:hide-overlay="true"
		:esc-to-close="false"
		:teleport-to="null"
		background="interactive"
		:focus-trap="false"
		:lock-scroll="false"
		@before-open="onBeforeOpen"
		@opened="onOpened"
		@closed="onClosed"
		@update:model-value="$emit('update:modelValue', $event)"
	>
		<div class="flex items-center space-x-8 w-full h-full">
			<TodaysLogCard
				v-if="storesAreFetched"
				class="bg-primary-500 text-white min-w-[240px]"
				:beat="beat"
				:editable="false"
			/>
			<PlaceholderLogCard v-else />
			<div class="flex flex-col space-y-8 pr-4">
				<div class="text-[20px] leading-[1.15] font-bold flex items-center space-x-2 grow">
					<span>
						🤓 Log-Check
					</span>
					<TooltipIcon
						v-tippy="{ content: 'Im Rahmen der SunHealth OKRs erheben wir Daten zur Qualität der SunHealth Themen- und Topic-Struktur. Deine Angaben dabei sind 100% anonymisiert und werden nicht personenbezogen gespeichert.', theme: 'sunhealth-mt' }"
						class="cursor-help focus:outline-none"
					/>
				</div>
				<div class="grow">
					<div v-if="storesAreFetched">
						Überzeugt, dass dein Log für
						<span class="font-bold">
							{{ beatInformation }}
						</span>
						inhaltlich passt?
					</div>
					<div
						v-else
						class="space-y-1"
					>
						<AppPlaceholder class="w-full h-4" />
						<AppPlaceholder class="w-2/3 h-4" />
					</div>
				</div>
				<div class="flex items-center space-x-4 text-white grow">
					<AppButton
						color="gray"
						class="!text-base"
						@click="onConfirm"
					>
						Ja, überzeugt 👍🏼
					</AppButton>
					<AppButton
						color="gray"
						class="!text-base"
						@click="onUnsure"
					>
						Nicht 100% 🤔
					</AppButton>
				</div>
			</div>
		</div>
		<CloseIcon
			class="absolute top-2 right-2 fill-current/50 cursor-pointer"
			@click="emit('dismiss')"
		/>
	</VueFinalModal>
</template>

<script lang="ts" setup>
import { PropType, computed, onMounted } from 'vue';
import {
	Beat, getActivityFromBeat, getClientFromBeat, getProjectFromBeat,
} from '@/models/Beats';
import { useVfm, VueFinalModal } from 'vue-final-modal';
import { useMainStore } from '@/store/main';
import CloseIcon from '@/assets/close-icon.svg?component';
import TooltipIcon from '@/assets/tooltip-icon.svg?component';
import { useActivitiesStore } from '@/store/activities';
import { useClientsStore } from '@/store/clients';
import { useProjectsStore } from '@/store/projects';
import { useToast } from 'vue-toastification';
import AppButton from '../AppButton.vue';
import TodaysLogCard from '../TodaysLogCard.vue';
import PlaceholderLogCard from '../PlaceholderLogCard.vue';
import AppPlaceholder from '../AppPlaceholder.vue';

const props = defineProps({
	modalId: {
		type: String,
		required: true,
	},
	beat: {
		type: Object as PropType<Beat>,
		required: true,
	},
});

const emit = defineEmits<{
	(e: 'confirm', closeFn: () => void): void;
	(e: 'unsure'): void;
	(e: 'dismiss'): void;
	(e: 'update:modelValue', value: boolean): void;
	(e: 'update:loading', value: boolean): void;
	(e: 'before-open'): void;
	(e: 'opened'): void;
	(e: 'before-close'): void;
	(e: 'closed'): void;
}>();

const mainStore = useMainStore();
const clientsStore = useClientsStore();
const projectsStore = useProjectsStore();
const activitiesStore = useActivitiesStore();

const beatInformation = computed(() => `${getClientFromBeat(clientsStore.clients, props.beat)?.name} ${getProjectFromBeat(projectsStore.projects, props.beat)?.name} - ${getActivityFromBeat(activitiesStore.activities, props.beat)?.name}`);

const storesAreFetched = computed(() => !clientsStore.isFetching && !projectsStore.isFetching && !activitiesStore.isFetching);

const close = () => {
	emit('update:modelValue', false);
};

const onBeforeOpen = () => {
	mainStore.hasAnyModalsOpened = true;
};

const { modals } = useVfm();

const onOpened = () => {
	emit('opened');
};

const onClosed = () => {
	emit('closed');

	if (modals.length === 0) {
		mainStore.hasAnyModalsOpened = false;
	}

	emit('update:loading', false);
	mainStore.howConfidentBeatTimestamp = null;
};

const toast = useToast();

const onConfirm = () => {
	emit('update:loading', true);
	emit('confirm', close);

	mainStore.howConfidentBeatTimestamp = null;
	mainStore.startIconSnow('💜');

	toast.success('Vielen Dank für deine Teilnahme!');
};

const onUnsure = () => {
	emit('update:loading', true);
	emit('unsure');

	mainStore.howConfidentBeatTimestamp = null;
	mainStore.startIconSnow('💜');

	toast.success('Vielen Dank für deine Teilnahme!');
};

onMounted(() => {
	mainStore.howConfidentBeatTimestamp = props.beat.timestamp;
});
</script>
