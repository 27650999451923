import { useModal } from 'vue-final-modal';
import { ResultAsync } from 'neverthrow';
import ShareableModal from '@/components/modals/ShareableModal.vue';
import { useBeatsStore } from '@/store/beats';
import { DateTime } from 'luxon';
import { SharedBeat } from '@/models/SharedBeats';
import { useMainStore } from '@/store/main';
import { createBeatMetaFromSharedBeat } from '@/models/Beats';
import { usePlausible } from '../plugins/plausible/usePlausible';

export const useShareableModal = () => {
	const beatsStore = useBeatsStore();
	const mainStore = useMainStore();
	const plausible = usePlausible();

	const modal = useModal({
		component: ShareableModal,
	});

	const show = (sharedBeat: SharedBeat | null) => ResultAsync.fromPromise(new Promise((resolve, reject) => {
		if (!sharedBeat || !mainStore.user) {
			return;
		}

		const newBeatMeta = createBeatMetaFromSharedBeat(sharedBeat, mainStore.user.id);

		modal.patchOptions({
			attrs: {
				beat: newBeatMeta,
				modalId: 'shared-log',
				async onConfirm() {
					await beatsStore.createBeat(newBeatMeta);
					resolve(true);
					plausible.trackEvent('create-log', { props: { source: 'shared-log' } });
					modal.close();
				},
				async onConfirmToday() {
					await beatsStore.createBeat({
						...newBeatMeta,
						timestamp: DateTime.now(),
					});
					resolve(true);
					plausible.trackEvent('create-log', { props: { source: 'shared-log' } });
					modal.close();
				},
				onCancel() {
					reject();
					modal.close();
				},
			},
		});

		modal.open();
	}), () => new Error());

	return {
		show,
	};
};
