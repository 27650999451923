<template>
	<div>
		<div class="flex items-center group">
			<div
				id="checkbox"
				class="flex items-center justify-center w-6 h-6 rounded border border-solid flex-shrink-0 cursor-pointer"
				:class="[
					isChecked ? 'border-white' : 'border-primary-900 group-hover:border-white',
				]"
				@click="onClick"
				@keydown="onClick"
			>
				<IconSuccess
					class="checkbox-mark text-white"
					:class="[
						isChecked ? 'opacity-100' : 'opacity-0',
					]"
				/>
			</div>
			<div
				class="flex group-hover:text-white cursor-pointer pl-2"
				:class="[
					isChecked ? 'text-white' : 'text-primary-900',
				]"
				@click="onClick"
				@keydown="onClick"
			>
				<slot />
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import IconSuccess from '@/assets/icon-success.svg?component';

const props = defineProps({
	modelValue: {
		type: Boolean,
		default: false,
	},
});

const isChecked = computed(() => props.modelValue);

const emits = defineEmits(['update:modelValue']);

const onClick = () => {
	emits('update:modelValue', !props.modelValue);
};
</script>
