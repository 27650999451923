import { useMutation, useQueryClient } from '@tanstack/vue-query';
import {
	addClientToUserFavorites, addProjectToUserFavorites, removeClientFromUserFavorites, removeProjectFromUserFavorites,
} from '@/api/user';
import { useAuthentication } from '@/store/auth';
import { FavoriteTopics } from '@/models/FavoriteTopics';

export const useUserFavoriteTopicsMutation = () => {
	const authStore = useAuthentication();
	const queryClient = useQueryClient();

	const addClientToFavorites = useMutation({
		async mutationFn(
			variables: {
				clientId: string,
			},
		) {
			if (!authStore.user?.id) {
				throw new Error('Unauthorized');
			}

			return addClientToUserFavorites(authStore.user.id, variables.clientId);
		},
		async onMutate({ clientId }) {
			await queryClient.cancelQueries({ queryKey: ['userFavoriteTopics'] });

			const previousFavoriteTopics = queryClient.getQueryData<FavoriteTopics>(['userFavoriteTopics']) ?? { clients: [], projects: [] };
			const newFavoriteTopics: FavoriteTopics = { ...previousFavoriteTopics, clients: [...previousFavoriteTopics.clients, clientId] };

			queryClient.setQueryData(['userFavoriteTopics'], newFavoriteTopics);

			return { previousFavoriteTopics };
		},
		onError: (error, variables, context) => {
			queryClient.setQueryData(['userFavoriteTopics'], context?.previousFavoriteTopics);
		},
		onSettled() {
			return Promise.all([
				queryClient.invalidateQueries({ queryKey: ['userFavoriteTopics'] }),
			]);
		},
	});

	const addProjectToFavorites = useMutation({
		async mutationFn(
			variables: {
				projectId: string,
			},
		) {
			if (!authStore.user?.id) {
				throw new Error('Unauthorized');
			}

			return addProjectToUserFavorites(authStore.user.id, variables.projectId);
		},
		async onMutate({ projectId }) {
			await queryClient.cancelQueries({ queryKey: ['userFavoriteTopics'] });

			const previousFavoriteTopics = queryClient.getQueryData<FavoriteTopics>(['userFavoriteTopics']) ?? { clients: [], projects: [] };
			const newFavoriteTopics: FavoriteTopics = { ...previousFavoriteTopics, projects: [...previousFavoriteTopics.projects, projectId] };

			queryClient.setQueryData(['userFavoriteTopics'], newFavoriteTopics);

			return { previousFavoriteTopics };
		},
		onError: (error, variables, context) => {
			queryClient.setQueryData(['userFavoriteTopics'], context?.previousFavoriteTopics);
		},
		onSettled() {
			return Promise.all([
				queryClient.invalidateQueries({ queryKey: ['userFavoriteTopics'] }),
			]);
		},
	});

	const removeClientFromFavorites = useMutation({
		async mutationFn(
			variables: {
				clientId: string,
			},
		) {
			if (!authStore.user?.id) {
				throw new Error('Unauthorized');
			}

			return removeClientFromUserFavorites(authStore.user.id, variables.clientId);
		},
		async onMutate({ clientId }) {
			await queryClient.cancelQueries({ queryKey: ['userFavoriteTopics'] });

			const previousFavoriteTopics = queryClient.getQueryData<FavoriteTopics>(['userFavoriteTopics']);

			if (previousFavoriteTopics) {
				const newFavoriteClients = previousFavoriteTopics.clients.filter((client) => client !== clientId);
				const newFavoriteTopics: FavoriteTopics = { ...previousFavoriteTopics, clients: newFavoriteClients };
				queryClient.setQueryData(['userFavoriteTopics'], newFavoriteTopics);
			}

			return { previousFavoriteTopics };
		},
		onError: (error, variables, context) => {
			queryClient.setQueryData(['userFavoriteTopics'], context?.previousFavoriteTopics);
		},
		onSettled() {
			return Promise.all([
				queryClient.invalidateQueries({ queryKey: ['userFavoriteTopics'] }),
			]);
		},
	});

	const removeProjectFromFavorites = useMutation({
		async mutationFn(
			variables: {
				projectId: string,
			},
		) {
			if (!authStore.user?.id) {
				throw new Error('Unauthorized');
			}

			return removeProjectFromUserFavorites(authStore.user.id, variables.projectId);
		},
		async onMutate({ projectId }) {
			await queryClient.cancelQueries({ queryKey: ['userFavoriteTopics'] });

			const previousFavoriteTopics = queryClient.getQueryData<FavoriteTopics>(['userFavoriteTopics']);

			if (previousFavoriteTopics) {
				const newFavoriteProjects = previousFavoriteTopics.projects.filter((project) => project !== projectId);
				const newFavoriteTopics: FavoriteTopics = { ...previousFavoriteTopics, projects: newFavoriteProjects };
				queryClient.setQueryData(['userFavoriteTopics'], newFavoriteTopics);
			}

			return { previousFavoriteTopics };
		},
		onError: (error, variables, context) => {
			queryClient.setQueryData(['userFavoriteTopics'], context?.previousFavoriteTopics);
		},
		onSettled() {
			return Promise.all([
				queryClient.invalidateQueries({ queryKey: ['userFavoriteTopics'] }),
			]);
		},
	});

	return {
		addClientToFavorites,
		addProjectToFavorites,
		removeClientFromFavorites,
		removeProjectFromFavorites,
	};
};
