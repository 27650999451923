import { computed, onUnmounted, ref } from 'vue';
import { v4 as uuidv4 } from 'uuid';

const unsavedChangesState = ref<(number | string)[]>([]);

export interface UseUnsavedChangesOptions {
	id?: string | number;
}

export const useUnsavedChanges = (options?: UseUnsavedChangesOptions) => {
	const id = ref(options?.id ?? uuidv4());

	const set = () => {
		if (unsavedChangesState.value.includes(id.value)) {
			return;
		}

		unsavedChangesState.value.push(id.value);
	};

	const remove = () => {
		const index = unsavedChangesState.value.indexOf(id.value);

		if (index === -1) {
			return;
		}

		unsavedChangesState.value.splice(index, 1);
	};

	const toggle = (state: boolean) => {
		if (state) {
			set();
		} else {
			remove();
		}
	};

	onUnmounted(remove);

	const hasUnsavedChange = computed(() => unsavedChangesState.value.includes(id.value));

	const hasAnyUnsavedChanges = computed(
		() => unsavedChangesState.value.length > 0,
	);

	return {
		set,
		remove,
		toggle,
		hasUnsavedChange,
		hasAnyUnsavedChanges,
	};
};
