import { DateTimeUnit } from 'luxon';
import { getNearestLowerMultipleOf, percentageRatio } from '@/utilities/Helpers';
import { MINUTES_STEPS } from '@/utilities/Constants';
import { Database } from './Supabase';

export type LoggedDataType = 'everyone' | 'user' | 'team';

export type LoggedDataPeriod = Extract<DateTimeUnit, 'day' | 'week' | 'month' | 'year'>;

export interface LoggedData {
	type: LoggedDataType,
	totalLoggedInMinutes: number,
	dailyFullTimeInMinutes: number,
}

export type SupabaseLoggedData = Database['public']['Functions']['get_logged_data']['Returns'][number];

export const createLoggedDataFromSupabase = (data: SupabaseLoggedData): LoggedData => ({
	dailyFullTimeInMinutes: data.daily_full_time_in_m,
	type: data.label as LoggedDataType,
	totalLoggedInMinutes: data.total_logged_in_m,
});

export const getLoggedDataByType = (data: LoggedData[], type: LoggedDataType): LoggedData => {
	const match = data.filter((d) => d.type === type)[0];

	if (!match) {
		return {
			dailyFullTimeInMinutes: 0,
			totalLoggedInMinutes: 0,
			type,
		};
	}

	return match;
};

export const getLoggedDataInPercent = (data: LoggedData) => percentageRatio(getNearestLowerMultipleOf(data.dailyFullTimeInMinutes, MINUTES_STEPS), data.totalLoggedInMinutes) || 0;

export const getLoggedDataByTypeInPercentFromSupabase = (data: SupabaseLoggedData[], type: LoggedDataType): number => {
	const mappedLoggedData = data.map(createLoggedDataFromSupabase);
	const match = mappedLoggedData.filter((d) => d.type === type)[0];

	const loggedDataInPercent = getLoggedDataInPercent(match);

	if (!match) {
		return 0;
	}

	return loggedDataInPercent;
};
