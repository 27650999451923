import {
	createRouter, createWebHistory, RouteLocationNormalized, START_LOCATION,
} from 'vue-router';
import { Result, err, ok } from 'neverthrow';
import { useAuthentication } from '@/store/auth';
import { versioningTimer, appVersionChecker } from '../plugins/versioningTimer';
import { useMainStore } from '../store/main';
import { routes } from './routes';

const router = createRouter({
	history: createWebHistory(),
	routes,
});

const validateAccessForRoute = (isAuthenticated: boolean, route: RouteLocationNormalized): Result<true, Error> => {
	const requiresAuth = route.matched.some((r) => r.meta.requiresAuth);

	if (requiresAuth && !isAuthenticated) {
		return err(new Error('authentication.required'));
	}

	return ok(true);
};

const validateAdminAccessForRoute = (isAdminUser: boolean, route: RouteLocationNormalized): Result<true, Error> => {
	const requiresAdmin = route.matched.some((r) => r.meta.requiresAdmin);

	if (requiresAdmin && !isAdminUser) {
		return err(new Error('authentication.adminRequired'));
	}

	return ok(true);
};

router.beforeEach(async (to, from, next) => {
	const authStore = useAuthentication();

	if (from === START_LOCATION) {
		await authStore.initAuthentication();
	}

	const validationResult = validateAccessForRoute(authStore.isAuthenticated, to);

	if (validationResult.isErr()) {
		return next({ name: 'Signup' });
	}

	const adminValidtionResult = validateAdminAccessForRoute(authStore.isAdminUser, to);

	if (adminValidtionResult.isErr()) {
		return next({ name: 'Dashboard' });
	}

	return next();
});

router.beforeEach(async (to, from, next) => {
	if (from !== START_LOCATION) {
		await appVersionChecker.ensureLatestAppVersion(to.fullPath);
	}

	versioningTimer.runTimersCheck();

	next();
});

router.afterEach((to) => {
	const mainStore = useMainStore();
	mainStore.viewLayout = to.meta.layout || 'default';
});

export default router;
