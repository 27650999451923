import { MaybeRef, computed, unref } from 'vue';

export const useProgress = (progress: MaybeRef<number | number[]>) => {
	const _progress = computed(() => unref(progress));

	const validatedProgress = computed(() => {
		if (Array.isArray(_progress.value)) {
			const progressArray = [_progress.value[0], _progress.value[1]];

			if (typeof progressArray[0] !== 'number') {
				progressArray[0] = 0;
			}

			if (typeof progressArray[1] !== 'number') {
				progressArray[1] = 1;
			}

			if (progressArray[0] > progressArray[1]) {
				[, progressArray[0]] = progressArray;
			}

			return progressArray;
		}

		if (_progress.value > 100) {
			return 100;
		}

		if (_progress.value < 0) {
			return 0;
		}

		return _progress.value;
	});

	const currentProgress = computed(() => {
		if (Array.isArray(validatedProgress.value)) {
			return ((validatedProgress.value[0] * 100) / validatedProgress.value[1]).toFixed(0);
		}

		return validatedProgress.value;
	});

	const progressInPercent = computed((): number => {
		if (Array.isArray(validatedProgress.value)) {
			const percent = (validatedProgress.value[0] * 100) / validatedProgress.value[1];

			if (Number.isNaN(percent)) {
				return 0;
			}

			return percent;
		}

		return validatedProgress.value;
	});

	const currentProgressInPercent = computed(() => `${currentProgress.value}%`);

	const label = computed(() => {
		if (Array.isArray(validatedProgress.value)) {
			return `${validatedProgress.value[0]}/${validatedProgress.value[1]}`;
		}

		return currentProgressInPercent.value;
	});

	return {
		validatedProgress,
		currentProgress,
		currentProgressInPercent,
		progressInPercent,
		label,
	};
};
