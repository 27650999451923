import {
	ref, watch, type Ref, onMounted, onUnmounted,
} from 'vue';
import type { SelectOption } from '../../models/Select';

export const useTypeAhead = (
	options: Ref<SelectOption[]>,
	selectedOption: Ref<SelectOption | null>,
	select: (option: SelectOption) => void,
	closeDropdown: () => void,
	isActive: Ref<boolean>,
) => {
	const typeAheadPointer = ref(-1);

	watch(options, () => {
		typeAheadPointer.value = 0;
	});

	const setTypeAheadPointer = () => {
		const newOptions = options.value.map((o) => {
			if (typeof o === 'string') {
				return o;
			}
			return `${o.value}`;
		});

		const newSelectedOption =			typeof selectedOption.value === 'string'
			? selectedOption.value
			: `${selectedOption.value?.value}`;

		const index = newOptions.indexOf(newSelectedOption);
		typeAheadPointer.value = index;
	};

	const typeAheadUp = () => {
		if (typeAheadPointer.value >= options.value.length - 1) {
			typeAheadPointer.value = options.value.length - 1;
		} else {
			typeAheadPointer.value += 1;
		}
	};

	const typeAheadDown = () => {
		if (typeAheadPointer.value <= 0) {
			typeAheadPointer.value = 0;
		} else {
			typeAheadPointer.value -= 1;
		}
	};

	const selectOptionByIndex = (index: number) => {
		const option = options.value[index];

		if (option) {
			select(option);
		}
	};

	const onKeydown = (event: KeyboardEvent) => {
		if (!isActive.value) {
			return;
		}

		const mappings: Record<string, (e: KeyboardEvent) => void> = {
			ArrowUp: (e) => {
				e.preventDefault();
				return typeAheadDown();
			},
			ArrowDown: (e) => {
				e.preventDefault();
				return typeAheadUp();
			},
			Enter: () => {
				selectOptionByIndex(typeAheadPointer.value);
				closeDropdown();
			},
		};

		const mapped = mappings[event.key];

		if (typeof mapped !== 'function') {
			return;
		}

		mapped(event);
	};

	const hasEventListener = ref(false);

	watch(isActive, (listen) => {
		if (listen) {
			if (!hasEventListener.value) {
				window.addEventListener('keydown', onKeydown);
				hasEventListener.value = true;
			}
		} else {
			window.removeEventListener('keydown', onKeydown);
			hasEventListener.value = false;
		}
	});

	onMounted(() => {
		setTypeAheadPointer();
	});

	onUnmounted(() => {
		window.removeEventListener('keydown', onKeydown);
		hasEventListener.value = false;
	});

	return {
		typeAheadPointer,
		setTypeAheadPointer,
		typeAheadUp,
		typeAheadDown,
	};
};
