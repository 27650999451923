<template>
	<div class="flex w-full pb-10 px-10">
		<div class="p-2 font-bold">
			Durchschnittswerte
		</div>
		<AppChip
			v-for="period in periodSpans"
			:key="period"
			:selected="activeSpan === period"
			class="mx-5"
			@click="setPeriod(period)"
		>
			<span class="text-white">
				Letzte {{ period }} Monate
			</span>
		</AppChip>
	</div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { plausible } from '@/plugins/plausible/setup';
import AppChip from './AppChip.vue';

const props = defineProps({
	periodSpans: {
		type: Array<number>,
		default: () => ([]),
	},
	selectedPeriod: {
		type: Number,
		default: 6,
	},
});

const activeSpan = ref<number>(props.selectedPeriod);

const emit = defineEmits<{
	(e: 'selectPeriod', months: number): void;
}>();

const setPeriod = (months: number) => {
	plausible.trackEvent('quota-change-daterange', { props: { period: months } });
	activeSpan.value = months;
	emit('selectPeriod', months);
};

</script>
