<template>
	<div>
		<div class="flex items-center space-x-2 mb-5">
			<button
				type="button"
				class="font-bold text-white text-opacity-75 cursor-pointer group w-fit"
				@click="toggleTopic"
			>
				My top
				<strong class="group-hover:underline transition-all ease-in-out">
					{{ topicName }}
				</strong>
			</button>
		</div>
		<div v-if="topicEntries.length > 0">
			<div
				v-for="entry in topicEntries"
				:key="entry.id"
				class="mb-4 text-white text-opacity-50 tracking-wide overflow-x-hidden"
			>
				<div
					class="text-base leading-none transform ease-in-out duration-500"
				>
					{{ entry.name }}
				</div>
				<div class="flex items-center transition-all">
					<div
						class="h-1 rounded-full bg-success mr-2 transition-all ease-in-out duration-1000 animation-bar"
						:style="`width: ${getSpentTimeRatio(topicEntries[0], entry)}%`"
					/>
					<div
						v-animated-number.toPercentage="getBeatSpentTimeRatioRatioToAllTime(beats, entry) || 0"
						class="text-sm leading-none whitespace-nowrapp font-bold mr-1"
					/>
					(
					<div
						v-animated-number:[msToDisplayTime]="entry.timeIntervalInMs"
						class="text-sm leading-none whitespace-nowrap"
					/>)
				</div>
			</div>
		</div>
		<div
			v-else
			class="flex items-center"
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="23.997"
				height="23.997"
				viewBox="0 0 23.997 23.997"
				class="opacity-20 mr-2"
			>
				<path
					id="meh-solid"
					d="M12,8A12,12,0,1,0,24,20,12,12,0,0,0,12,8Zm-3.87,8.128A1.548,1.548,0,1,1,6.58,17.676,1.547,1.547,0,0,1,8.128,16.128Zm8.515,9.289H7.354a.774.774,0,0,1,0-1.548h9.289A.774.774,0,0,1,16.643,25.417Zm-.774-6.193a1.548,1.548,0,1,1,1.548-1.548A1.547,1.547,0,0,1,15.869,19.224Z"
					transform="translate(0 -8)"
					class="fill-current"
				/>
			</svg>
			<div class="opacity-50">
				No data to display yet
			</div>
		</div>
	</div>
</template>

<script setup lang="ts" generic="T extends EntryWithTime">
import { PropType } from 'vue';
import { Beat, getBeatSpentTimeRatioRatioToAllTime } from '@/models/Beats';
import { EntryWithTime, getSpentTimeRatio } from '@/models/EntryWithTime';
import { msToDisplayTime } from '../shared/times';
import vAnimatedNumber from '../plugins/animatedNumberDirective';

defineProps({
	beats: {
		type: Array as PropType<Beat[]>,
		default: () => ([]),
	},
	topicEntries: {
		type: Array as PropType<T[]>,
		default: () => ([]),
	},
	topicName: {
		type: String,
		default: '',
	},
});

const emit = defineEmits(['changeTopic']);

const toggleTopic = () => {
	emit('changeTopic');
};
</script>

<style lang="scss" scoped>
@keyframes expand {
	0% {
		transform: scaleX(0);
	}

	100% {
		transform: scaleX(1);
	}
}

.animation-bar {
	animation: expand 1000ms ease-in-out;
	transform-origin: left;
}
</style>
