<template>
	<div class="w-10 h-10 bg-primary-400 rounded-full flex items-center justify-center">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="16"
			viewBox="0 0 18 16"
			class="fill-error mb-1"
		>
			<path
				d="M7.773,6.4l.206,3.5a.375.375,0,0,0,.374.353H9.646a.375.375,0,0,0,.374-.353l.206-3.5A.375.375,0,0,0,9.852,6h-1.7a.375.375,0,0,0-.374.4ZM10.312,12A1.313,1.313,0,1,1,9,10.687,1.312,1.312,0,0,1,10.312,12ZM10.3.75a1.5,1.5,0,0,0-2.6,0l-7.5,13A1.5,1.5,0,0,0,1.5,16h15a1.5,1.5,0,0,0,1.3-2.25L10.3.75ZM1.662,14.219,8.837,1.781a.187.187,0,0,1,.325,0l7.175,12.437a.187.187,0,0,1-.162.281H1.824a.187.187,0,0,1-.162-.281Z"
				transform="translate(0 0)"
			/>
		</svg>
	</div>
</template>

<script setup lang="ts">
</script>
