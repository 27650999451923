import { computed } from 'vue';
import { useAuthentication } from '@/store/auth';
import { keepPreviousData, useQuery } from '@tanstack/vue-query';
import { getActivities } from '@/api/activity';

export const useActivitiesQuery = () => {
	const { isAuthenticated } = useAuthentication();

	const enabled = computed(() => isAuthenticated);

	const query = useQuery({
		queryKey: ['activities'],
		queryFn: () => getActivities(),
		enabled,
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: true,
	});

	return query;
};
