<template>
	<div
		class="flex flex-col"
		v-bind="$attrs"
	>
		<div
			class="text-xs phablet:text-sm font-normal tracking-wide"
			:class="{ 'text-secondary': highlight }"
		>
			<slot>
				{{ label }}
			</slot>
		</div>
		<span class="text-base phablet:text-xl text-white text-opacity-75 tracking-wide font-bold flex">
			<div v-animated-number:[formatNumber]="valueBindings" />{{ suffix }}
		</span>
	</div>
</template>

<script setup lang="ts">
import { PropType, computed } from 'vue';
import vAnimatedNumber, { AnimatedNumberDirectiveBindingOptions } from '../plugins/animatedNumberDirective';

const props = defineProps({
	label: {
		type: String,
		default: '',
	},
	value: {
		type: Number,
		default: 0,
	},
	formatter: {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		type: Function as PropType<(value: number) => string | number >,
		default: null,
	},
	suffix: {
		type: String,
		default: '',
	},
	highlight: {
		type: Boolean,
		default: false,
	},
	options: {
		type: Object as PropType<Partial<AnimatedNumberDirectiveBindingOptions>>,
		default: () => {},
	},
});

const formatNumber = (value: number) => {
	if (props.formatter) {
		return props.formatter(value);
	}

	return Math.round(value);
};

const valueBindings = computed(() => ({ value: props.value, ...props.options }));
</script>
