<template>
	<div
		class="border-b border-primary-800 py-3 px-4 grid grid-cols-12 gap-8 items-center w-full hover:cursor-pointer hover:bg-primary-300 last:border-0"
		:class="{ hidden: project.archived && !mainStore.showArchived, 'pointer-events-none': project.archived, 'bg-primary-300': isActive }"
		@click="onshowProjectPanel(project, client)"
		@keydown.enter="onshowProjectPanel(project, client)"
	>
		<div
			:class="{ 'opacity-40 line-through pointer-events-none': project.archived }"
			class="flex w-full whitespace-nowrap col-span-4 fullhd:col-span-5 pl-4"
		>
			<!-- eslint-disable vue/no-v-html -->
			<p
				class="min-w-0 truncate"
				v-html="DOMPurify.sanitize(highlight(project.name, searchValue))"
			/>
		</div>
		<div class="col-span-3 flex items-center justify-end space-x-4">
			<IconWarning
				v-if="currentQuotaHours && !hasQuotaForNextPeriod"
				v-tippy="{ content: 'Quota expires in the next two weeks', theme: 'sunhealth-mt' }"
				class="fill-warning"
			/>
			<IconExtraQuota
				v-if="quotasData[0]?.isExtra"
				class="text-success fill-current w-5 h-5"
			/>
			<div
				v-if="currentQuotaHours"
				class="flex items-center space-x-2"
			>
				<IconClock class="fill-primary-100" />
				<div class="text-primary-900">
					{{ hoursToDisplayTime(currentQuotaHours) }}h
				</div>
			</div>
		</div>
		<div class="flex space-x-2 col-span-5 fullhd:col-span-4 justify-end">
			<AppButton
				v-if="!project.archived"
				size="base"
				color="outline"
				class="h-8 font-normal"
				@click.stop="onArchiveProject(project)"
			>
				Archive
			</AppButton>
		</div>
	</div>
</template>

<script lang="ts" setup>
import * as Sentry from '@sentry/vue';
import { useMainStore } from '@/store/main';
import { Client } from '@/models/Client';
import { isActiveDuringSpecificDate } from '@/models/Quota';
import { Project } from '@/models/Project';
import { PropType, computed } from 'vue';
import IconClock from '@/assets/clock-icon.svg?component';
import IconExtraQuota from '@/assets/layer-plus-solid.svg?component';
import IconWarning from '@/assets/icon-warning.svg?component';
import { useToast } from 'vue-toastification';
import { useProjectsStore } from '@/store/projects';
import { useQuotasQuery } from '@/composables/queries/useQuotasQuery';
import { hoursToDisplayTime } from '@/shared/times';
import { useToday } from '@/composables/useToday';
import AppButton from '@/components/AppButton.vue';
import DOMPurify from 'dompurify';
import { getSafeArray, highlight } from '@/utilities/Helpers';

const props = defineProps({
	project: {
		type: Object as PropType<Project>,
		required: true,
	},
	client: {
		type: Object as PropType<Client>,
		required: true,
	},
	searchValue: {
		type: String,
		default: '',
	},
	isActive: {
		type: Boolean,
		default: false,
	},
});

const mainStore = useMainStore();
const projectsStore = useProjectsStore();
const toast = useToast();
const today = useToday();

const { data: quotasData } = useQuotasQuery(computed(() => ({
	quotaInfoId: props.project.quotaInfoId,
})));

const quotas = computed(() => getSafeArray(quotasData.value));

const activeQuota = computed(() => quotas.value.find((quota) => isActiveDuringSpecificDate(today.value, quota)));

const currentQuotaHours = computed(() => activeQuota.value?.monthlyHours);

const hasQuotaForNextPeriod = computed(() => {
	const startOfNextPeriod = today.value.plus({ days: 14 });
	const nextQuota = quotas.value.find((quota) => isActiveDuringSpecificDate(startOfNextPeriod, quota));
	return !!nextQuota;
});

const emit = defineEmits([
	'onArchiveProject',
	'click',
]);

const onshowProjectPanel = (project: Project, client: Client) => {
	emit('click', project, client);
	Sentry.addBreadcrumb({
		category: 'Button',
		message: 'Clicked showProjectPanel Button',
		level: 'info',
	});
};

const onArchiveProject = async (project: Project) => {
	await projectsStore.archiveProject(project.id);
	toast.success(`Successfully archived project "${project.name}"`);
};
</script>
