import { groupBy } from '../utilities/Helpers';
import { Beat } from './Beats';
import { EntryWithTime } from './EntryWithTime';
import { Database } from './Supabase';

export interface Client {
	id: string;
	name: string;
	description?: string;
	archived?: boolean;
	quotaInfoId?: string;
	isInternal: boolean;
}

export type ClientWithTime = Client & EntryWithTime;

export type ClientMeta = Omit<Client, 'id'>;

export type SupabaseClient = Database['public']['Tables']['clients']['Row'];

export const createClientFromSupabase = (client: SupabaseClient): Client => ({
	id: client.client_id,
	name: client.name,
	quotaInfoId: client.quota_info_id ?? undefined,
	archived: client.archived,
	description: client.description ?? '',
	isInternal: client.is_internal,
});

export const isClientActive = (client: Client): client is Client & { archived?: false } => client.archived === undefined || !client.archived;

export const isClientInternal = (client: Client) => client.isInternal === true;

export const findClientFromBeat = (clients: Client[], beat: Beat): Client | undefined => clients.find((client) => client.id === beat.clientId);

export const getClientsWithTimeFromBeats = (allBeats: Beat[], clients: Client[]): ClientWithTime[] => {
	const beatsWithClient = allBeats.filter((beat) => beat.clientId);
	const beatsGroupedByClient = groupBy(beatsWithClient, 'clientId');

	return Object.entries(beatsGroupedByClient)
		.map(([clientId, clientBeats]) => {
			const client = findClientFromBeat(clients, clientBeats[0]);
			return (
				{
					id: clientId,
					name: client?.name ?? 'NO NAME FOUND',
					timeIntervalInMs: clientBeats.reduce((prev, curr) => prev + curr.timeIntervalInMs, 0),
					quotaInfoId: client?.quotaInfoId,
					description: client?.description
						? client?.description
						: 'NO DESCRIPTION FOUND',
					isInternal: client?.isInternal ?? false,
				});
		});
};

export const sortClientsByTime = (clients: ClientWithTime[]): ClientWithTime[] => [...clients].sort((clientA, clientB) => clientB.timeIntervalInMs - clientA.timeIntervalInMs);

export const getTopClients = (beats: Beat[], clients: Client[], maxClients?: number): ClientWithTime[] => {
	const topClients = getClientsWithTimeFromBeats(beats, clients);
	const sortedClients = sortClientsByTime(topClients);
	if (maxClients !== undefined) {
		return sortedClients.slice(0, maxClients);
	}
	return sortedClients;
};
