import { DateTime } from 'luxon';
import { groupBy } from 'lodash';
import { luxonDateTimeSchema, notEmpty, percentageRatio } from '@/utilities/Helpers';
import { z } from 'zod';
import { msToDisplayTime } from '../shared/times';
import { Activity } from './Activity';
import { Client } from './Client';
import { Project } from './Project';
import { Database } from './Supabase';
import { EntryWithTime } from './EntryWithTime';
import { QuotaBeat } from './QuotaBeats';
import { SharedBeat } from './SharedBeats';

export const beatMetaSchema = z.object({
	timeIntervalInMs: z.number().min(1),
	archived: z.boolean().optional(),
	satisfaction: z.number().nullable(),
	note: z.string(),
	timestamp: luxonDateTimeSchema(),
	clientId: z.string(),
	projectId: z.string().nullable().optional(),
	activityId: z.string(),
	userId: z.string(),
	sharedBeatId: z.number().nullable(),
});

export type BeatMeta = z.infer<typeof beatMetaSchema>;

export interface Beat extends BeatMeta {
	beatId: string;
	user?: string,
}

export type BeatResolved = Beat & {
	client: string;
	project: string;
	activity: string;
};

export type SupabaseBeat = Database['public']['Tables']['beats']['Row'];

export const createEmptyBeatMeta = (userId: string, modifiedTimestamp?: DateTime): Partial<BeatMeta> => ({
	timeIntervalInMs: 0,
	archived: false,
	satisfaction: null,
	note: '',
	clientId: '',
	projectId: null,
	activityId: undefined,
	timestamp: modifiedTimestamp ?? DateTime.now(),
	userId,
	sharedBeatId: null,
});

export const createBeatMetaFromSharedBeat = (sharedBeat: SharedBeat, userId: string): BeatMeta => ({
	timeIntervalInMs: sharedBeat.timeIntervalInMs,
	satisfaction: null,
	note: sharedBeat.note,
	clientId: sharedBeat.clientId,
	projectId: sharedBeat.projectId,
	activityId: sharedBeat.activityId,
	timestamp: sharedBeat.timestamp,
	userId,
	sharedBeatId: sharedBeat.sharedBeatId,
});

export const isBeatValid = (beat: Partial<BeatMeta>): beat is BeatMeta => beatMetaSchema.safeParse(beat).success;

export interface CalendarAttribute {
	key: string,
	dates: Date,
	customData: {
		beat: Beat,
	},
}

export const createBeatFromSupabase = (beat: SupabaseBeat): Beat => ({
	beatId: beat.beat_id,
	note: beat.note ?? '',
	satisfaction: beat.satisfaction,
	activityId: beat.activity_id,
	clientId: beat.client_id,
	projectId: beat.project_id ?? undefined,
	timeIntervalInMs: beat.time_interval_in_ms ?? 0,
	timestamp: beat.timestamp ? DateTime.fromISO(beat.timestamp) : DateTime.now(),
	userId: beat.user_id ?? '',
	sharedBeatId: beat.beats_shared_id ?? null,
});

export const toSupabaseData = (beatMeta: BeatMeta): Omit<SupabaseBeat, 'beat_id' | 'created_at' | 'edit_at'> => ({
	activity_id: beatMeta.activityId ?? null,
	archived: false,
	client_id: beatMeta.clientId ?? null,
	note: beatMeta.note,
	project_id: beatMeta.projectId ?? null,
	satisfaction: beatMeta.satisfaction,
	time_interval_in_ms: beatMeta.timeIntervalInMs,
	timestamp: beatMeta.timestamp.toISO(),
	user_id: beatMeta.userId,
	beats_shared_id: beatMeta.sharedBeatId ?? null,
});

export const isBeatActive = (beat: Beat): boolean => beat.archived === undefined || !beat.archived;

export const getTotalTimeInMs = (beats: Beat[]) => beats.reduce((prev, curr) => prev + curr.timeIntervalInMs, 0);

export const totalTimeInDisplayTime = (beats: Beat[]) => msToDisplayTime(getTotalTimeInMs(beats));

export const getBeatsFromAttributes = (attributes: CalendarAttribute[]): Beat[] => attributes.map((attribute) => attribute.customData.beat);

export const getActivityFromBeat = (activities: Activity[], beat: Beat | BeatMeta | QuotaBeat): Activity | null => activities.find((activity) => activity.id === beat.activityId) ?? null;

export const getProjectFromBeat = (projects: Project[], beat: Beat | BeatMeta): Project | null => projects.find((project) => project.id === beat.projectId) ?? null;

export const getClientFromBeat = (clients: Client[], beat: Beat | BeatMeta): Client | null => clients.find((client) => client.id === beat.clientId) ?? null;

export const getBeatSpentTimeRatioRatioToAllTime = (allBeats: Beat[], topItem: EntryWithTime) => {
	const totalTime = allBeats.reduce((prev, curr) => prev + curr.timeIntervalInMs, 0);
	return percentageRatio(totalTime, topItem.timeIntervalInMs);
};

export const groupBeatsByClient = (beats: Beat[]): Record<string, Beat[]> => groupBy(beats, 'clientId');

export const groupBeatsByProject = (beats: Beat[]): Record<string, Beat[]> => groupBy(beats, 'projectId');

export const groupBeatsByActivity = (beats: Beat[]): Record<string, Beat[]> => groupBy(beats, 'activityId');

export const groupQuotaBeatsByActivity = (beats: QuotaBeat[]): Record<string, QuotaBeat[]> => groupBy(beats, 'activityId');

export const groupBeatsBySatisfaction = (beats: Beat[]): Record<string, Beat[]> => groupBy(beats, 'satisfaction');

export const groupBeatsByTopic = (beats: Beat[]): Record<string, Beat[]> => groupBy(beats, (beat) => [beat.clientId, beat.projectId, beat.activityId].filter(notEmpty));

export const groupBeatsByTopicResolved = (
	options: {
		beats: Beat[],
		projects: Project[],
		clients: Client[],
		activities: Activity[],
	},
): Record<string, Beat[]> => groupBy(options.beats, (beat) => {
	const client = getClientFromBeat(options.clients, beat);
	const project = getProjectFromBeat(options.projects, beat);
	const activity = getActivityFromBeat(options.activities, beat);

	return [client?.name, project?.name, activity?.name].filter(notEmpty).join(' - ');
});

export const resolveBeat = (options: {
	beat: Beat,
	projects: Project[],
	clients: Client[],
	activities: Activity[],
}): BeatResolved => {
	const client = getClientFromBeat(options.clients, options.beat)?.name ?? '-';
	const project = getProjectFromBeat(options.projects, options.beat)?.name ?? '-';
	const activity = getActivityFromBeat(options.activities, options.beat)?.name ?? '-';

	return {
		...options.beat,
		client,
		project,
		activity,
	};
};

export const sortBeatsByDuration = (beats: Beat[]): Beat[] => [...beats].sort((beatA, beatB) => beatB.timeIntervalInMs - beatA.timeIntervalInMs);
