<script setup lang="ts">
import DateRangePicker from '@/components/DateRangePicker.vue';
import PageTitle from '@/components/PageTitle.vue';
import TeamLogDataTable from '@/components/TeamLogDataTable.vue';
import { DateTime } from 'luxon';
import { ref } from 'vue';

const fromDate = ref(DateTime.now().minus({ days: 30 }).startOf('day'));
const toDate = ref(DateTime.now().startOf('day'));
</script>

<template>
	<div class="pt-6 space-y-10">
		<PageTitle>
			Admin Insights
		</PageTitle>
		<div class="space-y-6">
			<div class="flex justify-between items-center">
				<p class="text-2xl font-semibold">
					Team Log Quoten
				</p>
				<DateRangePicker
					v-model:from="fromDate"
					v-model:to="toDate"
				/>
			</div>
			<TeamLogDataTable
				:from-date="fromDate"
				:to-date="toDate"
			/>
		</div>
	</div>
</template>
