<template>
	<VueFinalModal
		:modal-id="modalId"
		class="fixed h-full w-full overflow-x-hidden"
		content-class="absolute inset-0"
		:content-transition="{
			enterFromClass: 'mobile:opacity-0 translate-y-full mobile:translate-y-0 mobile:scale-90',
			enterActiveClass: 'transition-all transform-gpu ease-smooth mobile:ease-bounce duration-300',
			enterToClass: 'translate-y-0',
			leaveFromClass: 'translate-y-0',
			leaveActiveClass: 'transition-all transform-gpu ease-smooth mobile:ease-bounce duration-200',
			leaveToClass: 'mobile:opacity-0 translate-y-full mobile:translate-y-0 mobile:scale-90',
		}"
		:esc-to-close="true"
		:teleport-to="false"
		@before-open="onBeforeOpen"
		@before-close="onBeforeClose"
		@opened="onOpened"
		@closed="onClosed"
		@update:model-value="$emit('update:modelValue', $event)"
	>
		<div class="relative top-0 mobile:py-16 phablet:py-32 px-0 mobile:px-8 phablet:px-16 min-h-screen">
			<div class="relative max-w-5xl bg-primary-300 z-10 rounded-lg px-6 mobile:px-12 py-20 shadow-modal top-0 mx-auto">
				<slot>
					<div
						v-for="release, index in releases"
						:key="release.id"
						class="mt-20 first:mt-0"
					>
						<hr
							v-if="index !== 0"
							class="my-20"
						>
						<h2 class="text-4xl font-bold mb-4">
							{{ release.name }}
						</h2>
						<div class="flex mb-8 desktop:mb-12">
							<div
								class="w-[20px] phablet:w-12 mt-2.5 phablet:mt-3 bg-primary-100 h-1 rounded-full mr-3"
							/>
							<span class="text-xl">
								{{ DateTime.fromISO(release.date).toLocaleString(DateTime.DATETIME_MED, { locale: 'de' }) }}
							</span>
						</div>
						<!-- eslint-disable vue/no-v-html -->
						<div
							class="markdown"
							v-html="DOMPurify.sanitize(marked(release.content, {
								breaks: true,
							}))"
						/>
					</div>
					<div class="flex items-center justify-center space-x-[32px] mt-8 desktop:mt-16 mx-auto w-full">
						<AppButton
							class="max-h-[44px] max-w-[232px] text-white"
							width="full"
							@click="onConfirm"
						>
							{{ confirmLabel }}
						</AppButton>
					</div>
				</slot>
				<TimesIcon
					class="absolute top-10 right-6 phablet:-top-16 phablet:-right-0 fill-current cursor-pointer"
					@click="emit('cancel')"
				/>
			</div>
			<div class="absolute inset-0 backdrop-filter backdrop-brightness-20 backdrop-blur-[10px] bg-primary-300/50" />
		</div>
	</VueFinalModal>
</template>

<script setup lang="ts">
import { useVfm, VueFinalModal } from 'vue-final-modal';
import { Release } from '@/models/Release';
import { PropType } from 'vue';
import { marked } from 'marked';
import { DateTime } from 'luxon';
import DOMPurify from 'dompurify';
import AppButton from '../AppButton.vue';
import { useMainStore } from '../../store/main';
import TimesIcon from '../../assets/times-icon.svg?component';

defineProps({
	releases: {
		type: Array as PropType<Release[]>,
		required: true,
	},
	confirmLabel: {
		type: String,
		required: true,
	},
	modalId: {
		type: String,
		required: true,
	},
});

const emit = defineEmits(['confirm', 'cancel', 'update:modelValue', 'update:loading', 'before-open', 'opened', 'before-close', 'closed']);

const mainStore = useMainStore();

const close = () => {
	emit('update:modelValue', false);
};

const onBeforeOpen = () => {
	emit('before-open');
	mainStore.hasAnyModalsOpened = true;
};

const onBeforeClose = () => {
	emit('before-close');
};

const onOpened = () => {
	emit('opened');
};

const { modals } = useVfm();

const onClosed = () => {
	emit('closed');

	if (modals.length === 0) {
		mainStore.hasAnyModalsOpened = false;
	}

	emit('update:loading', false);
};

const onConfirm = () => {
	emit('update:loading', true);
	emit('confirm', close);
};
</script>
