import {
	addTranslation, setLanguage, getHolidays, isSpecificHoliday, getHolidayByDate,
} from 'feiertagejs';
import { computed, Ref } from 'vue';
import { DateTime } from 'luxon';
import { LANGUAGE, COMPANY_REGION } from '../utilities/Constants';

// From Google Calendar

addTranslation(LANGUAGE, {
	NEUJAHRSTAG: 'New Year\'s Day',
	HEILIGEDREIKOENIGE: 'Epiphany',
	KARFREITAG: 'Good Friday',
	OSTERSONNTAG: 'Easter Sunday',
	OSTERMONTAG: 'Easter Monday',
	TAG_DER_ARBEIT: 'May Day',
	CHRISTIHIMMELFAHRT: 'Ascension Day',
	MARIAHIMMELFAHRT: 'Assumption of Mary',
	PFINGSTSONNTAG: 'Whit Sunday',
	PFINGSTMONTAG: 'Whit Monday',
	FRONLEICHNAM: 'Corpus Christi',
	DEUTSCHEEINHEIT: 'Day of German Unity',
	REFORMATIONSTAG: 'Reformation Day',
	ALLERHEILIGEN: 'All Saint\'s Day',
	BUBETAG: 'Repentance Day',
	ERSTERWEIHNACHTSFEIERTAG: 'Christmas Day',
	ZWEITERWEIHNACHTSFEIERTAG: 'Boxing Day',
});
setLanguage(LANGUAGE);

export const useFeiertage = (year: Ref<DateTime>) => {
	const bavariaHolidays = computed(() => getHolidays(year.value.year, COMPANY_REGION));
	const isCompanyHoliday = (isoDateString: string) => bavariaHolidays.value.some((holiday) => holiday.dateString === isoDateString);

	const renderHolidayContent = (isoDateString: string): string => {
		const date = new Date(isoDateString);
		const holiday = getHolidayByDate(date, COMPANY_REGION);
		if (!holiday) {
			return '';
		}
		const translatedHolidayName = holiday.translate(LANGUAGE);
		const isBavariaOnlyHoliday = !isSpecificHoliday(date, holiday.name, 'BUND');

		return isBavariaOnlyHoliday ? `${translatedHolidayName} (Bavaria)` : translatedHolidayName;
	};

	return {
		isCompanyHoliday,
		renderHolidayContent,
	};
};
