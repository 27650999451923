<template>
	<div>
		<div class="relative rounded-lg">
			<input
				ref="inputRef"
				v-model="inputValue"
				class="focus:ring-0 focus:outline-none appearance-none text-white bg-primary-300 rounded-md w-full px-2 text-2xl border border-primary-900 py-2 focus:border-white"
				:class="{
					'!border-secondary':
						isInvalid && (meta?.validated || meta?.touched),
				}"
				type="text"
				:name="name"
				:aria-label="name"
				:placeholder="placeholder"
				v-bind="$attrs"
				v-on="validationListeners"
			/>
		</div>
		<div
			v-if="errorMessage"
			class="text-xs text-secondary mt-1"
		>
			{{ errorMessage }}
		</div>
	</div>
</template>

<script lang="ts" setup>
import {
	ref, onMounted, computed, toRef, type PropType,
} from 'vue';
import { useFieldLazy } from '@/composables/useFieldLazy';
import { useField } from 'vee-validate';

const props = defineProps({
	modelValue: {
		type: String,
		default: undefined,
	},
	standalone: {
		type: Boolean,
		default: false,
	},
	rules: {
		// Should be type "RuleExpression" but vee-validate has no exported member
		type: Object as PropType<Parameters<typeof useField>[1]>,
		default: undefined,
	},
	placeholder: {
		type: String,
		default: '',
	},
	name: {
		type: String,
		required: true,
	},
	autofocus: {
		type: Boolean,
		default: false,
	},
});

defineEmits<{
	(e: 'update:modelValue', value: string): void;
}>();

const inputRef = ref<HTMLInputElement>();
const focusInput = () => {
	inputRef.value?.focus();
	inputRef.value?.select();
};

const {
	value: inputValue,
	validationListeners,
	errorMessage,
	meta,
} = useFieldLazy(
	toRef(props, 'name'),
	props.rules,
	{
		syncVModel: true,
		standalone: props.standalone,
	},
);

const isInvalid = computed(() => {
	if (!meta) {
		return false;
	}

	return (meta.validated || meta.touched) && !meta.valid;
});

const autoFocusInput = () => {
	if (!props.autofocus || props.modelValue !== '') {
		return;
	}

	const isTouchDevice = 'ontouchstart' in document.documentElement;

	if (window.innerWidth > 942 || !isTouchDevice) {
		focusInput();
	}
};

onMounted(autoFocusInput);
</script>

<script lang="ts">
export default {
	inheritAttrs: false,
};
</script>
