import { useLocalStorage } from '@vueuse/core';

/**
	some assumptions:
	the users standard work day is 7.75h
	we want 2 procs per 5 days, so 1 proc every 2.5 days
	so every 7.75h * 2.5 = 19.375
	users have to log only 80% of their working time: 19.375 * .8 = 15.5

	the following algorithm targets a 1/15.5h proc rate:
	for every log the users makes, find out how much big of a fraction the
	logged time is of the maximum cooldown and randomly check whether to display
	if no prompt is displayed, remember the logged time and add that to the next log

	e.g. user logs 3h:
	3h/15.5h = 19.4% chance of getting a proc
		> on proc: cool down for the next 15.5h - 3h = 12.5h
		> on miss: save the 3h to the elapsed pool

	user next logs 2h:
	(3h(pooled)+2h) / 15.5 = 32.3% chance of getting a proc
		> on proc: cool down for the next 15.5h - (3h + 2h) = 10.5h
		> on miss: add the 2h to the pool

	any time the pool exceeds 15.5h, reset the pool to plus the overflow, as a proc is then guaranteed
*/

const PROC_EVERY_HOURS = 18; // proc once per ~3 days

const elapsedTimePool = useLocalStorage<number>('HCP__elapsedTimePool', 0);
const isOnCooldown = useLocalStorage<boolean>('HCP__isOnCooldown', false);

export const shouldDisplayPopup = (loggedTimeHours: number) => {
	elapsedTimePool.value += loggedTimeHours;

	// if on cooldown, subtract 1 to make proc impossible (chance < 0)
	const chance = (elapsedTimePool.value / PROC_EVERY_HOURS) - (isOnCooldown.value ? 1 : 0);

	// on overflow: reset cooldown and pool
	if (elapsedTimePool.value > PROC_EVERY_HOURS) {
		elapsedTimePool.value -= PROC_EVERY_HOURS;
		isOnCooldown.value = false;
	}

	// roll the dice
	if (Math.random() <= chance) {
		// cool down happens only, when we procced by chance (chance <= 1), not when we overflow (chance > 1)
		if (chance <= 1) isOnCooldown.value = true;
		return true;
	}

	return false;
};
