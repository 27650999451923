import { Database } from './Supabase';

export interface QuotaInfoMeta {
	isExtra: boolean;
}

export interface QuotaInfo extends QuotaInfoMeta {
	quotaInfoId: string;
}

export type SupabaseQuotaInfo = Database['public']['Tables']['quota_infos']['Row'];

export const createQuotaInfoFromSupabase = (quotaInfo: SupabaseQuotaInfo): QuotaInfo => ({
	isExtra: quotaInfo.is_extra ?? false,
	quotaInfoId: quotaInfo.quota_info_id,
});
