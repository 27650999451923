<template>
	<VueFinalModal
		:modal-id="modalId"
		class="fixed h-full w-full"
		overlay-class="backdrop-filter backdrop-brightness-20 backdrop-blur-[10px] !bg-primary-300 !bg-opacity-50 h-full w-full"
		content-class="absolute mt-[206px] bg-primary-300 w-fit max-w-3xl rounded-lg p-8 shadow-modal mx-4 top-0 mx-auto left-0 right-0 outline-none"
		:content-transition="{
			enterFromClass: 'mobile:opacity-0 translate-y-full mobile:translate-y-0 mobile:scale-90',
			enterActiveClass: 'transition-all transform-gpu ease-smooth mobile:ease-bounce duration-300',
			enterToClass: 'translate-y-0',
			leaveFromClass: 'translate-y-0',
			leaveActiveClass: 'transition-all transform-gpu ease-smooth mobile:ease-bounce duration-200',
			leaveToClass: 'mobile:opacity-0 translate-y-full mobile:translate-y-0 mobile:scale-90',
		}"
		:overlay-transition="{
			enterFromClass: 'mobile:opacity-0',
			enterActiveClass: 'transition-all transform-gpu ease-smooth mobile:ease-bounce duration-300',
			enterToClass: 'opacity-100',
			leaveFromClass: 'opacity-100',
			leaveActiveClass: 'transition-all transform-gpu ease-smooth mobile:ease-bounce duration-200',
			leaveToClass: 'mobile:opacity-0',
		}"
		:esc-to-close="true"
		:teleport-to="false"
		@before-open="onBeforeOpen"
		@before-close="onBeforeClose"
		@opened="onOpened"
		@closed="onClosed"
		@update:model-value="$emit('update:modelValue', $event)"
	>
		<div class="grid grid-cols-2 items-center space-x-24 w-full p-6">
			<div class="flex flex-col col-span-1">
				<div class="text-[42px] leading-[1.15] font-bold">
					Möchtest du den Log übernehmen?
				</div>
				<div class="flex items-center justify-between my-8">
					<AppButton
						:disabled="!beat"
						size="medium"
						autofocus
						@click="onSubmit"
					>
						Annehmen & loggen
					</AppButton>
					<AppButton
						size="medium"
						color="outline"
						@click="emit('cancel')"
					>
						Abbrechen
					</AppButton>
				</div>
				<div>
					<span
						v-if="!isLogPeriodOver"
						class="text-sm block text-center"
					>
						Der Log wird für den <strong>
							{{ beat.timestamp.toLocaleString() }}
						</strong>
						erstellt
					</span>
					<span
						v-if="isLogPeriodOver"
						class="text-sm block text-center"
					>
						Der Log wird für <strong>heute</strong> erstellt
					</span>
				</div>
				<hr
					v-if="!isLogPeriodOver"
					class="text-gray my-4"
				>
				<div
					v-if="!isLogPeriodOver"
					class="justify-center my-0 mx-auto"
				>
					<AppButton
						size="medium"
						color="outline"
						@click="onConfirmToday"
					>
						Für heute loggen
					</AppButton>
				</div>
			</div>
			<div class="flex flex-col col-span-1 space-y-8">
				<TodaysLogCard
					v-if="beat"
					class="bg-primary-500 min-w-[240px]"
					:beat="beat"
					:editable="false"
				/>
				<PlaceholderLogCard	v-else />
			</div>
		</div>
		<TimesIcon
			class="absolute -top-8 -right-4 mobile:-top-16 mobile:-right-8 fill-current cursor-pointer"
			@click="emit('cancel')"
		/>
	</VueFinalModal>
</template>

<script lang="ts" setup>
import { PropType, computed } from 'vue';
import { BeatMeta } from '@/models/Beats';
import { useVfm, VueFinalModal } from 'vue-final-modal';
import { useMainStore } from '@/store/main';
import TimesIcon from '@/assets/times-icon.svg?component';
import { useToast } from 'vue-toastification';
import AppButton from '../AppButton.vue';
import TodaysLogCard from '../TodaysLogCard.vue';
import PlaceholderLogCard from '../PlaceholderLogCard.vue';

const props = defineProps({
	modalId: {
		type: String,
		required: true,
	},
	beat: {
		type: Object as PropType<BeatMeta>,
		required: true,
	},
});

const emit = defineEmits(['confirm', 'confirm-today', 'cancel', 'update:modelValue', 'update:loading', 'before-open', 'opened', 'before-close', 'closed']);

const mainStore = useMainStore();

const toast = useToast();

const isLogPeriodOver = computed(() => {
	if (props.beat.timestamp < mainStore.today.startOf('day').minus({ days: 7 })) {
		return true;
	}
	return false;
});

const close = () => {
	emit('update:modelValue', false);
};

const onBeforeOpen = () => {
	emit('before-open');
	mainStore.hasAnyModalsOpened = true;
};

const onBeforeClose = () => {
	emit('before-close');
};

const onOpened = () => {
	emit('opened');
};

const { modals } = useVfm();

const onClosed = () => {
	emit('closed');

	if (modals.length === 0) {
		mainStore.hasAnyModalsOpened = false;
	}

	emit('update:loading', false);
};

const onConfirm = () => {
	emit('update:loading', true);
	toast.success('You\'ve successfully logged time!');
	emit('confirm', close);
};

const onConfirmToday = () => {
	emit('update:loading', true);
	toast.success('You\'ve successfully logged time!');
	emit('confirm-today', close);
};

const onSubmit = isLogPeriodOver.value ? onConfirmToday : onConfirm;

</script>
