import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useAbsencesQuery } from '@/composables/queries/useAbsencesQuery';
import { useAbsencesMutation } from '@/composables/queries/useAbsencesMutation';
import { AbsenceMeta } from '@/models/Absence';
import { appProgressLoader } from '@/plugins/AppProgressLoader';
import { useMainStore } from '@/store/main';
import { useToday } from '@/composables/useToday';

export const useAbsencesStore = defineStore('absences', () => {
	const minYear = ref(useToday().value.year);

	const mainStore = useMainStore();

	const { data } = useAbsencesQuery(computed(() => ({
		user: mainStore.user,
		year: minYear.value,
	})));

	const {
		createAbsence: createAbsenceMutation,
		deleteAbsence: deleteAbsenceMutation,
	} = useAbsencesMutation();

	const absences = computed(() => data.value ?? []);

	const addNewAbsence = async (absence: AbsenceMeta) => {
		const hasAbsenceForThisUser = absences.value?.some((element) => element.date === absence.date && element.userId === absence.userId);

		if (!mainStore.user) {
			return;
		}

		if (hasAbsenceForThisUser) {
			return;
		}

		if (absence && absence.userId === mainStore.user.id) {
			if (mainStore.routerLoadingSegmentId) {
				appProgressLoader.finish(mainStore.routerLoadingSegmentId);
			}
		}
		mainStore.routerLoadingSegmentId = appProgressLoader.start();

		await createAbsenceMutation.mutateAsync({ absence, user: mainStore.user });

		appProgressLoader.finish(mainStore.routerLoadingSegmentId);
	};

	const removeAbsence = async (absenceId: string) => {
		if (mainStore.routerLoadingSegmentId) {
			appProgressLoader.finish(mainStore.routerLoadingSegmentId);
		}
		mainStore.routerLoadingSegmentId = appProgressLoader.start();

		await deleteAbsenceMutation.mutateAsync({ absenceId });

		appProgressLoader.finish(mainStore.routerLoadingSegmentId);
	};

	const requestAbsencesForYear = (year: number) => {
		if (year < minYear.value) {
			minYear.value = year;
		}
	};

	return {
		addNewAbsence,
		removeAbsence,
		absences,
		minYear,
		requestAbsencesForYear,
	};
});
