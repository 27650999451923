<template>
	<AppDropdownSelect
		ref="selectRef"
		v-bind="$attrs"
		:options="options"
		:appearance="appearance"
		:can-deselect="canDeselect"
		:search-function="getFilteredOptions"
	>
		<template #optionAttributes="{ attributes }">
			<div
				:class="{
					'w-full px-4 left-0 top-0 text-center h-7 pointer-events-none leading-snug flex justify-center flex-col': appearance === 'small' || appearance === 'smallRounded',
					'text-center flex items-center h-full left-0 top-0 pointer-events-none bg-transparent leading-snug max-w-[85%]': appearance === 'regular',
				}"
			>
				<p
					:class="{
						'py-1 px-4 rounded-full mx-auto whitespace-nowrap': appearance === 'small' || appearance === 'smallRounded',
						truncate: appearance === 'regular',
					}"
				>
					{{ attributes.name }}
				</p>
			</div>
		</template>
	</AppDropdownSelect>
</template>

<script setup lang="ts">
import { PropType, ref } from 'vue';
import { search } from 'fast-fuzzy';
import AppDropdownSelect from './AppDropdownSelect.vue';
import type { Activity } from '../models/Activity';

const props = defineProps({
	appearance: {
		type: String,
		default: 'regular',
	},
	canDeselect: {
		type: Boolean,
		default: false,
	},
	options: {
		type: Array as PropType<Activity[]>,
		required: true,
	},
});

const selectRef = ref();

const focus = () => {
	selectRef.value.focus();
};

const getFilteredOptions = (query: string, options: Activity[]) => {
	if (!query) return props.options;

	return search(
		query,
		options,
		{
			keySelector: (option) => option.name,
			threshold: 0.7,
		},
	);
};

defineExpose({
	focus,
});
</script>
