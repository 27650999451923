import { DateTime } from 'luxon';
import { supabase } from '@/lib/supabase';
import { UserWorkSchedule } from '@/models/UserWorkSchedule';
import { UserModel } from '../models/User';
import {
	Absence, AbsenceMeta,
} from '../models/Absence';

export const getAbsencesForYear = async (user: UserModel, year: number): Promise<Absence[]> => {
	const firstDayOfYear = DateTime.now().set({ year }).startOf('year');

	const { data } = await supabase
		.from('absences')
		.select('*')
		.eq('user_id', user.id)
		.gt('date', firstDayOfYear);

	return data?.map((entry) => ({ absenceId: entry.absence_id, date: entry.date, userId: entry.user_id })) ?? [];
};

export const createNewAbsence = async (meta: AbsenceMeta, user: UserModel) => {
	const { data } = await supabase
		.from('absences')
		.insert({
			date: meta.date,
			user_id: user.id,
		});

	return data;
};

export const deleteAbsence = async (absenceId: string) => {
	const { data } = await supabase
		.from('absences')
		.delete()
		.eq('absence_id', absenceId);

	return data;
};

export const getUserWorkSchedule = async (date: DateTime): Promise<UserWorkSchedule[] | null> => {
	const { data } = await supabase
		.from('user_work_schedules')
		.select(`
			userId:user_id,
			from,
			till,
			isoDow:iso_dow,
			workingHours:working_hours
		`)
		.lte('from', date.toISODate())
		.or(`till.gte.${date.toISODate()},till.is.null`);

	return data;
};
