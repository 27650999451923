<template>
	<BoardWrapper>
		<TimeTrackHeader />
		<form
			method="post"
			class="space-y-8"
			@submit.prevent="onSubmit()"
		>
			<div class="desktop:grid phablet:grid-cols-3 phablet:gap-6">
				<div class="tablet:flex items-center col-span-3 widescreen:col-span-3 w-full h-full desktop:gap-6 widescreen:gap-0">
					<div class="flex flex-col justify-center items-center w-full desktop:w-1/3">
						<TimeTrackInput
							v-model="beat.timeIntervalInMs"
							appearance="base"
							@keydown.prevent.enter="focusFirstLevelInput"
							@update:model-value="onUseTimeTrackInput"
							@focus="startInteraction"
						/>
					</div>
					<div class="hidden desktop:flex w-full bg-primary-700 p-8 pb-12 pl-9 rounded-2xl">
						<TimeTrackInteract
							v-model:sliderValue="beat.timeIntervalInMs"
							:pin-size="28"
							class="min-h-56"
							@update:slider-value="onUseTimeTrackInteract"
						/>
					</div>
				</div>
			</div>
			<div class="grid grid-cols-1 desktop:grid-cols-12 gap-6 items-center">
				<div class="desktop:col-span-4 flex flex-col">
					<FirstLevelSelectInput
						id="FirstLevelSelectInput"
						ref="firstInputRef"
						v-model="selectedFirstLevelOption"
						:project-options="projectsStore.projects"
						:client-options="clientsStore.clients"
						placeholder="Which client/project?"
						class="text-white text-opacity-100 desktop:col-span-4"
						:can-deselect="true"
						:can-favorite="true"
						:favorite-topics="favoriteUserTopics"
						@keydown.enter="focusActivityInput"
						@open="startInteraction"
						@toggle-favorite-topic="onToggleFavoriteTopic"
					/>
				</div>
				<div class="desktop:col-span-3">
					<ActivitySelectInput
						id="ActivitySelectInput"
						ref="activityInputRef"
						v-model="selectedActivity"
						value-prop="id"
						label="name"
						placeholder="What did you do?"
						class="text-white text-opacity-100 desktop:col-span-3"
						:options="activities"
						:can-deselect="true"
						@keydown.enter="focusSatisfaction"
						@open="startInteraction"
					/>
				</div>
				<div class="mx-auto mt-8 desktop:mt-0 flex items-end space-x-[24px] w-full max-w-md desktop:col-span-4 desktop:col-start-9">
					<div class="flex items-center space-x-[24px] text-white text-opacity-50 text-center text-[14px] tracking-wider">
						<div class="flex flex-col">
							<div class="w-full text-center">
								Fun-Factor
							</div>
							<SatisfactionEmotes
								ref="satisfactionRef"
								v-model="satisfactionValue"
								:is-satisfaction-happy="isSatisfactionHappy"
								:is-satisfaction-sad="isSatisfactionSad"
								has-focus
								@update:model-value="startInteraction"
								@keydown.enter="focusBeatInlineNote"
							/>
						</div>
						<div class="flex flex-col">
							<div class="w-full text-center">
								Note
							</div>
							<BeatInlineNote
								ref="noteRef"
								v-model="beat.note"
								is-editable
								@focus-beat-inline-note="startInteraction"
							/>
						</div>
					</div>
					<AppButton
						type="submit"
						width="full"
						:disabled="!isFormValid"
						@focus="onLogBottonFocus()"
						@blur="onLogBottonBlur()"
					>
						Log time
					</AppButton>
				</div>
				<div class="desktop:col-span-8">
					<ReasonChips
						v-model:activity="selectedActivity"
						v-model:client="selectedClient"
						v-model:project="selectedProject"
						@click="startInteraction"
					/>
				</div>
			</div>
		</form>
	</BoardWrapper>
</template>

<script setup lang="ts">
import {
	computed, reactive, ref, watchEffect,
} from 'vue';
import { useToast } from 'vue-toastification';
import { DateTime } from 'luxon';
import BoardWrapper from '@/components/BoardWrapper.vue';
import { useBeatsStore } from '@/store/beats';
import { useTimeToBeatTracking } from '@/composables/useTimeToBeatTracking';
import { useHowConfidentModal } from '@/composables/useHowConfidentModal';
import { shouldDisplayPopup } from '@/services/ConfidenceLottery';
import { FeatureFlag, isFeatureFlagEnabled } from '@/models/FeatureFlag';
import { useUserActivitiesQuery } from '@/composables/queries/useUserActivitesQuery';
import { useActivitiesStore } from '@/store/activities';
import { useUserFavoriteTopicsQuery } from '@/composables/queries/useUserFavoriteTopicsQuery';
import { useUserFavoriteTopicsMutation } from '@/composables/queries/useUserFavoriteTopicsMutation';
import { usePlausible } from '../plugins/plausible/usePlausible';
import TimeTrackHeader from './TimeTrackHeader.vue';
import TimeTrackInput from './TimeTrackInput.vue';
import AppButton from './AppButton.vue';
import TimeTrackInteract from './TimeTrackInteract.vue';
import SatisfactionEmotes from './SatisfactionEmotes.vue';
import BeatInlineNote from './BeatInlineNote.vue';
import ReasonChips from './ReasonChips.vue';
import { useMainStore } from '../store/main';
import { createEmptyBeatMeta, isBeatValid } from '../models/Beats';
import { useClientsStore } from '../store/clients';
import FirstLevelSelectInput from './FirstLevelSelectInput.vue';
import { useProjectsStore } from '../store/projects';
import { FirstLevelOption, fromClientAndMaybeProject } from '../models/FirstLevelOption';
import ActivitySelectInput from './ActivitySelectInput.vue';
import { Activity } from '../models/Activity';
import { Client } from '../models/Client';
import { Project } from '../models/Project';

const firstInputRef = ref<typeof FirstLevelSelectInput>();
const activityInputRef = ref<typeof ActivitySelectInput>();
const satisfactionRef = ref<typeof SatisfactionEmotes>();
const noteRef = ref<typeof BeatInlineNote>();

const hasLogTimeButtonFocus = ref(false);

const toast = useToast();
const mainStore = useMainStore();
const clientsStore = useClientsStore();
const projectsStore = useProjectsStore();
const activitiesStore = useActivitiesStore();

const { data: sortedActivitiesData } = useUserActivitiesQuery();
const sortedActivites = computed(() => sortedActivitiesData.value ?? []);

const activities = computed(() => {
	if (isFeatureFlagEnabled(FeatureFlag.ACTIVITY_ORDER)) {
		return sortedActivites.value;
	}

	return activitiesStore.activities;
});

const selectedClient = ref<Client | null>(null);
const selectedProject = ref<Project | null>(null);
const selectedActivity = ref<Activity | null>(null);
const satisfactionValue = ref<number | null>(null);

const { startInteraction, stopAndTrackInteraction } = useTimeToBeatTracking({ interactionType: 'dashboard' });

const beat = reactive(createEmptyBeatMeta(mainStore.user!.id));

const selectedFirstLevelOption = computed<FirstLevelOption | null>({
	get() {
		if (!selectedClient.value) {
			return null;
		}

		return fromClientAndMaybeProject(selectedClient.value, selectedProject.value);
	},
	set(option) {
		selectedClient.value = option ? option.client : null;
		selectedProject.value = option ? option.project : null;
	},
});

const focusFirstLevelInput = () => {
	firstInputRef.value?.focus();
};

const focusActivityInput = () => {
	activityInputRef.value?.focus();
};

const focusSatisfaction = () => {
	satisfactionRef.value?.focus();
};

const focusBeatInlineNote = () => {
	noteRef.value?.focus();
};

const isSatisfactionHappy = computed(() => beat.satisfaction === 1);
const isSatisfactionSad = computed(() => beat.satisfaction === 0);
const isFormValid = computed(() => isBeatValid(beat));

watchEffect(() => {
	beat.activityId = selectedActivity.value?.id ?? undefined;
	beat.clientId = selectedFirstLevelOption.value?.client.id ?? undefined;
	beat.projectId = selectedFirstLevelOption.value?.project?.id ?? undefined;
});

watchEffect(() => {
	beat.satisfaction = satisfactionValue.value === beat.satisfaction ? null : satisfactionValue.value;
});

const resetForm = () => {
	Object.assign(beat, createEmptyBeatMeta(mainStore.user!.id));
	selectedActivity.value = null;
	selectedClient.value = null;
	satisfactionValue.value = null;
};

const onLogBottonFocus = () => {
	hasLogTimeButtonFocus.value = true;
};

const onLogBottonBlur = () => {
	hasLogTimeButtonFocus.value = false;
};

const hasUsedTimeTrackInteract = ref(false);

const onUseTimeTrackInput = () => {
	startInteraction();
	hasUsedTimeTrackInteract.value = false;
};

const onUseTimeTrackInteract = () => {
	startInteraction();
	hasUsedTimeTrackInteract.value = true;
};

const plausible = usePlausible();

const isSubmit = ref(false);

const beatStore = useBeatsStore();

const howConfidentModal = useHowConfidentModal({ beat: null });

const onSubmit = async () => {
	if (!isFormValid.value) {
		toast.warning('Sorry, your log isn\'t valid.');
		return;
	}
	try {
		if (isSubmit.value) {
			return;
		}
		isSubmit.value = true;
		beat.timestamp = DateTime.now();

		if (!isBeatValid(beat)) {
			throw new Error('Beat is not valid');
		}

		const result = await beatStore.createBeat(beat);

		if (isFeatureFlagEnabled(FeatureFlag.HOW_CONFIDENT) && result.isOk() && shouldDisplayPopup(beat.timeIntervalInMs / 3_600_000)) {
			howConfidentModal.show({ beat: result.value });
		}

		stopAndTrackInteraction();
		plausible.trackEvent('create-log', { props: { source: hasUsedTimeTrackInteract.value ? 'dashboard-graph' : 'dashboard-input' } });
		hasUsedTimeTrackInteract.value = false;
		resetForm();
		toast.success('You\'ve successfully logged time!');
		isSubmit.value = false;
	} catch (error) {
		toast.error('Oops, something went wrong.');
	} finally {
		hasLogTimeButtonFocus.value = false;
	}
};

const { data: favoriteUserTopics } = useUserFavoriteTopicsQuery();
const {
	addClientToFavorites,
	removeClientFromFavorites,
	addProjectToFavorites,
	removeProjectFromFavorites,
} = useUserFavoriteTopicsMutation();

const onToggleFavoriteClient = async (clientId: string) => {
	if (!favoriteUserTopics.value) return;

	const isFavorite = !!favoriteUserTopics.value.clients.find((client) => client === clientId);

	if (isFavorite) {
		removeClientFromFavorites.mutate({ clientId });
	} else {
		addClientToFavorites.mutate({ clientId });
	}
};

const onToggleFavoriteProject = async (projectId: string) => {
	if (!favoriteUserTopics.value) return;

	const isFavorite = !!favoriteUserTopics.value.projects.find((project) => project === projectId);

	if (isFavorite) {
		removeProjectFromFavorites.mutate({ projectId });
	} else {
		addProjectToFavorites.mutate({ projectId });
	}
};

const onToggleFavoriteTopic = (option: FirstLevelOption) => {
	if (option.project) {
		return onToggleFavoriteProject(option.project.id);
	}

	return onToggleFavoriteClient(option.client.id);
};
</script>
