<template>
	<div class="w-full align-middle">
		<div
			class="relative"
			:class="{
				'min-h-[200px]': loading,
			}"
		>
			<div
				v-show="loading"
				class="absolute -inset-0 z-10 bg-opacity-75 flex items-center justify-center"
			>
				Loading...
			</div>
			<table class="min-w-full relative">
				<thead v-if="hasLabel">
					<tr
						v-for="headerGroup in table.getHeaderGroups()"
						:key="headerGroup.id"
					>
						<th
							v-if="selectable"
							scope="col"
							class="sticky top-0 p-4 border-b"
						>
							<AppCheckbox
								:model-value="isRowSelected(rows)"
								@update:model-value="
									onUpdateSelectedRows(rows, $event)
								"
							/>
						</th>
						<th
							v-for="(
								header, index
							) in headerGroup.headers"
							:key="header.id"
							:colSpan="header.colSpan"
							class="p-4 font-sans font-bold text-sm text-gray-400 border-b-2 border-primary-300 bg-primary-400 z-40"
							:class="[{
								'sm:pl-6': index === 0,
								'cursor-pointer select-none': header.column.getCanSort(),
							}, header.column.columnDef.meta?.align === 'right' ? 'text-right' : 'text-left']"
							:style="{ width: `${header.getSize()}px`, position: 'sticky', top: `${headPositionTop}px` }"
							@click="event => header.column.getToggleSortingHandler()?.(event)"
						>
							<div
								class="flex items-center"
								:class="{
									'justify-end': header.column.columnDef.meta?.align === 'right',
								}"
							>
								<FlexRender
									v-if="!header.isPlaceholder"
									:render="header.column.columnDef.header"
									:props="header.getContext()"
								/>
								<ArrowDownIcon
									v-if="header.column.getCanSort()"
									class="w-5 ml-3 transform"
									:class="{
										'opacity-50': !header.column.getIsSorted(),
										'rotate-180': header.column.getIsSorted() === 'desc',
									}"
								/>
							</div>
						</th>
					</tr>
				</thead>
				<tbody class="divide-y divide-primary-300">
					<template v-if="table.getRowModel().rows.length > 0">
						<tr
							v-for="row in table.getRowModel().rows"
							:key="row.id"
							:class="{
								'cursor-pointer hover:bg-primary-300 group':
									clickable,
							}"
							@click="onRowClick(row)"
						>
							<td
								v-if="selectable"
								class="relative whitespace-nowrap py-4 pl-4 text-sm font-medium"
								:class="{
									'bg-primary-300': isRowSelected(row) || row.getIsSelected(),
								}"
							>
								<AppCheckbox
									:model-value="isRowSelected(row)"
									@update:model-value="
										onUpdateSelectedRows(
											row,
											$event,
										)
									"
								/>
							</td>
							<td
								v-for="(
									cell, columnIndex
								) in row.getVisibleCells()"
								:key="cell.id"
								class="py-4 pl-4 pr-4 text-left"
								:class="{
									'sm:pl-6': columnIndex === 0,
									'bg-primary-300': isRowSelected(row) || row.getIsSelected(),
									'text-right': cell.column.columnDef.meta?.align === 'right',
								}"
								:style="{
									width: `${cell.column.getSize()}px`,
								}"
							>
								<FlexRender
									:render="cell.column.columnDef.cell"
									:props="cell.getContext()"
								/>
							</td>
						</tr>
					</template>
					<tr v-else-if="!loading">
						<td
							colspan="100%"
							class="p-4"
						>
							<slot name="noEntries">
								Keine Einträge vorhanden
							</slot>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { computed, type PropType } from 'vue';
import { FlexRender, Row, Table } from '@tanstack/vue-table';
import AppCheckbox from '@/components/AppCheckbox.vue';
import {
	addRowToSelection,
	removeRowFromSelection,
	TableAction,
	TableRowData,
} from '@/models/Table';
import { ArrowDownIcon } from '@heroicons/vue/20/solid';

const props = defineProps({
	headPositionTop: {
		type: Number,
		default: 0,
	},
	table: {
		type: Object as PropType<Table<any>>,
		required: true,
	},
	rows: {
		type: Array as PropType<TableRowData[]>,
		default: () => [],
	},
	trackBy: {
		type: String,
		default: 'id',
	},
	editable: {
		type: Boolean,
		default: false,
	},
	selectable: {
		type: Boolean,
		default: false,
	},
	deselectable: {
		type: Boolean,
		default: false,
	},
	clickable: {
		type: Boolean,
		default: false,
	},
	selection: {
		type: Array as PropType<TableRowData[]>,
		default: () => [],
	},
	actions: {
		type: Array as PropType<TableAction<any>[]>,
		default: () => [],
	},
	hasLabel: {
		type: Boolean,
		default: true,
	},
	loading: {
		type: Boolean,
		default: false,
	},
});

const emits = defineEmits<{
	(e: 'edit', rowData: TableRowData<any>): void;
	(e: 'update:selection', selection: TableRowData<any>[]): void;
	(e: 'row-click', rowData: TableRowData<any>): void;
}>();

const selectedRows = computed<TableRowData[]>({
	get() {
		return props.selection;
	},
	set(newSelection) {
		emits('update:selection', newSelection);
	},
});

const isRowSelected = (row: TableRowData): boolean => !!selectedRows.value.find((s) => s[props.trackBy] === row[props.trackBy]);

const onUpdateSelectedRows = (rowData: TableRowData, isSelected: boolean) => {
	if (isSelected) {
		selectedRows.value = addRowToSelection(selectedRows.value, rowData);
	} else {
		selectedRows.value = removeRowFromSelection(
			selectedRows.value,
			rowData,
			props.trackBy,
		);
	}
};

const onRowClick = (row: Row<any>) => {
	if (props.clickable) {
		row.toggleSelected(props.deselectable ? undefined : true);
	}

	row.toggleExpanded();
};
</script>
