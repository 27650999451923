import { groupBy } from '../utilities/Helpers';
import { Beat } from './Beats';
import { EntryWithTime } from './EntryWithTime';
import { Database } from './Supabase';

export interface Activity {
	id: string;
	name: string;
	description?: string | null;
	archived: boolean | null;
}

export type ActivityWithTime = Activity & EntryWithTime;

export type ActivityMeta = Omit<Activity, 'id'>;

export type SupabaseActivity = Database['public']['Tables']['activities']['Row'];

export const toSupabaseData = (activityMeta: Activity): Omit<SupabaseActivity, 'id'> => ({
	activity_id: activityMeta.id,
	name: activityMeta.name,
	archived: activityMeta.archived,
});

export const createActivityFromSupabase = (activity: SupabaseActivity): Activity => ({
	id: activity.activity_id,
	name: activity.name,
	archived: activity.archived,
	description: null,
});

export const findActivityFromBeat = (activities: Activity[], beat: Beat): Activity | undefined => activities.find((activity) => activity.id === beat.activityId);

export const getActivitiesWithTimeFromBeats = (allBeats: Beat[], activities: Activity[]): ActivityWithTime[] => {
	const beatsWithActivity = allBeats.filter((beat) => beat.activityId);
	const beatsGroupedByActivity = groupBy(beatsWithActivity, 'activityId');

	return Object.entries(beatsGroupedByActivity)
		.map(([activityId, activityBeats]) => {
			const activity = findActivityFromBeat(activities, activityBeats[0]);
			return (
				{
					id: activityId,
					name: activity?.name ?? 'NO NAME FOUND',
					timeIntervalInMs: activityBeats.reduce((prev, curr) => prev + curr.timeIntervalInMs, 0),
					archived: activity?.archived ?? null,
					description: activity?.description
						? activity?.description
						: 'NO DESCRIPTION FOUND',
				});
		});
};

export const sortActivitiesByTime = (activities: ActivityWithTime[]): ActivityWithTime[] => [...activities].sort((activityA, activityB) => activityB.timeIntervalInMs - activityA.timeIntervalInMs);

export const getTopActivities = (beats: Beat[], activities: Activity[], maxActivities?: number): ActivityWithTime[] => {
	const topActivities = getActivitiesWithTimeFromBeats(beats, activities);
	const sortedActivities = sortActivitiesByTime(topActivities);
	if (maxActivities !== undefined) {
		return sortedActivities.slice(0, maxActivities);
	}
	return sortedActivities;
};
