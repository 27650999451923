<template>
	<textarea
		v-model="value"
		class="text-white bg-primary-300 rounded-md w-full px-2 text-base border border-primary-900 py-2 focus:outline-none focus-visible:outline-none focus:border-white"
		:placeholder="placeholder"
		:aria-label="label"
		:name="name"
		:rows="rows"
	/>
</template>

<script setup lang="ts">
import { useFieldLazy } from '@/composables/useFieldLazy';
import { toRef } from 'vue';

const props = defineProps({
	name: {
		type: String,
		required: true,
	},
	modelValue: {
		type: String,
		default: '',
	},
	label: {
		type: String,
		required: true,
	},
	placeholder: {
		type: String,
		required: true,
	},
	rows: {
		type: Number,
		default: 2,
	},
});

const { value } = useFieldLazy<string>(
	toRef(props, 'name'),
	{ initialValue: props.modelValue },
);
</script>
