<template>
	<div class="pt-6 space-y-10">
		<PageTitle>
			Team Report (Beta)
		</PageTitle>
		<div class="flex items-center justify-between">
			<div class="flex space-x-4">
				<BoxedStatItem
					label="Anzahl aller Logs"
					class="w-48 shrink-0"
					:value="currentStatsPeriodBeats.length"
				/>
				<BoxedStatItem
					class="w-48 shrink-0"
					suffix="%"
					:value="unknownLoggedDataInPercent"
				>
					<div class="flex items-center space-x-2">
						<p>Unbekannte Logs</p>
						<div
							v-tippy="{ content: 'Anzahl aller Logs in Prozent, die für Sunlab ohne Projekt und ohne Notiz erstellt wurden.', theme: 'sunhealth-mt' }"
							class="w-5 h-5 rounded-full bg-primary-800 flex items-center justify-center text-xs font-bold cursor-help"
						>
							?
						</div>
					</div>
				</BoxedStatItem>
			</div>
			<div class="flex space-x-4">
				<DateRangePicker
					v-model:from="fromDate"
					v-model:to="toDate"
				/>
			</div>
		</div>

		<div>
			<p class="text-2xl font-semibold mb-6">
				Quoten-Vergleich
			</p>
			<div class="flex bg-primary-400 rounded-2xl p-6 shadow-widget text-center">
				<ChartPieSingle
					:data="internalLogStatsData"
					class="px-6 desktop:px-12 w-full"
				/>
			</div>
		</div>
		<div class="space-y-6">
			<p class="text-2xl font-semibold">
				Daten Übersicht
			</p>
			<div class="grid grid-cols-12 gap-4">
				<div class="flex bg-primary-400 rounded-2xl p-6 shadow-widget text-center col-span-8">
					<TableGroupedBeats
						ref="groupedBeatsTable"
						:beats="currentStatsPeriodBeats"
						class="h-[700px] overflow-auto has-custom-scrollbar"
					/>
				</div>
				<div class="flex flex-col space-y-8 bg-primary-400 rounded-2xl p-8 shadow-widget justify-center items-center col-span-4">
					<div class="text-left w-full">
						<p class="text-xl">
							{{ selectedRow?.original.name }}
						</p>
						<p
							v-if="selectedParentRow"
							class="text-white/50"
						>
							{{ selectedParentRow.original.name }}
						</p>
					</div>
					<div class="flex-1 flex flex-col justify-center space-y-8 text-center">
						<div v-if="selectedRowChartData.length > 0">
							<p>Project comparison</p>
							<ChartPieSingle
								:data="selectedRowChartData"
								:show-legend="false"
								class="px-6 desktop:px-12"
							/>
						</div>
						<div>
							<p>Activity comparison</p>
							<ChartPieSingle
								:data="selectedRowActivityChartData"
								:show-legend="false"
								class="px-6 desktop:px-12"
							/>
						</div>
						<div>
							<p>User comparison</p>
							<ChartPieSingle
								:data="selectedRowUserChartData"
								:show-legend="false"
								class="px-6 desktop:px-12"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="flex items-center justify-between bg-primary-400 rounded-2xl p-10 shadow-widget text-center">
				<p class="text-2xl font-semibold">
					High-Fun vs Low-Fun
				</p>
				<div class="flex space-x-16">
					<div class="flex space-x-6 items-center">
						<SatisfactionHappy class="w-16 opacity-50" />
						<span class="text-2xl">
							{{ highSatisfactionLoggedTimeInPercent }}%
						</span>
					</div>
					<div class="flex space-x-6 items-center">
						<SatisfactionSad class="w-16 opacity-50" />
						<span class="text-2xl">
							{{ lowSatisfactionLoggedTimeInPercent }}%
						</span>
					</div>
				</div>
				<div>
					<AppButton
						color="gray"
						size="sm"
						class="min-w-32"
						@click="showSatisfactionLogs = !showSatisfactionLogs"
					>
						<span v-if="showSatisfactionLogs">
							Hide Logs
						</span>
						<span v-else>
							Show Logs
						</span>
					</AppButton>
				</div>
			</div>
			<div
				v-show="showSatisfactionLogs"
				class="grid grid-cols-12 gap-4"
			>
				<div class="flex bg-primary-400 rounded-2xl p-6 shadow-widget text-center col-span-6">
					<TableTopic
						:data="highSatisfactionTableEntries"
						class="w-full max-h-[400px] overflow-auto has-custom-scrollbar"
					/>
				</div>
				<div class="flex bg-primary-400 rounded-2xl p-6 shadow-widget text-center col-span-6">
					<TableTopic
						:data="lowSatisfactionTableEntries"
						class="w-full max-h-[400px] overflow-auto has-custom-scrollbar"
					/>
				</div>
			</div>
		</div>
		<div class="space-y-6">
			<p class="text-2xl font-semibold">
				Log Historie
			</p>
			<div class="grid grid-cols-12 gap-4">
				<div class="bg-primary-400 rounded-2xl p-6 shadow-widget text-center col-span-full">
					<div class="border-b-2 border-primary-300 pb-4 flex justify-between items-center">
						<div class="text-sm opacity-95 pl-4">
							<span class="font-bold">Ergebnisse: </span>
							<span>{{ filteredBeatsTableRows.length }} Logs</span>,
							<span class="font-bold">Gesamtdauer: </span>
							<span>{{ filteredBeatsTableRowsTotalTime }}h</span>
						</div>
						<div class="w-96 ml-auto">
							<AppInput
								v-model="logSearch"
								name="logSearch"
								placeholder="Beats durchsuchen..."
								class="text-sm"
							/>
						</div>
					</div>
					<TableBeats
						ref="beatsTable"
						:beats="currentStatsPeriodBeats"
						class="max-h-[700px] overflow-auto has-custom-scrollbar"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import PageTitle from '@/components/PageTitle.vue';
import BoxedStatItem from '@/components/BoxedStatItem.vue';
import {
	computed, ref, toRef, watch,
} from 'vue';
import { msToDisplayTime } from '@/shared/times';
import ChartPieSingle from '@/components/ChartPieSingle.vue';
import { useUnknownBeats } from '@/composables/useUnknownBeats';
import { DateTime } from 'luxon';
import { useSatisfactionStats } from '@/composables/useSatisfactionStats';
import { useInternalLogStats } from '@/composables/useInternalLogStats';
import { PieChartDataItem } from '@/models/PieChartDataItem';
import {
	getTotalTimeInMs,
	groupBeatsByActivity,
	getActivityFromBeat,
	groupBeatsByTopicResolved,
	createBeatFromSupabase,
} from '@/models/Beats';
import { useClientsStore } from '@/store/clients';
import { useProjectsStore } from '@/store/projects';
import { useActivitiesStore } from '@/store/activities';
import AppButton from '@/components/AppButton.vue';
import DateRangePicker from '@/components/DateRangePicker.vue';
import AppInput from '@/components/AppInput.vue';
import { useFieldSearch } from '@/composables/useFieldSearch';
import TableBeats from '@/components/TableBeats.vue';
import TableTopic from '@/components/TableTopic.vue';
import TableGroupedBeats from '@/components/TableGroupedBeats.vue';
import { BeatTableEntry } from '@/models/BeatTableEntry';
import { useAppProgressLoader } from '@sunlabde/vuelab';
import { keepPreviousData, useQuery } from '@tanstack/vue-query';
import { supabase } from '@/lib/supabase';
import { groupBy } from 'lodash';
import SatisfactionSad from '../assets/satisfaction-sad.svg?component';
import SatisfactionHappy from '../assets/satisfaction-happy.svg?component';

/**
 * IMPORTANT: This view is work in progress, we just already pushed in
 * to main to work with it
 */

const fromDate = ref(DateTime.now().minus({ days: 30 }).startOf('day'));
const toDate = ref(DateTime.now().endOf('day'));

const clientsStore = useClientsStore();
const projectsStore = useProjectsStore();
const activitiesStore = useActivitiesStore();

const { data: beatsData, isFetching: isFetchingBeats } = useQuery({
	queryKey: ['team-beats', { fromDate, toDate }],
	queryFn: async () => {
		const { data: beatsDataRaw } = await supabase
			.from('beats')
			.select('*, users!inner(*, users_in_teams!inner(*))')
			.eq('users.users_in_teams.team_id', '1ee15911-fa07-66e0-9a18-639200d7397a') // Hardcoded id for team UX
			.lte('users.users_in_teams.from', fromDate.value)
			.eq('archived', false)
			.order('timestamp', { ascending: false })
			.gt('timestamp', fromDate.value.startOf('day'))
			.lt('timestamp', toDate.value.endOf('day'));

		return beatsDataRaw?.map(createBeatFromSupabase) ?? [];
	},
	placeholderData: keepPreviousData,
	refetchOnWindowFocus: true,
});

const isFetching = computed(() => isFetchingBeats.value);

const appPogressLoader = useAppProgressLoader();

watch(isFetching, (_isLoading) => {
	if (_isLoading) {
		appPogressLoader.start();
	} else {
		appPogressLoader.finishAll();
	}
});

const currentStatsPeriodBeats = computed(() => beatsData.value ?? []);

const {
	internalLoggedTimeInPercent,
	externalLoggedTimeInPercent,
} = useInternalLogStats({
	beats: currentStatsPeriodBeats,
	internalClients: toRef(clientsStore, 'internalClients'),
});

const { unknownLoggedDataInPercent } = useUnknownBeats({
	beats: currentStatsPeriodBeats,
	internalClients: toRef(clientsStore, 'internalClients'),
});

const internalLogStatsData = computed<PieChartDataItem[]>(() => [
	{
		color: '#ff874b',
		label: 'Internal',
		value: internalLoggedTimeInPercent.value,
	},
	{
		color: '#733bff',
		label: 'External',
		value: externalLoggedTimeInPercent.value,
	},
]);

const groupedBeatsTable = ref<InstanceType<typeof TableGroupedBeats>>();

const selectedRows = computed(() => groupedBeatsTable.value?.table.getSelectedRowModel());

const selectedRow = computed(() => selectedRows.value?.flatRows[0]);

const selectedParentRow = computed(() => selectedRow.value?.getParentRow() ?? null);

const selectedRowChartData = computed<PieChartDataItem[]>(() => {
	if (!selectedRow.value) {
		return [];
	}

	const projects = selectedRow.value.original.subRows;

	if (!projects) {
		return [];
	}

	return projects.map<PieChartDataItem>((project) => ({
		label: project.name,
		value: project.totalDurationInMs,
	}));
});

const selectedRowActivityChartData = computed<PieChartDataItem[]>(() => {
	if (!selectedRow.value) {
		return [];
	}

	const groupedByActivites = groupBeatsByActivity(selectedRow.value.original.beats);

	return Object.entries(groupedByActivites)
		.map(([activityId, beats]) => {
			const activity = getActivityFromBeat(activitiesStore.activities, beats[0]);

			return ({
				label: activity?.name ?? activityId,
				value: getTotalTimeInMs(beats),
			});
		})
		.sort((a, b) => b.value - a.value);
});

const selectedRowUserChartData = computed<PieChartDataItem[]>(() => {
	if (!selectedRow.value) {
		return [];
	}

	const groupedByUsers = groupBy(selectedRow.value.original.beats, 'userId');

	return Object.entries(groupedByUsers)
		.map(([userId, beats]) => ({
			label: userId,
			value: getTotalTimeInMs(beats),
		}))
		.sort((a, b) => b.value - a.value);
});

const {
	value: logSearch, fields, unmatchedInput,
} = useFieldSearch({ debounce: 500 });

const beatsTable = ref<InstanceType<typeof TableBeats>>();

watch(fields, (newFields) => {
	beatsTable.value?.table.setColumnFilters(newFields);
});

watch(unmatchedInput, (input) => {
	beatsTable.value?.table.setGlobalFilter(input);
});

const filteredBeatsTableRows = computed(() => beatsTable.value?.table.getRowModel().rows ?? []);

const filteredBeatsTableRowsTotalTime = computed(() => msToDisplayTime(filteredBeatsTableRows.value.reduce((a, b) => a + b.original.timeIntervalInMs, 0)));

const {
	beatsWithHighSatisfaction,
	beatsWithLowSatisfaction,
	highSatisfactionLoggedTimeInPercent,
	lowSatisfactionLoggedTimeInPercent,
} = useSatisfactionStats(currentStatsPeriodBeats);

const highSatisfactionTableEntries = computed<BeatTableEntry[]>(() => {
	const group = groupBeatsByTopicResolved({
		beats: beatsWithHighSatisfaction.value,
		activities: activitiesStore.activities,
		clients: clientsStore.clients,
		projects: projectsStore.projects,
	});

	return Object.entries(group).map<BeatTableEntry>(([topic, beats]) => ({
		beats,
		id: topic,
		logCount: beats.length,
		name: topic,
		totalDurationInMs: getTotalTimeInMs(beats),
	}));
});

const lowSatisfactionTableEntries = computed<BeatTableEntry[]>(() => {
	const group = groupBeatsByTopicResolved({
		beats: beatsWithLowSatisfaction.value,
		activities: activitiesStore.activities,
		clients: clientsStore.clients,
		projects: projectsStore.projects,
	});

	return Object.entries(group).map<BeatTableEntry>(([topic, beats]) => ({
		beats,
		id: topic,
		logCount: beats.length,
		name: topic,
		totalDurationInMs: getTotalTimeInMs(beats),
	}));
});

const showSatisfactionLogs = ref(false);
</script>
