import { refDebounced } from '@vueuse/core';
import { computed, ref } from 'vue';

export interface FieldSearchValue {
	id: string;
	value: string;
}

export interface UseFieldSearchOptions {
	debounce?: number;
}

export const useFieldSearch = (options?: UseFieldSearchOptions) => {
	const REGEX = /(\w+:(?:"[^"]*"|\S+))/g;

	const input = ref('');
	const debouncedValue = refDebounced(input, options?.debounce ?? 1000);

	const matchedFields = computed(() => debouncedValue.value.match(REGEX));

	const unmatchedInput = computed(() => debouncedValue.value.replace(REGEX, ''));

	const fields = computed<FieldSearchValue[]>(() => {
		if (!matchedFields.value) {
			return [];
		}

		return matchedFields.value.map((match) => {
			const [id, value] = match.split(':');
			return { id, value: value.replace(/"/g, '') };
		});
	});

	return {
		value: input,
		debouncedValue,
		unmatchedInput,
		fields,
	};
};
