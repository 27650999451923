import { Beat } from '@/models/Beats';
import { Client } from '@/models/Client';
import { percentageRatio } from '@/utilities/Helpers';
import { MaybeRef, computed, unref } from 'vue';
import { useInternalBeats } from './useInternalBeats';

export interface UseInternalLogStatsOptions {
	beats: MaybeRef<Beat[]>,
	internalClients: MaybeRef<Client[]>,
}

export const useInternalLogStats = (options: UseInternalLogStatsOptions) => {
	const _beats = computed(() => unref(options.beats));

	const { internalBeats } = useInternalBeats(options);

	const internalTotalTimeLoggedInMs = computed(() => internalBeats.value
		.map((beat) => beat.timeIntervalInMs)
		.reduce((accumulator, currentValue) => accumulator + currentValue, 0));

	const totalTimeLoggedInMs = computed(() => _beats.value
		.map((beat) => beat.timeIntervalInMs)
		.reduce((accumulator, currentValue) => accumulator + currentValue, 0));

	const internalLoggedTimeInPercent = computed(() => {
		const p = percentageRatio(totalTimeLoggedInMs.value, internalTotalTimeLoggedInMs.value);
		return p || 0;
	});

	const externalLoggedTimeInPercent = computed(() => (_beats.value.length > 0 ? 100 - internalLoggedTimeInPercent.value : 0));

	return {
		internalBeats,
		internalTotalTimeLoggedInMs,
		internalLoggedTimeInPercent,
		externalLoggedTimeInPercent,
		totalTimeLoggedInMs,
	};
};
