import { ECElementEvent } from 'echarts';
import { MaybeRef, computed, unref } from 'vue';
import { LoggedDataType } from '@/models/LoggedData';
import { getNearestLowerMultipleOf, percentageRatio } from '@/utilities/Helpers';
import { DateTime } from 'luxon';
import { MINUTES_STEPS } from '@/utilities/Constants';
import { useLoggedDataQuery } from './queries/useLoggedDataQuery';
import { useOptionToggle } from './useOptionToggle';

export type LoggedTimeStatsItem = {
	value: number;
	label: string;
	type: LoggedDataType;
	color: string;
}

export type LoggedTimeECElementEvent = ECElementEvent & {
	data: {
		name: LoggedDataType,
		value: number,
		label: {
			tag: string,
		}
	}
}

export interface UseLoggedTimeStatsChartOptions {
	fromDate: MaybeRef<DateTime>;
	toDate?: MaybeRef<DateTime>;
	userId?: MaybeRef<string | null>;
}

export const useLoggedTimeStatsChart = (options: UseLoggedTimeStatsChartOptions) => {
	const { data: loggedData, isFetching } = useLoggedDataQuery(computed(() => ({
		period: {
			fromDate: unref(options.fromDate),
			toDate: unref(options.toDate),
		},
		userId: unref(options.userId),
	})));

	const {
		selected: selectedTopic,
		toggle: toggleTopic,
		selectedLabel: currentTopicName,
	} = useOptionToggle([
		{
			value: 'client',
			label: 'Clients',
		},
		{
			value: 'project',
			label: 'Projects',
		},
		{
			value: 'activity',
			label: 'Activities',
		},
	], 'client');

	const getLoggedDataInPercentByType = (type: LoggedDataType) => {
		const data = loggedData.value?.filter((d) => d.type === type)[0];

		if (!data) {
			return 0;
		}

		const dataInPercent = percentageRatio(getNearestLowerMultipleOf(data.dailyFullTimeInMinutes, MINUTES_STEPS), data.totalLoggedInMinutes) || 0;

		return dataInPercent;
	};

	const loggedTimeStats = computed<Record<LoggedDataType, LoggedTimeStatsItem>>(() => ({
		user: {
			value: getLoggedDataInPercentByType('user'),
			label: 'You',
			color: '#733bff',
			type: 'user',
		},
		team: {
			value: getLoggedDataInPercentByType('team'),
			label: 'Team',
			color: '#42D392',
			type: 'team',
		},
		everyone: {
			value: getLoggedDataInPercentByType('everyone'),
			label: 'Sunlab',
			color: '#ff874b',
			type: 'everyone',
		},
	}));

	const data = computed(() => Object.values(loggedTimeStats.value));

	return {
		data,
		isFetching,
		loggedData,
		loggedTimeStats,
		selectedTopic,
		currentTopicName,
		toggleTopic,
	};
};
