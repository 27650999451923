import { computed } from 'vue';
import { keepPreviousData, useQuery } from '@tanstack/vue-query';
import { useAuthentication } from '@/store/auth';
import { getUserFavoriteClients, getUserFavoriteProjects } from '@/api/user';
import { FeatureFlag, isFeatureFlagEnabled } from '@/models/FeatureFlag';
import { FavoriteTopics } from '@/models/FavoriteTopics';

export const useUserFavoriteTopicsQuery = () => {
	const authStore = useAuthentication();

	const enabled = computed(() => authStore.isAuthenticated && isFeatureFlagEnabled(FeatureFlag.FAVORITE_TOPICS));

	const query = useQuery<FavoriteTopics>({
		queryKey: ['userFavoriteTopics'],
		queryFn: async () => {
			const [favoriteClients, favoriteProjects] = await Promise.all([
				getUserFavoriteClients(),
				getUserFavoriteProjects(),
			]);

			return {
				clients: favoriteClients.data?.map((data) => data.client_id) ?? [],
				projects: favoriteProjects.data?.map((data) => data.project_id) ?? [],
			};
		},
		enabled,
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: true,
	});

	return query;
};
