import { Release } from '@/models/Release';
import axios from 'axios';
import {
	computed, onMounted, ref, watch,
} from 'vue';
import { useStorage, useWindowFocus } from '@vueuse/core';
import router from '@/router';
import ReleaseNotesModal from '@/components/modals/ReleaseNotesModal.vue';
import { useModal } from 'vue-final-modal';
import { useMainStore } from '@/store/main';
import { ResultAsync, okAsync } from 'neverthrow';
import * as Sentry from '@sentry/vue';
import { DateTime } from 'luxon';
import { usePlausible } from '@/plugins/plausible/usePlausible';

export const releaseNotes = () => {
	const releases = ref<Release[]>();
	const lastReleaseDate = useStorage<string>('last-release-date', '2004-04-04T04:44:44Z');
	const windowFocused = useWindowFocus();
	const mainStore = useMainStore();

	const releaseDatesInDateTime = computed(() => {
		if (!releases.value) {
			return [];
		}
		return releases.value.map((release) => DateTime.fromISO(release.date));
	});
	const latestIsoDate = computed(() => DateTime.max(...releaseDatesInDateTime.value).toUTC().toISO());

	const unreleasedReleases = computed(() => {
		if (!releases.value) {
			return [];
		}
		return releases.value
			.filter((newRelease) => DateTime.fromISO(newRelease.date) > DateTime.fromISO(lastReleaseDate.value))
			.sort((a, b) => DateTime.fromISO(b.date).toMillis() - DateTime.fromISO(a.date).toMillis());
	});

	const { trackEvent } = usePlausible();

	const { open, close, patchOptions } = useModal({
		component: ReleaseNotesModal,
		attrs: {
			releases: unreleasedReleases.value,
			modalId: 'release-notes',
			confirmLabel: 'Ok, nice!',
			onBeforeOpen() {
				patchOptions({
					attrs: {
						// Overwrite the modal's props
						releases: unreleasedReleases.value,
						modalId: 'releas-notes',
						confirmLabel: 'Ok, nice!',
					},
				});
			},
			onConfirm() {
				lastReleaseDate.value = latestIsoDate.value;
				close();
				trackEvent('release-note-action', {
					props: {
						action: 'acknowledged',
					},
				});
			},
			onCancel() {
				lastReleaseDate.value = latestIsoDate.value;
				close();
				trackEvent('release-note-action', {
					props: {
						action: 'dismissed',
					},
				});
			},
		},
	});

	const getCurrentReleases = (): ResultAsync<Release[], Error> => {
		const data: Promise<Release[]> = axios.get('/.netlify/functions/release-notes').then((responseData) => responseData.data);

		return ResultAsync.fromPromise(data, (error) => {
			if (!(error instanceof Error)) {
				return new Error('An unexpected error occured while getting the current releases');
			}
			return error;
		}).andThen((response) => okAsync(response));
	};

	const checkForNewRelease = async () => {
		if (!mainStore.user) {
			return;
		}
		const currentReleasesResult = await getCurrentReleases();

		if (currentReleasesResult.isErr()) {
			Sentry.withScope((scope) => {
				scope.setLevel('warning');
				scope.setTransactionName('Could not get current releases');
				scope.setFingerprint(['ReleaseNotesError']);
				Sentry.captureException(currentReleasesResult.error);
			});
			return;
		}
		releases.value = currentReleasesResult.value;

		if (!releases.value) {
			return;
		}
		if (!unreleasedReleases.value?.length) {
			return;
		}
		open();
	};

	router.beforeEach(checkForNewRelease);

	watch(windowFocused, async (value) => {
		if (value) {
			await checkForNewRelease();
		}
	});

	onMounted(checkForNewRelease);
};
