<template>
	<div class="mt-5 z-0">
		<div>
			<TransitionGroup
				v-if="beats.length > 0"
				name="card"
				tag="ul"
				class="grid grid-cols-cards gap-8 transition-all duration-300 ease-in-out"
			>
				<TodaysLogCard
					v-for="(beat, $index) in beats"
					:key="beat.beatId"
					:beat="beat"
					:style="`z-index: ${20 - $index}`"
					:editable="!isLocked || isLockReason(beat.beatId)"
					@editing:start="lock(beat.beatId)"
					@editing:end="unlock"
					@editing:success="emit('beatEdited')"
				/>
			</TransitionGroup>
			<EmptyCard
				v-else
			>
				Logged time will be displayed here!
			</EmptyCard>
		</div>
	</div>
</template>

<script setup lang="ts">
import { PropType } from 'vue';
import { useLock } from '@/composables/useLock';
import { useUnsavedChanges } from '@/composables/useUnsavedChanges';
import { Beat } from '../models/Beats';
import TodaysLogCard from './TodaysLogCard.vue';
import EmptyCard from './EmptyCard.vue';

defineProps({
	beats: {
		type: Array as PropType<Beat[]>,
		required: true,
	},
});

const {
	lock,
	unlock,
	isLockReason,
	isLocked,
	onLockChange,
} = useLock();

const { toggle } = useUnsavedChanges();

onLockChange(toggle);

const emit = defineEmits(['beatEdited']);
</script>
