import { selectQuotaInfoById } from '@/api/quotaInfo';
import { createQuotaInfoFromSupabase } from '@/models/QuotaInfo';
import { useAuthentication } from '@/store/auth';
import { useQuery } from '@tanstack/vue-query';
import {
	MaybeRef, computed, unref,
} from 'vue';

export interface QuotaInfoSearchParams {
	quotaInfoId?: string | null;
}

export const useQuotaInfoQuery = (
	searchParams: MaybeRef<QuotaInfoSearchParams>,
) => {
	const auth = useAuthentication();

	const enabled = computed(() => auth.isAuthenticated && !!unref(searchParams).quotaInfoId);

	const query = useQuery({
		queryKey: [
			'quotaInfo',
			{ quotaInfoId: computed(() => unref(searchParams).quotaInfoId) },
		],
		queryFn: async () => {
			const { quotaInfoId } = unref(searchParams);

			if (!quotaInfoId) {
				return Promise.reject();
			}

			const quotaInfo = await selectQuotaInfoById(quotaInfoId);

			if (!quotaInfo.data) {
				return Promise.reject();
			}

			return createQuotaInfoFromSupabase(quotaInfo.data);
		},
		enabled,
		refetchOnWindowFocus: true,
	});

	return query;
};
