import { supabase } from '@/lib/supabase';
import { SupabaseClient } from '../models/Client';

export const getClients = async () => supabase
	.from('clients')
	.select('*')
	.order('name');

export const updateClientById = async (clientId: string, properties: Partial<SupabaseClient>) => supabase
	.from('clients')
	.update(properties)
	.eq('client_id', clientId)
	.select()
	.single();

export const archiveAllClientProjects = async (clientId: string) => {
	await supabase
		.from('projects')
		.update({ archived: true } as Partial<SupabaseClient>)
		.eq('client_id', clientId);
};

export const archiveClientById = async (clientId: string) => {
	const result = await updateClientById(clientId, { archived: true });
	await archiveAllClientProjects(clientId);
	return result;
};

export const restoreClientById = async (clientId: string) => updateClientById(clientId, { archived: false });

export const createNewClient = async (name: string, description?: string) => {
	const { data } = await supabase
		.from('clients')
		.insert({
			name,
			description,
		});

	return data;
};
