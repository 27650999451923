<template>
	<div>
		<div
			class="grid grid-cols-12 items-center gap-6 px-4 hover:bg-primary-300 cursor-pointer active:bg-primary-300"
			:class="{ 'pointer-events-none hover:bg-transparent': client.archived, 'bg-primary-300': isActive }"
			@click="onClickListItem(client)"
			@keydown.enter="onClickListItem(client)"
		>
			<!-- eslint-disable vue/no-v-html -->
			<p
				class="text-2xl text-secondary col-span-5 fullhd:col-span-6 min-w-0 truncate"
				:class="{ 'opacity-40 line-through pointer-events-none cursor-not-allowed': client.archived }"
				v-html="DOMPurify.sanitize(highlight(client.name, searchValue))"
			/>
			<div class="col-span-2 justify-self-end">
				<div
					v-if="currentQuotaHours"
					class="flex items-center"
				>
					<IconWarning
						v-if="!hasQuotaForNextPeriod"
						v-tippy="{ content: 'Quota expires in the next two weeks', theme: 'sunhealth-mt' }"
						class="fill-warning mr-4"
					/>
					<IconClock class="fill-primary-100 mr-2" />
					<div class="text-primary-900">
						{{ hoursToDisplayTime(currentQuotaHours) }}h
					</div>
				</div>
			</div>
			<div class="flex justify-end space-x-2 py-4 col-span-5 fullhd:col-span-4">
				<AppButton
					v-if="!client.archived"
					size="base"
					class="h-8 font-normal"
					@click.stop="onAddProject(client)"
				>
					Add Project
				</AppButton>
				<AppButton
					v-if="client.archived"
					size="base"
					color="outline"
					class="h-8 font-normal"
					:class="{ 'pointer-events-auto': client.archived }"
					@click.stop="onRestoreClient(client)"
				>
					Restore
				</AppButton>
				<AppButton
					v-else
					size="base"
					color="outline"
					class="h-8 font-normal"
					@click.stop="onArchiveClient(client)"
				>
					Archive
				</AppButton>
			</div>
		</div>
		<slot />
	</div>
</template>

<script lang="ts" setup>
import * as Sentry from '@sentry/vue';
import { Client } from '@/models/Client';
import { isActiveDuringSpecificDate } from '@/models/Quota';
import { PropType, computed } from 'vue';
import AppButton from '@/components/AppButton.vue';
import IconClock from '@/assets/clock-icon.svg?component';
import IconWarning from '@/assets/icon-warning.svg?component';
import { hoursToDisplayTime } from '@/shared/times';
import { useToday } from '@/composables/useToday';
import { useQuotasQuery } from '@/composables/queries/useQuotasQuery';
import { useToast } from 'vue-toastification';
import { useClientsStore } from '@/store/clients';
import DOMPurify from 'dompurify';
import { getSafeArray, highlight } from '../utilities/Helpers';

const props = defineProps({
	client: {
		type: Object as PropType<Client>,
		required: true,
	},
	isActive: {
		type: Boolean,
		default: false,
	},
	searchValue: {
		type: String,
		default: '',
	},
});

const clientsStore = useClientsStore();
const toast = useToast();
const today = useToday();

const { data: quotasData } = useQuotasQuery(computed(() => ({
	quotaInfoId: props.client.quotaInfoId,
	clientId: props.client.id,
})));

const quotas = computed(() => getSafeArray(quotasData.value));

const activeQuota = computed(() => quotas.value.find((quota) => isActiveDuringSpecificDate(today.value, quota)));

const currentQuotaHours = computed(() => activeQuota.value?.monthlyHours);

const hasQuotaForNextPeriod = computed(() => {
	const startOfNextPeriod = today.value.plus({ days: 14 });
	const nextQuota = quotas.value.find((quota) => isActiveDuringSpecificDate(startOfNextPeriod, quota));
	return !!nextQuota;
});

const emit = defineEmits([
	'addProject',
	'click',
]);

const onClickListItem = (client: Client) => {
	emit('click', client);
	Sentry.addBreadcrumb({
		category: 'Button',
		message: 'Clicked showClientPanel Button',
		level: 'info',
	});
};
const onAddProject = (client: Client) => {
	emit('addProject', client);
	Sentry.addBreadcrumb({
		category: 'Button',
		message: 'Clicked showAddProjectPanel Button',
		level: 'info',
	});
};

const onArchiveClient = async (client: Client) => {
	await clientsStore.archiveClient(client.id);
	toast.success(`Successfully archived client "${client.name}"`);
};

const onRestoreClient = async (client: Client) => {
	await clientsStore.restoreClient(client.id);
	toast.success(`Successfully restored client "${client.name}"`);
};

</script>
