import { User as SupabaseUser } from '@supabase/supabase-js';

export interface AuthUser {
	id: string;
	displayName: string;
	email: string;
}

export const supabaseToAuthUser = (user: SupabaseUser): AuthUser => ({
	id: user.id,
	displayName: user.user_metadata.full_name ?? user.user_metadata.fbuser?.displayName ?? 'Unknown',
	email: user.email || 'Unknown',
});
