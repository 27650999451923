import { supabase } from '@/lib/supabase';

export const selectQuotaInfoById = async (quotaInfoId: string) => supabase
	.from('quota_infos')
	.select('*')
	.eq('quota_info_id', quotaInfoId)
	.single();

export const updateQuotaInfoIsExtra = async (quotaInfoId: string, isExtra: boolean) => supabase
	.from('quota_infos')
	.update({
		is_extra: isExtra,
	})
	.eq('quota_info_id', quotaInfoId);
