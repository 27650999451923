import { LogNotificationVoice } from '@/models/UserSettings';

export const logMissingMessages: Record<LogNotificationVoice, string[]> = {
	neutral: [
		'Friendly Reminder: es ist Zeit, deine Stunden zu loggen. Halte deine Leistungen fest!',
		"Kurze Erinnerung: deine produktiven Stunden sind wichtig für uns! Vergiss' bitte nicht zu loggen.",
		'Hast du heute schon deine Arbeitszeit geloggt? Es hilft uns, besser zu planen und zu unterstützen.',
		"Vergiss' bitte nicht, deine heutigen Errungenschaften in SunHealth zu dokumentieren.",
		"Deine Arbeit ist wertvoll für uns – bitte denk' daran, deine Zeiten noch zu loggen.",
		"Ein kleiner Schubs: das Loggen deiner Stunden hilft uns, gemeinsam erfolgreich zu sein - also bleib' dran!",
		"Vergiss' nicht, deinen Tag in SunHealth festzuhalten. Deine Logs helfen uns, gemeinsam zu wachsen.",
		"Jede geloggte Stunde zählt und trägt zu unserem gemeinsamen Erfolg bei. Vergiss' also nicht zu loggen.",
		'Friendly Reminder: deine Mühen und deine Arbeit sind vor allem sichtbar, wenn du deine Stunden in SunHealth loggst.',
		'Friendly Reminder: deine Arbeitszeit zu erfassen zeigt, wie wertvoll deine Arbeit für uns ist. Danke dafür!',
		"Vergiss' nicht, heute deine Zeit zu loggen. Es macht einen großen Unterschied in unserer Planung.",
		'Erinnerung: durch das Loggen deiner Stunden leistest du einen wertvollen Beitrag zu unserem Unternehmenserfolg. Wir schätzen das sehr!',
		"Vergiss' nicht, deine täglichen Arbeitsstunden in SunHealth zu erfassen. Deine Arbeit zählt!",
		"Bitte vergiss' nicht, deine Arbeitszeiten zu dokumentieren. Wir zählen auf dich!",
		"Denk' daran, deine Arbeitszeit heute noch zu erfassen. Deine Arbeit macht einen Unterschied!",
		'Deine Zeit ist wertvoll – halte sie in SunHealth fest!',
	],
	sassy: [
		'Hast du heute schon geloggt oder bist du zu beschäftigt, um produktiv zu sein?',
		'Zeit zum Loggen – oder hast du vor, heute unsichtbar zu arbeiten?',
		"Logg' deine Zeiten, sonst nehmen wir an, dass du den ganzen Tag nur Kaffee getrunken hast!",
		'Wir vermissen deine Logs – fühlst du dich schon schuldig?',
		'Ist das dein freier Tag oder warum hast du noch nichts geloggt?',
		'SunHealth ist einsam ohne deine Logs – mach’s doch glücklich!',
		'Loggen ist wie Zähneputzen – besser nicht vergessen!',
		'Ohne deine Logs sieht es hier ganz schön leer aus – Zeit das zu ändern, oder?',
		'Wieder ein Tag, an dem du dich vor dem Loggen drückst?',
		'Nur zur Info: Loggen ist kein optionaler Sport – also ran an die Tasten!',
		'Hallo?! SunHealth fühlt sich ziemlich vernachlässigt…',
		'Loggen oder nicht loggen, das ist hier die Frage. Spoiler: Loggen!',
		'SunHealth ruft: deine Zeit zu loggen ist… jetzt!',
		'Ich sehe keine Logs! Ich hoffe, das ist ein technischer Fehler…',
		'Keine Zeit zu loggen? Wir nehmen auch Einträge nach Mitternacht an!',
		"Mach mal eine Pause vom Arbeiten und logg' deine Zeiten.",
		'Deine Stunden loggen sich nicht von alleine!',
		'Zeit fürs Loggen! Keine Ausreden mehr!',
		'Hast du schon deine Zeiten geloggt oder bist du noch beschäftigt mit etwas Wichtigerem?',
		"Zeiterfassung ist das neue Schwarz. Logg' jetzt!",
		'Deine Stunden loggen sich nicht von alleine, weißt du?',
		'Schon wieder Zeit zu loggen… das Leben ist hart.',
		'SunHealth - weil nichts im Leben umsonst ist.',
		'SunHealth - weil wir sonst im Dunkeln tappen.',
		"Logg' deine Zeit, bevor du schon wieder vergisst, was du gemacht hast.",
		'Nicht vergessen: Jede nicht geloggte Minute ist eine verlorene Minute.',
		'SunHealth - der wahre Grund, warum du zur Arbeit kommst.',
		'Wenn du nicht loggst, wie sollen wir dann wissen, dass du hier warst?',
		'Hey, schau mal: ein Logbuch! Zeit für dich, es zu füllen.',
		'SunHealth - weil wir alle Kontrolle lieben.',
		"Vergiss' nicht zu loggen, sonst müssen wir raten, was du gemacht hast.",
		'SunHealth – weil dein Gedächtnis ein Sieb ist.',
		'Keine Zeiten geloggt? Dann brauchst du auch keinen Urlaub.',
		'SunHealth - damit du wenigstens einmal nützlich bist.',
		'Loggen. Jetzt. Wir wissen, wo du wohnst.',
		"Heb' die Pfote und logg' deine Zeit, uwu.",
	],
};
