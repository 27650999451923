<template>
	<div>
		<button
			ref="buttonRef"
			type="button"
			class="flex items-center space-x-6 bg-primary-400 rounded-2xl px-6 py-4 shadow-widget text-center"
			@click="toggle"
		>
			<span>
				{{ from.toLocaleString() }} - {{ to.toLocaleString() }}
			</span>
			<ChevronDownIcon
				class="w-5 transform"
				:class="{
					'rotate-180': isOpen,
				}"
			/>
		</button>
		<teleport to="#app">
			<div
				v-show="isOpen"
				ref="popperRef"
				v-on-click-outside="onClickOutsideHandler"
				class="z-50 bg-primary-400 rounded-2xl p-6 shadow-widget border border-primary-300"
			>
				<div class="flex space-x-4">
					<div>
						<p class="text-white text-center mb-3">
							Start Date
						</p>
						<DatePicker
							ref="fromDatePicker"
							v-model="fromValue"
							mode="date"
							is-required
							class="custom-datepicker"
						/>
					</div>
					<div>
						<p class="text-white text-center mb-3">
							End Date
						</p>
						<DatePicker
							ref="toDatePicker"
							v-model="toValue"
							mode="date"
							is-required
							class="custom-datepicker"
						/>
					</div>
				</div>
				<div class="flex flex-wrap gap-2 w-[500px] mt-6">
					<AppButton
						v-for="preset in dateRangePresets"
						:key="preset.label"
						size="sm"
						color="gray"
						class="text-white"
						@click="onSelectPreset(preset)"
					>
						{{ preset.label }}
					</AppButton>
				</div>
			</div>
		</teleport>
	</div>
</template>

<script lang="ts" setup>
import { usePopper } from '@/composables/usePopper';
import { DateTime } from 'luxon';
import { DatePicker } from 'v-calendar';
import {
	PropType, computed, ref, watch,
} from 'vue';
import { OnClickOutsideOptions } from '@vueuse/core';
import { vOnClickOutside } from '@vueuse/components';
import { ChevronDownIcon } from '@heroicons/vue/24/solid';
import AppButton from './AppButton.vue';

const props = defineProps({
	from: {
		type: Object as PropType<DateTime>,
		required: true,
	},
	to: {
		type: Object as PropType<DateTime>,
		required: true,
	},
});

const emits = defineEmits<{
	(e: 'update:from', date: DateTime): void;
	(e: 'update:to', date: DateTime): void;
}>();

const fromValue = computed({
	get() {
		return props.from.toJSDate();
	},
	set(date) {
		emits('update:from', DateTime.fromJSDate(date));
	},
});

const toValue = computed({
	get() {
		return props.to.toJSDate();
	},
	set(date) {
		emits('update:to', DateTime.fromJSDate(date));
	},
});

const buttonRef = ref();
const popperRef = ref();

const { toggle, isOpen, close } = usePopper({
	placement: ref('bottom-start'),
	offsetSkid: ref('0'),
	offsetDistance: ref('12'),
	popperNode: popperRef,
	triggerNode: buttonRef,
});

const onClickOutsideHandler: [(evt: any) => void, OnClickOutsideOptions] = [
	() => close(),
	{ ignore: [buttonRef] },
];

const dateRangePresets = [
	{
		label: 'Letztes Jahr',
		value: {
			start: DateTime.now().minus({ years: 1 }).startOf('year'),
			end: DateTime.now().minus({ years: 1 }).endOf('year').startOf('day'),
		},
	},
	{
		label: 'Letzten Monat',
		value: {
			start: DateTime.now().minus({ months: 1 }).startOf('month'),
			end: DateTime.now().minus({ months: 1 }).endOf('month').startOf('day'),
		},
	},
	{
		label: 'Letzte Woche',
		value: {
			start: DateTime.now().minus({ weeks: 1 }).startOf('week'),
			end: DateTime.now().minus({ weeks: 1 }).endOf('week').startOf('day'),
		},
	},
	{
		label: 'Gestern',
		value: {
			start: DateTime.now().minus({ days: 1 }).startOf('day'),
			end: DateTime.now().minus({ days: 1 }).startOf('day'),
		},
	},
	{
		label: 'Heute',
		value: {
			start: DateTime.now().startOf('day'),
			end: DateTime.now().startOf('day'),
		},
	},
	{
		label: 'Diese Woche',
		value: {
			start: DateTime.now().startOf('week'),
			end: DateTime.now().endOf('week').startOf('day'),
		},
	},
	{
		label: 'Dieser Monat',
		value: {
			start: DateTime.now().startOf('month'),
			end: DateTime.now().endOf('month').startOf('day'),
		},
	},
	{
		label: 'Dieses Jahr',
		value: {
			start: DateTime.now().startOf('year'),
			end: DateTime.now().endOf('year').startOf('day'),
		},
	},
];

const onSelectPreset = (preset: typeof dateRangePresets[number]) => {
	if (!preset) {
		return;
	}

	fromValue.value = preset.value.start.toJSDate();
	toValue.value = preset.value.end.toJSDate();
};

const fromDatePicker = ref();
const toDatePicker = ref();

watch(fromValue, (newValue) => {
	fromDatePicker.value.move(newValue);
});

watch(toValue, (newValue) => {
	toDatePicker.value.move(newValue);
});
</script>

<style lang="scss">
.custom-datepicker.vc-container {
    @apply bg-primary-500 border-primary-800 rounded-lg z-50;

    .vc-title {
        @apply text-white;
    }

    .vc-weekday {
        @apply text-white;
    }

    .vc-day {
        .vc-day-content {
            @apply text-primary-900;
        }

        &.weekday-1,
        &.weekday-7 {
            .vc-day-content {
                @apply text-primary-800;
            }
        }

        &:hover {
            .vc-day-content {
                @apply text-white bg-primary-800;
            }

        }
    }

    .vc-arrow {
        @apply text-white;

        &:hover {
            @apply bg-primary-800;
        }
    }

    .vc-popover-caret {
        @apply bg-primary-500;
    }

    .vc-highlight {
        @apply bg-primary-100 #{!important};
    }
}
</style>
