<template>
	<div
		class="p-8 h-full fixed left-0 top-0 bottom-0 flex flex-col justify-between w-sidebar backdrop-filter backdrop-brightness-150 backdrop-blur-[10px] bg-primary-300 bg-opacity-30 phablet:bg-primary-600 phablet:bg-opacity-100 transform transition phablet:-translate-x-0 duration-300 ease-in-out z-[90]"
		:class="[showMobileSidebar ? 'right-0' : '-translate-x-full']"
	>
		<div class="pt-[108px] phablet:pt-0">
			<router-link
				:to="{ name: 'Dashboard' }"
				tabindex="-1"
				class="hidden phablet:flex focus:outline-none"
			>
				<SunHealthLogo class="mb-16" />
			</router-link>
			<div
				v-if="mainStore.user"
				class="flex items-center cursor-pointer p-2 rounded-full transition-all ease-in-out z-10 phablet:hidden mb-14"
			>
				<div class="min-w-[48px] max-w-[48px] min-h-[48px] max-h-[48px] rounded-full border border-solid border-secondary mr-3 flex items-center justify-center font-bold text-[24px] text-secondary">
					{{ firstGlyph(mainStore.user.name) }}
				</div>
				<div class="max-w-full overflow-hidden">
					<div class="text-[18px] font-bold flex items-center">
						<div class="truncate max-w-[127px]">
							{{ mainStore.user.name }}
						</div>
					</div>
				</div>
			</div>
			<div class="flex flex-col space-y-4">
				<div
					v-for="(nav) in filteredNavigationItems"
					:key="nav.label"
				>
					<NavigationItemVue
						:label="nav.label"
						:icon="nav.icon"
						:link="nav.link"
						:badge="nav.badge"
					/>
				</div>
			</div>
		</div>
		<div
			v-click-outside="closeUserMenu"
			class="hidden phablet:flex flex-col"
		>
			<div class="flex flex-col-reverse gap-2 my-2">
				<Transition
					v-for="(item, index) in filteredUserMenuItems"
					:key="item.label"
					enter-from-class="opacity-0 translate-y-12"
					enter-to-class="opacity-100 translate-y-0"
					class="card-wrapper will-change-transform"
					enter-active-class="transform duration-300"
					leave-active-class="hidden"
					:style="{ 'transition-delay': `${index === 0 ? 0 : (index * 25 + 75)}ms` }"
				>
					<RouterLink
						v-show="showUserMenu"
						:to="item.link"
						@click="closeUserMenu"
					>
						<UserMenuItem>
							{{ item.label }}
						</UserMenuItem>
					</RouterLink>
				</Transition>
			</div>
			<div
				v-if="mainStore.user"
				class="flex items-center w-full cursor-pointer p-2 hover:bg-primary-300 rounded-full transition-all ease-in-out z-10"
				:class="showUserMenu ? 'bg-primary-300' : ''"
				@click="toggleUserMenu"
				@keydown.enter="toggleUserMenu"
			>
				<div class="min-w-[48px] max-w-[48px] min-h-[48px] max-h-[48px] rounded-full border border-solid border-secondary mr-3 flex items-center justify-center font-bold text-[24px] text-secondary">
					{{ firstGlyph(mainStore.user.name) }}
				</div>
				<div class="w-full overflow-hidden">
					<div class="text-[18px] font-bold flex items-center justify-between pr-4">
						<div class="truncate max-w-[127px]">
							{{ mainStore.user.name.split(' ')[0] }}
						</div>
						<ChevronDownIcon
							class="w-5 transform transition-all ease-in-out duration-200"
							:class="{
								'rotate-180': showUserMenu,
							}"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="phablet:hidden">
			<router-link
				:to="{ name: 'Logout' }"
			>
				<button
					class="py-4 px-10 cursor-pointer w-full p-2 transition-all ease-in-out mb-2"
					type="button"
				>
					Log out
				</button>
			</router-link>
		</div>

		<teleport to="#app-portal">
			<div
				class="flex justify-between items-center phablet:hidden h-[72px] top-0 left-6 right-6 z-[100]"
				:class="[showMobileSidebar ? 'fixed' : 'absolute']"
			>
				<router-link
					:to="{ name: 'Dashboard' }"
					tabindex="-1"
					class="focus:outline-none"
				>
					<SunHealthLogo
						@click="onNavigationItemClick"
					/>
				</router-link>
				<button
					class="space-y-1.5 p-2 opacity-50"
					type="button"
					@click="toggleMobileSidebar"
				>
					<div
						class="w-[26px] bg-white h-[3px] rounded-sm transition-all ease-in-out duration-150"
						:class="showMobileSidebar ? 'rotate-[-45deg] translate-y-[9px]' : ''"
					/>
					<div
						class="w-[26px] bg-white h-[3px] rounded-sm transition-all ease-in-out duration-150"
						:class="showMobileSidebar ? 'opacity-0' : ''"
					/>
					<div
						class="w-[26px] bg-white h-[3px] rounded-sm transition-all ease-in-out duration-150"
						:class="showMobileSidebar ? 'rotate-[45deg] translate-y-[-9px]' : ''"
					/>
				</button>
			</div>
		</teleport>
	</div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useAuthentication } from '@/store/auth';
import NavigationItemVue from '@/components/NavigationItem.vue';
import {
	NavigationItem, hasUserRightsCheck, navigationItems, userMenuItems,
} from '@/models/NavigationItems';
import { useQuotaListsStore } from '@/store/quotaLists';
import { ChevronDownIcon } from '@heroicons/vue/24/solid';
import { useUserRightsStore } from '@/store/userRights';
import SunHealthLogo from '../assets/sunhealth-logo.svg?component';
import vClickOutside from '../plugins/ClickOutsideDirective';
import { useMainStore } from '../store/main';
import { firstGlyph } from '../utilities/Helpers';
import UserMenuItem from './UserMenuItem.vue';

const mainStore = useMainStore();
const quotaListsStore = useQuotaListsStore();
const authStore = useAuthentication();
const userRightsStore = useUserRightsStore();

const isDevelopment = import.meta.env.MODE === 'development';

const personalReportUrl = computed(() => `https://lookerstudio.google.com/u/0/reporting/a969ffe2-cbbf-4954-8cad-3a8dc6dc277e/page/p_g6gf9h654c?params=%7B%22df65%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${mainStore.userData?.userId}%22%7D`);

const getFilteredNavigationItems = (items: NavigationItem[]) => {
	let tempList = items
		.filter((navigationItem) => (navigationItem.check === 'isDevelopment' ? isDevelopment : true))
		.filter((navigationItem) => (navigationItem.check === 'isAdmin' ? authStore.isAdminUser : true))
		.filter((navigationItem) => {
			if (navigationItem.check && hasUserRightsCheck(navigationItem.check)) {
				return userRightsStore.hasUserRight(navigationItem.check.userRight);
			}

			return true;
		});

	if (!isDevelopment && mainStore.userData) {
		const editLink = tempList.find((navigationItem) => navigationItem.link === 'personalReportURL');
		if (editLink) {
			editLink.link = personalReportUrl.value;
		}
	}

	const checkQuotaListAccess = quotaListsStore.quotaLists.length > 0;
	if (!checkQuotaListAccess) {
		tempList = tempList.filter((navigationItem) => navigationItem.check !== 'hasQuotaListAccess');
	} else {
		const editLink = tempList.find((navigationItem) => navigationItem.check === 'hasQuotaListAccess');
		if (editLink) {
			editLink.link = { name: 'QuotaReport', params: { quotaListId: quotaListsStore.quotaLists[0].quota_list_id } };
		}
	}

	return tempList;
};

const filteredNavigationItems = computed(() => getFilteredNavigationItems(navigationItems));
const filteredUserMenuItems = computed(() => getFilteredNavigationItems(userMenuItems));

const showMobileSidebar = ref(false);

const toggleMobileSidebar = () => {
	showMobileSidebar.value = !showMobileSidebar.value;

	if (showMobileSidebar.value) {
		document.body.classList.add('overflow-hidden', 'h-screen');
		return;
	}
	document.body.classList.remove('overflow-hidden', 'h-screen');
};

const onNavigationItemClick = () => {
	showMobileSidebar.value = false;
	document.body.classList.remove('overflow-hidden', 'h-screen');
};

const showUserMenu = ref(false);

const toggleUserMenu = () => {
	showUserMenu.value = !showUserMenu.value;
};

const closeUserMenu = () => {
	showUserMenu.value = false;
};
</script>

<style>
:root {
    --sidebar-width: 100%;
}
@screen phablet {
    :root {
        --sidebar-width: 280px;
    }
}

@screen widescreen {
    :root {
        --sidebar-width: 348px;
    }
}

.card-wrapper > * {
	@apply w-full h-full;
}

.will-change-transform {
	will-change: transform;
}
</style>
