import { Directive } from 'vue';

const directive: Directive = {
	beforeMount(el, binding) {
		if (!(Object.prototype.hasOwnProperty.call(binding, 'value')) || binding.value) {
			el.onClick = (event: MouseEvent) => {
				const elBox = el.getBoundingClientRect();
				const clickWrapper = document.createElement('div');
				const clickPoint = document.createElement('div');

				clickWrapper.style.top = `${elBox.top + document.documentElement.scrollTop}px`;
				clickWrapper.style.left = `${elBox.left + document.documentElement.scrollLeft}px`;
				clickWrapper.style.width = `${elBox.width}px`;
				clickWrapper.style.height = `${elBox.height}px`;

				clickPoint.style.top = `${event.clientY - elBox.top}px`;
				clickPoint.style.left = `${event.clientX - elBox.left}px`;

				clickWrapper.classList.add('click-wrapper');
				clickPoint.classList.add('click-point');

				if (binding.arg && binding.arg.match(/^#[0-9a-f]{3,6}$/i)) {
					clickPoint.style.backgroundColor = binding.arg;
				}

				if (binding.arg === 'light') {
					clickPoint.classList.add('is-light');
				}

				if (binding.arg === 'dark') {
					clickPoint.classList.add('is-dark');
				}

				clickWrapper.appendChild(clickPoint);
				document.body.appendChild(clickWrapper);

				clickPoint.addEventListener('animationend', () => {
					clickPoint.remove();
					clickWrapper.remove();
				}, { once: true });
			};

			el.addEventListener('click', el.onClick);
		}
	},
	beforeUnmount(el) {
		el.removeEventListener('click', el.onClick);
	},
};

export default directive;
