import { computed } from 'vue';
import { useAuthentication } from '@/store/auth';
import { keepPreviousData, useQuery } from '@tanstack/vue-query';
import { getClients } from '@/api/client';
import { createClientFromSupabase } from '@/models/Client';

export const useClientsQuery = () => {
	const { isAuthenticated } = useAuthentication();

	const enabled = computed(() => isAuthenticated);

	const query = useQuery({
		queryKey: ['clients'],
		queryFn: async () => {
			const clients = await getClients();

			return clients.data?.map(createClientFromSupabase) ?? [];
		},
		enabled,
		initialData: [],
		placeholderData: (previousData) => keepPreviousData(previousData ?? []),
		refetchOnWindowFocus: false,
		refetchInterval: 3600000,
		refetchIntervalInBackground: true,
	});

	return query;
};
