import { computed } from 'vue';
import { useAuthentication } from '@/store/auth';
import { keepPreviousData, useQuery } from '@tanstack/vue-query';
import { getOrderedUserActivities } from '@/api/activity';

export const useUserActivitiesQuery = () => {
	const authStore = useAuthentication();

	const enabled = computed(() => authStore.isAuthenticated);

	const query = useQuery({
		queryKey: ['userActivities', authStore.user?.id],
		queryFn: () => getOrderedUserActivities(),
		enabled,
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: true,
		staleTime: 1000 * 60 * 60,
	});

	return query;
};
