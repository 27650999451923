import {
	useField, type FieldContext, type FieldOptions, type RuleExpression,
} from 'vee-validate';
import { type MaybeRef, computed } from 'vue';

export interface ValidationListeners<Context extends FieldContext> {
	blur: Context['handleBlur'];
	change: Context['handleChange'];
	input: Context['handleChange'];
}

/**
 * This function is a extension to the built in useField from
 * vee-validate with the feature of having a dynamic validation
 * (copied from https://vee-validate.logaretm.com/v4/guide/composition-api/validation#handling-events)
 * Unfortunately I have not found a way to write these function
 * parameters in a more readable way. Maybe someone else knows that
 * better. On the other hand there might a be a new feature with
 * TS4.7 which allows us to intantiate function with gernics
 */
export const useFieldLazy = <TValue = unknown>(
	name: Parameters<typeof useField>[0],
	rules?: MaybeRef<RuleExpression<TValue>>,
	opts?: Partial<FieldOptions<TValue>>,
) => {
	const field = useField<TValue>(name, rules, {
		...opts,
		validateOnValueUpdate: false,
	});

	const validationListeners = computed<ValidationListeners<typeof field>>(() => {
		// If the field is valid or have not been validated yet
		// lazy
		if (!field.errorMessage.value) {
			return {
				blur: field.handleChange,
				change: field.handleChange,
				// disable `shouldValidate` to avoid validating on input
				input: (e: unknown) => field.handleChange(e, false),
			};
		}
		// Aggressive
		return {
			blur: field.handleChange,
			change: field.handleChange,
			input: field.handleChange, // only switched this
		};
	});

	return {
		...field,
		validationListeners,
	};
};
