<template>
	<form>
		<div class="flex">
			<div class="inline-block mr-6 text-secondary">
				Allgemein
			</div>
		</div>
		<div class="text-xl mt-10 mb-3">
			Activity-Name
		</div>
		<!-- create -->
		<AppInput
			v-model="values.activityName"
			class="text-white bg-primary-300 rounded-md w-full px-2 text-2xl border border-primary-900 py-2 mb-12"
			placeholder="Activity-Name"
			name="activityName"
		/>
		<div class="flex justify-between mt-12">
			<AppButton
				size="sm"
				:disabled="!meta.valid"
				@click="onConfirm(values.activityName)"
			>
				Create Activity
			</AppButton>
			<AppButton
				size="sm"
				color="outline"
				@click="close"
			>
				Cancel
			</AppButton>
		</div>
	</form>
</template>

<script lang="ts" setup>
import AppButton from '@/components/AppButton.vue';
import { useActivitiesStore } from '@/store/activities';
import { useToast } from 'vue-toastification';
import * as yup from 'yup';
import { useForm } from 'vee-validate';
import AppInput from './AppInput.vue';

const activitiesStore = useActivitiesStore();
const toast = useToast();
const emit = defineEmits(['cancel']);

const { values, meta } = useForm({
	validationSchema: {
		activityName: yup.string().required('Activity-Name required'),
	},
});

const close = () => {
	emit('cancel', false);
};

const onConfirm = async (activityName: string) => {
	await activitiesStore.createActivity(activityName);
	toast.success(`Successfully created activity "${activityName}"`);
	close();
};
</script>
