<template>
	<div>
		<div class="text-2xl font-bold mt-10">
			{{ project?.name ?? client?.name }}
		</div>
		<div
			v-if="remainingQuotaMonthlyHours !== null"
			class="flex items-center space-x-2 text-primary-900"
		>
			<div class="w-6 h-6 flex items-center justify-center">
				<IconClock class="fill-primary-100" />
			</div>
			<div class="text-primary-900">
				Available Quota:
			</div>
			<div
				class="text-primary-900"
			>
				<span>
					{{ hoursToDisplayTime(remainingQuotaMonthlyHours) }}h
				</span>
				<span v-if="quotaMonthlyHours && remainingQuotaMonthlyHours !== quotaMonthlyHours">
					({{ hoursToDisplayTime(quotaMonthlyHours) }}h)
				</span>
			</div>
		</div>
		<div v-if="isExtraProject">
			<div class="flex items-center space-x-2 ">
				<div class="w-6 h-6 flex items-center justify-center">
					<IconExtraQuota class="text-success fill-current w-5 h-5" />
				</div>
				<span class="text-primary-900">Project will be charged separately</span>
			</div>
		</div>
		<div class="flex justify-between items-center mt-20 mb-3">
			<div class="text-xl">
				Quota
			</div>
		</div>
		<div class="space-y-3">
			<div
				v-if="futureAndActiveQuotas.length > 0"
				class="grid grid-cols-11 gap-x-4 relative"
			>
				<div class="col-span-3">
					<div class="text-xs text-gray">
						Startdate
					</div>
				</div>
				<div class="col-span-3">
					<div class="text-xs text-gray">
						Enddate (optional)
					</div>
				</div>
				<div class="col-span-3">
					<div class="text-xs text-gray">
						Quota
					</div>
				</div>
			</div>
			<QuotaListItem
				v-for="quota in futureAndActiveQuotas"
				:key="quota.quotaId"
				:quota="quota"
				:editable="!isInteractionLocked"
				:on-update="onUpdateQuota"
				:on-delete="onDeleteQuota"
				@start-edit-mode="onStartEditMode"
				@end-edit-mode="onEndEditMode"
			/>
			<QuotaForm
				v-if="isCreateModeActive"
				:is-extra="isExtraProject"
				:active-quotas="quotasForSelectedEntity"
				:show-extra-option="quotasForSelectedEntity.length === 0 && project !== null"
				:error-message="createQuotaErrorMessage"
				@create="onCreateQuota"
				@cancel="onCancelCreateMode"
			/>
			<button
				v-if="!isCreateModeActive"
				type="button"
				class="border-2 border-dashed border-primary-900/30 w-full p-4 rounded flex items-center justify-center space-x-2 text-white/60 hover:text-white/80 hover:border-white/60 disabled:cursor-not-allowed"
				:disabled="isInteractionLocked"
				@click="startCreateMode"
			>
				<IconPlus class="w-4 h-4" />
				<span>Add Quota</span>
			</button>
		</div>

		<div class="text-xl mt-8 mb-3">
			Quota History
		</div>
		<div
			v-if="pastQuotas.length <= 0"
			class="mb-4 text-gray italic"
		>
			No Quota
		</div>
		<div
			v-else
			class="space-y-3"
		>
			<div class="grid grid-cols-11 gap-x-4 relative">
				<div class="col-span-3">
					<div class="text-xs text-gray">
						Startdate
					</div>
				</div>
				<div class="col-span-3">
					<div class="text-xs text-gray">
						Enddate (optional)
					</div>
				</div>
				<div class="col-span-3">
					<div class="text-xs text-gray">
						Quota
					</div>
				</div>
			</div>
			<QuotaListItem
				v-for="quota in pastQuotas"
				:key="quota.quotaId"
				:quota="quota"
			/>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { Project } from '@/models/Project';
import { Client } from '@/models/Client';
import {
	Quota,
	startsAfterSpecificDate,
	isActiveDuringSpecificDate,
	QuotaSubmitData,
	startsBeforeSpecificDate,
	getRemainingQuotaHoursForSpecificDate,
	getActiveQuotaDuringTimeRange,
	removeQuotaFromArray,
} from '@/models/Quota';
import { useQuotasQuery } from '@/composables/queries/useQuotasQuery';
import { useQuotaInfoQuery } from '@/composables/queries/useQuotaInfoQuery';
import { useProjectsQuotasQuery } from '@/composables/queries/useProjectsQuotasQuery';
import { PropType, computed, ref } from 'vue';
import IconClock from '@/assets/clock-icon.svg?component';
import IconExtraQuota from '@/assets/layer-plus-solid.svg?component';
import { useToday } from '@/composables/useToday';
import { hoursToDisplayTime } from '@/shared/times';
import QuotaListItem from '@/components/QuotaListItem.vue';
import QuotaForm from '@/components/QuotaForm.vue';
import { useQuotasMutation } from '@/composables/queries/useQuotasMutation';
import { useMainStore } from '@/store/main';
import { useToast } from 'vue-toastification';
import { getDateTime, getSafeArray, wrapResultAsyncFunction } from '@/utilities/Helpers';
import { useConfirmationModal } from '@/composables/useConfirmationModal';
import { onBeforeRouteUpdate } from 'vue-router';
import {
	err, errAsync, ok, okAsync,
} from 'neverthrow';
import { useLock } from '@/composables/useLock';
import IconPlus from '../assets/icon-plus.svg?component';

const props = defineProps({
	client: {
		type: Object as PropType<Client>,
		required: true,
	},
	project: {
		type: Object as PropType<Project | null>,
		default: null,
	},
});

const isCreateModeActive = ref(false);

const today = useToday();

const { data: projectQuotasData } = useQuotasQuery(computed(() => ({
	quotaInfoId: props.project?.quotaInfoId,
})));

const projectQuotas = computed(() => getSafeArray(projectQuotasData.value));

const { data: clientQuotasData } = useQuotasQuery(computed(() => ({
	quotaInfoId: props.client.quotaInfoId,
})));

const clientQuotas = computed(() => getSafeArray(clientQuotasData.value));

const { data: clientProjectsQuotasData } = useProjectsQuotasQuery(computed(() => ({
	clientId: props.client.id,
})));

const clientProjectsQuotas = computed(() => getSafeArray(clientProjectsQuotasData.value));

const { data: quotaInfo } = useQuotaInfoQuery(computed(() => ({
	quotaInfoId: props.project?.quotaInfoId,
})));

const quotasForSelectedEntity = computed<Quota[]>(() => {
	if (props.project) {
		return projectQuotas.value;
	}

	return clientQuotas.value;
});

const activeClientQuota = computed(() => getSafeArray(clientQuotas.value)
	.find((quota) => isActiveDuringSpecificDate(today.value, quota)));

const futureAndActiveQuotas = computed<Quota[]>(() => quotasForSelectedEntity.value
	.filter((quota) => startsAfterSpecificDate(today.value, quota) || isActiveDuringSpecificDate(today.value, quota)));

const pastQuotas = computed<Quota[]>(() => quotasForSelectedEntity.value
	.filter((quota) => startsBeforeSpecificDate(today.value, quota) && !isActiveDuringSpecificDate(today.value, quota)));

const activeQuota = computed(() => quotasForSelectedEntity.value
	.find((quota) => isActiveDuringSpecificDate(today.value, quota)));

const quotaMonthlyHours = computed(() => {
	if (quotaInfo.value?.isExtra) {
		return activeQuota.value?.monthlyHours ?? null;
	}

	if (!activeClientQuota.value?.monthlyHours) {
		return null;
	}

	return activeClientQuota.value.monthlyHours;
});

const remainingQuotaMonthlyHours = computed(() => {
	if (quotaInfo.value?.isExtra) {
		return quotaMonthlyHours.value;
	}

	return getRemainingQuotaHoursForSpecificDate(clientQuotas.value, clientProjectsQuotas.value, today.value);
});

const createQuotaErrorMessage = ref('');

const {
	createQuota,
	updateQuota: updateQuotaMutation,
	deleteQuota,
} = useQuotasMutation();

const mainStore = useMainStore();

const toast = useToast();

const {
	lock: lockInteraction,
	unlock: unlockInteraction,
	isLocked: isInteractionLocked,
} = useLock();

const startCreateMode = () => {
	createQuotaErrorMessage.value = '';
	isCreateModeActive.value = true;
	lockInteraction('createMode');
};

const onCancelCreateMode = () => {
	createQuotaErrorMessage.value = '';
	isCreateModeActive.value = false;
	unlockInteraction();
};

onBeforeRouteUpdate(onCancelCreateMode);

const onStartEditMode = () => {
	lockInteraction('editMode');
};

const onEndEditMode = () => {
	unlockInteraction();
};

const { show: showOverwriteQuotaConfirmationModal } = useConfirmationModal({
	title: 'Conflicting Quota',
	description: 'There already is an active quota. Do you want to automatically end this quota one day before your new quota?',
	cancelLabel: 'Cancel',
	confirmLabel: 'Confirm',
});

const validateQuotaMutation = (newQuota: QuotaSubmitData, activeQuotaDuringTimeRange?: Quota | null, allowOverwrite = true) => {
	if (activeQuotaDuringTimeRange) {
		if (activeQuotaDuringTimeRange.till) {
			return errAsync('Only one active Quota is allowed');
		}

		if (startsAfterSpecificDate(newQuota.from, activeQuotaDuringTimeRange) && newQuota.till && activeQuotaDuringTimeRange.from < newQuota.till) {
			return errAsync(`Enddate must be less than ${activeQuotaDuringTimeRange.from.toISODate()}`);
		}

		if (!allowOverwrite) {
			return errAsync('Quota cannot be updated to this new date.');
		}

		return showOverwriteQuotaConfirmationModal()
			.map(() => true)
			.mapErr(() => 'Quota cannot be updated to this new date.');
	}

	return okAsync(true);
};

const onCreateQuota = async (quota: QuotaSubmitData) => {
	if (!mainStore.user) {
		return;
	}

	const activeQuotaDuringTimeRange = getActiveQuotaDuringTimeRange(quota, quotasForSelectedEntity.value);

	const validationResult = await validateQuotaMutation(quota, activeQuotaDuringTimeRange);

	if (validationResult.isErr()) {
		createQuotaErrorMessage.value = validationResult.error;
		return;
	}

	const clientProjectQuotasWithoutActiveOne = activeQuotaDuringTimeRange
		? removeQuotaFromArray(activeQuotaDuringTimeRange, clientProjectsQuotas.value)
		: clientProjectsQuotas.value;

	const availableQuotaHours = props.project
		? getRemainingQuotaHoursForSpecificDate(clientQuotas.value, clientProjectQuotasWithoutActiveOne, quota.from)
		: null;

	if (availableQuotaHours !== null && availableQuotaHours < quota.monthlyHours && !quota.isExtra) {
		createQuotaErrorMessage.value = `On ${getDateTime(quota.from).toLocaleString()}, there are only ${availableQuotaHours} hours of available quota`;
		return;
	}

	if (activeQuotaDuringTimeRange) {
		const updateResult = await updateQuotaMutation.mutateAsync({
			quotaId: activeQuotaDuringTimeRange.quotaId,
			quota: {
				...activeQuotaDuringTimeRange,
				till: quota.from.minus({ days: 1 }),
			},
		});

		if (updateResult.isErr()) {
			toast.error(updateResult.error.message);
			return;
		}
	}

	const result = await createQuota.mutateAsync({
		quota,
		client: props.client,
		project: props.project ?? undefined,
		user: mainStore.user,
	});

	if (result.isErr()) {
		toast.error(result.error.message);
		return;
	}

	toast.success(`Successfully added new quota for "${props.project?.name || props.client.name}"`);

	onCancelCreateMode();
};

const onUpdateQuota = wrapResultAsyncFunction(async (quota: Quota) => {
	const quotasWithoutOneToUpdate = removeQuotaFromArray(quota, quotasForSelectedEntity.value);

	const activeQuotaDuringTimeRange = getActiveQuotaDuringTimeRange(quota, quotasWithoutOneToUpdate);

	const validationResult = await validateQuotaMutation(quota, activeQuotaDuringTimeRange, false);

	if (validationResult.isErr()) {
		return err(validationResult.error);
	}

	const mutationResult = await updateQuotaMutation.mutateAsync({ quotaId: quota.quotaId, quota });

	if (mutationResult.isErr()) {
		return err(mutationResult.error.message);
	}

	toast.success('Successfully updated quota');
	onCancelCreateMode();

	return ok(mutationResult.value);
});

const onDeleteQuota = (quota: Quota) => wrapResultAsyncFunction(deleteQuota.mutateAsync)({ quota })
	.map((value) => {
		toast.success('Successfully deleted quota');
		return value;
	});

const isExtraProject = computed(() => projectQuotas.value.some((quota) => quota.isExtra));
</script>
