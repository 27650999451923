import { supabase } from '@/lib/supabase';
import { SharedBeatMeta, toSupabaseData, createSharedBeatFromSupabase } from '@/models/SharedBeats';

export const createNewSharedBeat = async (sharedBeatMeta: SharedBeatMeta) => {
	const supabaseSharedBeat = toSupabaseData(sharedBeatMeta);
	const { data } = await supabase
		.from('beats_shared')
		.insert(supabaseSharedBeat)
		.select()
		.single();
	return data ? createSharedBeatFromSupabase(data) : null;
};

export const getSharedBeatByShortcode = async (shortCode: string) => {
	const { data } = await supabase
		.from('beats_shared')
		.select('*')
		.eq('short_code', shortCode)
		.single();
	return data ? createSharedBeatFromSupabase(data) : null;
};
