<template>
	<div class="relative overflow-hidden">
		<div class="flex flex-col items-center justify-between h-[100vh] min-h-[700px] pt-12">
			<SunHealthLogo />
			<div class="tablet:mt-[223px] z-[10000] flex flex-col items-center px-4">
				<h1 class="text-5xl mobile:text-6xl phablet:text-[80px] font-bold leading-none text-center">
					Welcome to SunHealth
				</h1>
				<p class="text-base phablet:text-xl tracking-[0.3px] opacity-50 mt-4 mb-12 text-center">
					Log in with your work Google email
				</p>
				<AppButton
					class="max-w-[326px] text-center"
					icon="google"
					size="sm"
					icon-position="left"
					width="full"
					tabindex="-1"
					color="primary"
					label="Continue Log in with Google"
					@click="onLoginWithGoogle"
				/>
			</div>
			<a
				ref="noopener"
				target="_blank"
				rel="noopener noreferrer"
				class="mb-[80px] text-base tracking-[0.3px] text-center opacity-50 hover:opacity-75 transition-all ease-in-out mt-20"
				href="https://sunplex.atlassian.net/wiki/spaces/SUN/pages/179830957/SunHealth+-+Overview+SL+Space"
			>
				Any problems? Check out the Confluence entry
			</a>
		</div>
		<div class="absolute pointer-events-none top-[-100px] phablet:top-[-175px] desktop:top-[-280px] right-[-100px] phablet:right-[-175px] desktop:right-[-330px] opacity-50 desktop:opacity-100 w-[300px] phablet:w-[450px] desktop:w-[741px] h-[300px] phablet:h-[450px] desktop:h-[741px]">
			<img
				src="../assets/sunhealth-3d-blur.png"
				alt="decoration icon"
			>
		</div>
		<div class="absolute pointer-events-none bottom-[-100px] phablet:bottom-[-175px] desktop:bottom-[-360px] left-[-100px] phablet:left-[-175px] desktop:left-[-220px] opacity-50 desktop:opacity-100 w-[300px] phablet:w-[450px] desktop:w-[741px] h-[300px] phablet:h-[450px] desktop:h-[741px]">
			<img
				src="../assets/sunhealth-3d.png"
				alt="decoration icon"
			>
		</div>
	</div>
</template>

<script setup lang="ts">
import { useAuthentication } from '@/store/auth';
import { useRouter } from 'vue-router';
import SunHealthLogo from '../assets/sunhealth-logo.svg?component';
import AppButton from '../components/AppButton.vue';

const router = useRouter();
const { loginWithGoogle } = useAuthentication();

const onLoginWithGoogle = async () => {
	const result = await loginWithGoogle();

	if (result.isOk()) {
		router.push({ name: 'Dashboard' });
	}
};
</script>
