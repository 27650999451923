import { createApp, h } from 'vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/vue';
import Toast from 'vue-toastification';
import VueTippy from 'vue-tippy';
import { createVfm } from 'vue-final-modal';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { plugin as vueTransitionsPlugin } from '@morev/vue-transitions';
import App from './App.vue';
import router from './router';
import { pinia } from './plugins/pinia';
import './styles/tailwind.css';
import { options as toastOptions } from './plugins/Toast';
import { appProgressLoader } from './plugins/AppProgressLoader';
import { plausible } from './plugins/plausible/setup';
import './styles/markdown.css';
import '@morev/vue-transitions/styles';
import '@/lib/sortablejs';

const app = createApp({
	render: () => h(App),
});

Sentry.init({
	app,
	dsn: 'https://d24f2c15fcc34b788cc6002553a3d499@o440428.ingest.sentry.io/6006618',
	integrations: [
		new BrowserTracing({
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
		}),
		new Sentry.Replay(),
	],
	allowUrls: ['netlify.app', /^\//],
	environment: import.meta.env.MODE,
	release: __COMMIT_REF__,
	tracePropagationTargets: ['netlify.app', /^\//],
	tracesSampleRate: 1.0,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});

app.use(createVfm());

app.use(VueTippy, {
	directive: 'tippy',
	defaultProps: {
		placement: 'bottom',
		theme: 'sunhealth',
		animation: 'shift-toward',
	},
});

app.use(router);
app.use(appProgressLoader, { router });
app.use(pinia);
app.use(Toast, toastOptions);
app.use(plausible);
app.use(VueQueryPlugin);

app.use(vueTransitionsPlugin());

app.mount('#app');
