import { useOptionToggle } from '@/composables/useOptionToggle';
import { computedEager } from '@vueuse/core';
import { useToday } from './useToday';

export interface UseTimePeriodToggleOptions {
	initialPeriod?: 'day' | 'week' | 'month' | 'year';
}

export const useTimePeriodToggle = (options: UseTimePeriodToggleOptions) => {
	const {
		selected,
		toggle,
		selectedLabel,
	} = useOptionToggle([
		{
			value: 'day',
			label: 'this day',
		},
		{
			value: 'week',
			label: 'this week',
		},
		{
			value: 'month',
			label: 'this month',
		},
		{
			value: 'year',
			label: 'this year',
		},
	], options.initialPeriod ?? 'month');

	const today = useToday();

	const selectedStartDate = computedEager(() => today.value.startOf(selected.value));

	return {
		selected,
		toggle,
		selectedLabel,
		selectedStartDate,
	};
};
