import { supabase } from '@/lib/supabase';
import { DateTime } from 'luxon';

export interface GetLoggedDataOptions {
	startDate: DateTime;
	endDate?: DateTime;
	userId?: string;
}

export const getLoggedData = async ({ startDate, endDate, userId }: GetLoggedDataOptions) => {
	const startDateInIso = startDate.toISODate();
	const endDateInIso = endDate?.toISODate() ?? undefined;

	if (!startDateInIso) {
		return [];
	}

	const { data } = await supabase
		.rpc('get_logged_data', { from_date: startDateInIso, to_date: endDateInIso, user_id: userId });

	return data ?? [];
};
