import { computed } from 'vue';
import {
	createToastInterface, EventBus, PluginOptions, POSITION, TYPE,
} from 'vue-toastification';
import { getUsedOperatorSystem } from '../utilities/Helpers';
import IconSuccess from '../components/IconSuccess.vue';
import IconWarning from '../components/IconWarning.vue';
import IconError from '../components/IconError.vue';
import IconInfo from '../components/IconInfo.vue';

// Create a new event bus
const eventBus = new EventBus();

const systemPosition = computed(() => {
	if (getUsedOperatorSystem(navigator.userAgent) === 'Windows OS') {
		return POSITION.BOTTOM_RIGHT;
	}
	return POSITION.TOP_RIGHT;
});

const options: PluginOptions = {
	position: systemPosition.value,
	toastClassName: 'font-medium text-sm mobile:text-base text-primary-400',
	closeButtonClassName: 'opacity-50 ml-4',
	timeout: 5000,
	closeOnClick: false,
	pauseOnFocusLoss: true,
	pauseOnHover: true,
	draggable: false,
	draggablePercent: 0.6,
	showCloseButtonOnHover: false,
	hideProgressBar: true,
	closeButton: false,
	rtl: false,
	toastDefaults: {
		[TYPE.SUCCESS]: {
			toastClassName: 'bg-success min-h-[44px] p-0.5 m-2 w-[360px] flex items-center rounded-full font-medium text-base text-primary-400',
			icon: IconSuccess,
		},
		[TYPE.ERROR]: {
			toastClassName: 'bg-error min-h-[44px] min-w-[360px] pl-0.5 pt-0.5 pb-0.5 pr-4 m-2 flex items-center rounded-full font-medium text-base text-error',
			bodyClassName: 'text-primary-400',
			icon: IconError,
		},
		[TYPE.INFO]: {
			toastClassName: 'bg-primary-100 min-h-[44px] min-w-[360px] p-0.5 pr-4 m-2 flex items-center rounded-full font-medium text-base text-primary-100',
			bodyClassName: 'text-primary-400',
			icon: IconInfo,
		},
		[TYPE.WARNING]: {
			toastClassName: 'bg-warning min-h-[44px] min-w-[360px] pl-0.5 pt-0.5 pb-0.5 pr-4 m-2 flex items-center rounded-full font-medium text-base text-warning',
			bodyClassName: 'text-primary-400',
			icon: IconWarning,
		},
	},
};

const toast = createToastInterface({
	eventBus,
	...options,
});

export default toast;

export {
	options, eventBus,
};
