<template>
	<div class="px-6 desktop:px-0 mb-6 desktop:mb-9 flex flex-col desktop:flex-row mt-6 desktop:justify-between space-y-[31px] desktop:space-y-0 desktop:space-x-8">
		<div class="flex space-x-9">
			<div class="flex items-center divide-x-[1px] divide-primary-300">
				<div class="flex items-center pr-3 phablet:pr-6">
					<div class="w-3 phablet:w-12 h-1 bg-secondary rounded-2xl mr-4" />
					<p class="text-xl mobile:text-2xl desktop:text-4xl font-bold">
						{{ day.toFormat('ccc', { locale: LANGUAGE }) }},
						{{ day.toFormat('DDD', { locale: LANGUAGE }) }}
					</p>
				</div>
				<StatItem
					label="Total"
					:value="getTotalTimeInMs(beats)"
					:formatter="msToDisplayTime"
					suffix="h"
					class="px-3 phablet:px-6 text-left"
				/>
				<AppIcon
					v-if="hasAbsence"
					class="px-3 phablet:px-6 text-left hidden desktop:block"
					icon="absence"
				/>
				<AppIcon
					v-if="currentDayIsoDate && isCompanyHoliday(currentDayIsoDate)"
					v-tippy="{
						theme: 'sunhealth-mt',
						content: renderHolidayContent(currentDayIsoDate),
						allowHTML: true,
					}"
					class="pl-3 phablet:pl-6 hidden desktop:block"
					icon="holiday"
				/>
			</div>
		</div>
		<div class="flex items-center justify-between desktop:justify-start desktop:space-x-10">
			<div class="flex items-center space-x-4">
				<button
					class="flex items-center justify-center rounded-full py-[9px] phablet:py-4 px-[6px] phablet:px-3 border-2 border-primary-300 w-[35px] phablet:w-12 h-[35px] phablet:h-12 hover:bg-primary-400 text-gray hover:text-white"
					type="button"
					@click="toPreviousDay"
				>
					<ArrowIcon class="fill-current w-full h-full" />
				</button>
				<button
					class="flex items-center justify-center rounded-full py-[9px] phablet:py-4 px-[6px] phablet:px-3 border-2 border-primary-300 w-[35px] phablet:w-12 h-[35px] phablet:h-12 hover:bg-primary-400 text-gray hover:text-white"
					type="button"
					@click="toNextDay"
				>
					<ArrowIcon class="fill-current transform rotate-180 w-full h-full" />
				</button>
			</div>
			<div class="flex space-x-2">
				<router-link :to="{ name: 'DaySingle', params: { day: DateTime.now().toISODate() } }">
					<AppChip :selected="true">
						Day
					</AppChip>
				</router-link>
				<router-link :to="{ name: 'History', query: { day: day.toISODate() } }">
					<AppChip>Month</AppChip>
				</router-link>
			</div>
		</div>
	</div>
	<div class="bg-primary-600 px-6 py-8 phablet:p-12 phablet:rounded-t-2xl flex-1 h-full">
		<div
			v-if="isFuture || isOverCreateTreshhold || hasAbsence"
			class="mb-8 space-y-3"
		>
			<div
				v-if="isOverCreateTreshhold"
				class="font-medium tracking-wider bg-secondary p-4 rounded-2xl text-white text-opacity-100"
				:class="isFeatureAdvancedEditEnabled ? 'line-through' : ''"
			>
				Note: Since this entry was created more than 7-days ago, the logs aren’t editable
			</div>
			<div
				v-if="isFuture"
				class="font-medium tracking-wider bg-secondary p-4 rounded-2xl text-white text-opacity-100"
				:class="isFeatureAdvancedEditEnabled ? 'line-through' : ''"
			>
				Note: You cannot add/edit a log in the future.
			</div>
			<div
				v-if="(isFuture || isOverCreateTreshhold) && isFeatureAdvancedEditEnabled"
				class="font-medium tracking-wider bg-secondary p-4 rounded-2xl text-white text-opacity-100"
			>
				Note: You are in the advanced edit mode. Use it wisely! 🧙🏽‍♂️
			</div>
			<div
				v-if="hasAbsence"
				class="font-medium tracking-wider bg-gray p-4 rounded-2xl text-white text-opacity-100 hidden desktop:block"
			>
				{{ `Note: You are set as absent on ${day.toFormat('ccc')}, ${day.toFormat('DDD')}.` }}
			</div>
		</div>
		<ul
			v-if="isDayEditable || beats.length > 0"
			:key="day.toISO() ?? ''"
			class="grid grid-cols-cards gap-8 transition-all duration-300 ease-in-out z-0"
		>
			<EmptyCard
				v-if="isDayEditable && !addNewBeatStarted"
				key="newLogCardEmpty"
				:is-day-editable="isDayEditable && !isLocked"
				@click="addNewBeat"
			>
				<IconPlus />
				<div>
					Add a new log
				</div>
			</EmptyCard>
			<div v-if="addNewBeatStarted">
				<AddNewLogCard
					key="newLogCard"
					class="z-[101]"
					:day="day"
					@cancel="abortNewBeat"
					@beat-created="onBeatCreated"
				/>
			</div>
			<TransitionGroup
				name="card"
				appear
			>
				<TodaysLogCard
					v-for="(beat, $index) in beats"
					:key="beat.beatId"
					:beat="beat"
					:style="`z-index: ${20 - $index}`"
					is-dark
					:editable="(isDayEditable && !isLocked) || isLockReason(beat.beatId)"
					@editing:start="lock(beat.beatId)"
					@editing:end="unlock"
					@editing:success="onBeatEdited"
				/>
			</TransitionGroup>
		</ul>
		<div
			v-else
			class="grid grid-cols-cards gap-8 z-0 mt-8"
		>
			<EmptyCard :is-day-editable="isDayEditable">
				No logs this day
			</EmptyCard>
		</div>
	</div>
</template>

<script setup lang="ts">
import {
	computed, onUnmounted, PropType, ref, toRef, watchEffect,
} from 'vue';
import { DateTime } from 'luxon';
import { useRouter } from 'vue-router';
import { useBeatsStore } from '@/store/beats';
import { useLock } from '@/composables/useLock';
import { useUnsavedChanges } from '@/composables/useUnsavedChanges';
import { useRouteLeaveConfirmation } from '@/composables/useRouteLeaveConfirmation';
import { useAbsencesStore } from '@/store/absences';
import { useFeiertage } from '@/composables/useFeiertage';
import StatItem from '@/components/StatItem.vue';
import { msToDisplayTime } from '@/shared/times';
import { useToday } from '@/composables/useToday';
import { useTimeToBeatTracking } from '@/composables/useTimeToBeatTracking';
import { shouldDisplayPopup } from '@/services/ConfidenceLottery';
import { useHowConfidentModal } from '@/composables/useHowConfidentModal';
import { usePlausible } from '../plugins/plausible/usePlausible';
import { useMainStore } from '../store/main';
import TodaysLogCard from '../components/TodaysLogCard.vue';
import AddNewLogCard from '../components/AddNewLogCard.vue';
import AppIcon from '../components/AppIcon.vue';
import AppChip from '../components/AppChip.vue';
import EmptyCard from '../components/EmptyCard.vue';
import { Beat, getTotalTimeInMs } from '../models/Beats';
import ArrowIcon from '../assets/long-arrow-left-regular.svg?component';
import IconPlus from '../assets/icon-plus.svg?component';
import { Absence } from '../models/Absence';
import { FeatureFlag, isFeatureFlagEnabled } from '../models/FeatureFlag';
import { LANGUAGE } from '../utilities/Constants';

const props = defineProps({
	day: {
		type: Object as PropType<DateTime>,
		required: true,
	},
});

const router = useRouter();
const mainStore = useMainStore();
const beatsStore = useBeatsStore();
const absenceStore = useAbsencesStore();
const addNewBeatStarted = ref(false);
const isFeatureAdvancedEditEnabled = ref(isFeatureFlagEnabled(FeatureFlag.ADVANCED_EDIT));

const todayIsoDate = computed(() => useToday().value.toISODate());
const currentDayIsoDate = computed(() => props.day.toISODate());
const createTreshhold = mainStore.today.minus({ days: 7 }).toISODate();

const {
	lock, unlock, isLockReason, isLocked, onLockChange,
} = useLock();

const { startInteraction, stopInteraction, stopAndTrackInteraction } = useTimeToBeatTracking({ interactionType: 'calendar' });

watchEffect(async () => {
	if (!mainStore.user) {
		return;
	}

	beatsStore.requestBeatsForYear(props.day.year);
});

const beats = computed(() => beatsStore.getBeatsFromDay(props.day));

const addNewBeat = () => {
	if (isLocked.value) {
		return;
	}

	addNewBeatStarted.value = true;
	startInteraction();
	lock('new-beat');
};

const abortNewBeat = () => {
	unlock();
	stopInteraction();
	addNewBeatStarted.value = false;
};

const removeBeforeResolveGuard = router.beforeResolve(abortNewBeat);

onUnmounted(removeBeforeResolveGuard);

const toPreviousDay = () => {
	const previousDay = props.day.minus({ day: 1 });
	router.push({ name: 'DaySingle', params: { day: previousDay.toISODate() } });
};

const toNextDay = () => {
	const previousDay = props.day.plus({ day: 1 });
	router.push({ name: 'DaySingle', params: { day: previousDay.toISODate() } });
};

const isOverCreateTreshhold = computed(() => ((currentDayIsoDate.value && createTreshhold) ? (currentDayIsoDate.value < createTreshhold) : false));

const isFuture = computed(() => ((currentDayIsoDate.value && todayIsoDate.value) ? (currentDayIsoDate.value > todayIsoDate.value) : false));

const isDayEditable = computed(() => (!isOverCreateTreshhold.value && !isFuture.value) || isFeatureAdvancedEditEnabled.value);

// Absence Feature

// Returns an absence object with the absence of today, if it exists, otherwise it's null.
// Good for validation. :)
// Has to be a computed, so that it's reactive & the state can be accessed when ever the day changes
const thisDaysAbsence = computed(() => absenceStore.absences.filter((absence: Absence) => props.day.toISODate() === absence.date)[0]);

// Checks if active date you are on, is matching any existing absence
const hasAbsence = computed<boolean>(() => !!thisDaysAbsence.value);

const plausible = usePlausible();

const howConfidentModal = useHowConfidentModal({ beat: null });

const onBeatCreated = (beat: Beat) => {
	if (isFeatureFlagEnabled(FeatureFlag.HOW_CONFIDENT) && shouldDisplayPopup(beat.timeIntervalInMs / 3_600_000)) {
		howConfidentModal.show({ beat });
	}

	stopAndTrackInteraction();
	plausible.trackEvent('create-log', { props: { source: 'calendar' } });
	unlock();
	addNewBeatStarted.value = false;
};

const onBeatEdited = () => {
	plausible.trackEvent('edit-log', { props: { source: 'calendar' } });
};

const { toggle, hasAnyUnsavedChanges } = useUnsavedChanges();

const { isCompanyHoliday, renderHolidayContent } = useFeiertage(toRef(props.day));

onLockChange(toggle);

useRouteLeaveConfirmation({
	modal: {
		title: 'Warning: There are some unsaved changes',
		description: 'Are you sure you want to exit without saving them?',
		confirmLabel: 'Exit without saving',
		cancelLabel: 'Go back',
	},
	showModal: hasAnyUnsavedChanges,
	showModalOnRouteUpdate: true,
});

</script>
