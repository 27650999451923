<template>
	<div
		ref="wrapperRef"
		class="relative flex flex-col justify-center items-center z-50"
	>
		<button
			ref="addNoteButtonRef"
			v-tippy="{ content: tippyContent, theme: tippyTheme }"
			v-bind="$attrs"
			class="group-scope flex items-center py-2 px-2.5 rounded-full bg-primary-300 leading-none font-medium text-white max-w-max truncate transition-all ease-in-out outline-none h-[40px] w-[40px]"
			:class="{
				'text-opacity-90 cursor-pointer': isNoteVisible, 'text-opacity-30': !isNoteVisible && !isEditable, 'text-opacity-50 hover:text-opacity-90 focus:text-opacity-90 ring-offset-primary-400 focus:ring-1 ring-offset-2 ring-primary-300 cursor-pointer': isEditable, 'cursor-default': !isEditable,
			}"
			type="button"
			@focus="focus"
			@blur="onBlur"
			@click.stop.prevent="onButtonClick"
		>
			<AddedDocumentIcon
				v-show="modelValue.length > 0"
				class="w-5 h-6 fill-current text-green-500"
			/>
			<AddDocumentIcon
				v-show="modelValue.length === 0"
				class="w-5 h-6 fill-current"
			/>
		</button>
		<teleport to="#app">
			<div
				ref="menuRef"
				class="z-50"
			>
				<div
					v-show="isNoteVisible && isEditable"
					v-click-outside="onTextareaBlur"
					class="rounded-xl border border-gray hover:border-gray focus:border-gray transition bg-primary-400 min-h-full"
					:class="inCard ? 'left-[-168px]' : 'left-[-110px]'"
				>
					<textarea
						v-show="isEditable"
						ref="noteTextareaRef"
						v-model="data"
						class="text-white text-[14px] tracking-wider pl-3 pt-3 pr-3 right-0 w-[280px] bg-transparent focus:outline-none resize-none box-border"
						:class="modelValue.length > 0 ? 'mb-10' : 'mb-2'"
						placeholder="Add a note"
						aria-label="Add a note"
						@focus="onTextareaFocus"
						@keydown.tab="onKeypressEsc"
						@keydown.esc="onKeypressEsc"
					/>
					<button
						v-show="modelValue.length > 0 && isEditable"
						class="absolute bottom-[12.6px] left-3 flex space-x-2 bg-primary-400 text-white/50 cursor-pointer"
						type="button"
						@click="removeNote"
					>
						<TrashIcon class="w-4 fill-current" />
						<div>
							Delete note
						</div>
					</button>
				</div>
			</div>
		</teleport>
	</div>
</template>

<script setup lang="ts">
import {
	computed, nextTick, ref, watch,
} from 'vue';
import { useTextSelection, useTextareaAutosize, useVModel } from '@vueuse/core';
import { usePopper } from '../composables/usePopper';
import AddDocumentIcon from '../assets/add-document.svg?component';
import AddedDocumentIcon from '../assets/added-document.svg?component';
import vClickOutside from '../plugins/ClickOutsideDirective';
import TrashIcon from '../assets/trash-icon.svg?component';

const props = defineProps({
	modelValue: {
		type: String,
		default: '',
	},
	isEditable: {
		type: Boolean,
		default: false,
	},
	inCard: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits(['update:modelValue', 'focusBeatInlineNote']);

const { textarea: noteTextareaRef, input } = useTextareaAutosize();
const data = useVModel(props, 'modelValue', emit);
watch(data, () => { input.value = data.value; });

const menuRef = ref<HTMLElement>();
const wrapperRef = ref<HTMLElement>();

const addNoteButtonRef = ref<HTMLButtonElement>();
const hasFocus = ref(false);

const tippyContent = computed(() => {
	const contentLength = props.modelValue.length > 0;

	if (contentLength && props.isEditable) {
		return 'Edit your note';
	}
	if (contentLength && !props.isEditable) {
		return props.modelValue;
	}

	return 'Add a note';
});

const tippyTheme = computed(() => {
	const { isEditable } = props;

	if (isEditable) {
		return 'sunhealth';
	}
	return 'note';
});

const focus = () => {
	addNoteButtonRef.value?.focus();
	hasFocus.value = true;
	emit('focusBeatInlineNote', hasFocus.value);
};

const onBlur = () => {
	hasFocus.value = false;
	emit('focusBeatInlineNote', hasFocus.value);
};

const removeNote = () => {
	emit('update:modelValue', '');
};

const hasFocedNoteVisibility = ref(false);
const isTouchDevice = computed(() => 'ontouchstart' in document.documentElement);

const isNoteVisible = computed(() => hasFocedNoteVisibility.value);

const { isOpen: isNoteOpen } = usePopper({
	placement: ref('bottom-start'),
	offsetSkid: ref('0'),
	offsetDistance: ref('12'),
	popperNode: menuRef,
	triggerNode: wrapperRef,
});

const onButtonClick = () => {
	if (isTouchDevice.value) {
		hasFocedNoteVisibility.value = true;
		isNoteOpen.value = !isNoteOpen.value;
		return;
	}

	if (!props.isEditable && props.modelValue.length === 0) {
		return;
	}

	hasFocedNoteVisibility.value = true;
	isNoteOpen.value = !isNoteOpen.value;

	nextTick(() => {
		noteTextareaRef.value?.focus();
	});
};

const onTextareaFocus = () => {
	hasFocedNoteVisibility.value = true;
};

const { text } = useTextSelection();

const onTextareaBlur = () => {
	if (text.value.length) {
		return;
	}
	hasFocedNoteVisibility.value = false;
	isNoteOpen.value = false;
};

const onKeypressEsc = () => {
	hasFocedNoteVisibility.value = false;

	nextTick(() => {
		addNoteButtonRef.value?.focus();
	});
};

defineExpose({
	focus,
});
</script>

<style lang="scss" scoped>
textarea::selection {
  background-color: #733BFF;
  color: inherit;
}
</style>
