<template>
	<input
		v-model="value"
		class="block relative focus:ring-0 focus:outline-none appearance-none text-white bg-primary-300 rounded-lg w-full border border-primary-900 focus:border-white disabled:opacity-50 disabled:bg-black/40 outline-offset-0"
		:class="sizeClass"
		type="text"
		autocomplete="off"
		data-1p-ignore
		:name="name"
		:aria-label="name"
		:placeholder="placeholder"
	/>
</template>

<script lang="ts" setup>
import { InputSize, getSizeClass } from '@/models/InputSize';
import { useVModel } from '@vueuse/core';
import { PropType, computed } from 'vue';

const props = defineProps({
	modelValue: {
		type: String,
		default: '',
	},
	placeholder: {
		type: String,
		default: '',
	},
	name: {
		type: String,
		default: 'name',
	},
	size: {
		type: String as PropType<InputSize>,
		default: 'lg',
	},
});

const value = useVModel(props, 'modelValue');

const sizeClass = computed(() => getSizeClass(props.size));

</script>
