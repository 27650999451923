import { useModal } from 'vue-final-modal';
import { ResultAsync } from 'neverthrow';
import ConfirmationModal from '../components/modals/ConfirmationModal.vue';

export interface ConfirmationModalOptions {
	title: string;
	description: string;
	cancelLabel: string;
	confirmLabel: string;
	onConfirm?: () => void | Promise<void>;
}

export const useConfirmationModal = ({
	title,
	description,
	cancelLabel,
	confirmLabel,
	onConfirm,
}: ConfirmationModalOptions) => {
	const modal = useModal({
		component: ConfirmationModal,
	});

	const show = (overwriteOptions?: ConfirmationModalOptions) => ResultAsync.fromPromise(new Promise((resolve, reject) => {
		modal.patchOptions({
			attrs: {
				title: overwriteOptions?.title ?? title,
				description: overwriteOptions?.description ?? description,
				cancelLabel: overwriteOptions?.cancelLabel ?? cancelLabel,
				confirmLabel: overwriteOptions?.confirmLabel ?? confirmLabel,
				async onConfirm() {
					if (typeof onConfirm === 'function') {
						await onConfirm();
					}

					resolve(true);
					modal.close();
				},
				onCancel() {
					reject();
					modal.close();
				},
			},
		});

		modal.open();
	}), () => new Error());

	return {
		show,
	};
};
