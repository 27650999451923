<template>
	<div
		ref="tableRef"
		class="w-full"
	>
		<AppTable
			:table="table"
			:head-position-top="headPositionTop"
		/>
	</div>
</template>

<script lang="ts" setup>
import {
	FilterFnOption,
	createColumnHelper,
	getCoreRowModel, getFilteredRowModel, getSortedRowModel, useVueTable, SortingFn, sortingFns,
} from '@tanstack/vue-table';
import {
	PropType, h, watch, nextTick, ref, computed,
} from 'vue';
import { rankItem } from '@tanstack/match-sorter-utils';
import { Client } from '@/models/Client';
import { Project } from '@/models/Project';
import { TopicOverviewResolved } from '@/models/TopicOverview';
import { highlight } from '@/utilities/Helpers';
import DOMPurify from 'dompurify';
import AppTable from './AppTable.vue';

const props = defineProps({
	clients: {
		type: Array as PropType<Client[]>,
		default: () => ([]),
	},
	projects: {
		type: Array as PropType<Project[]>,
		default: () => ([]),
	},
	headPositionTop: {
		type: Number,
		default: 0,
	},
});

const fuzzyFilter: FilterFnOption<TopicOverviewResolved> = (row, columnId, value, addMeta) => {
	const itemRank = rankItem(row.getValue(columnId), value, { threshold: 2 });
	addMeta(itemRank);

	return itemRank.passed;
};

const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
	let dir = 0;

	if (rowA.columnFiltersMeta[columnId] && rowB.columnFiltersMeta[columnId]) {
		// @ts-ignore
		const rankA = rowA.columnFiltersMeta[columnId]?.rank!;
		// @ts-ignore
		const rankB = rowB.columnFiltersMeta[columnId]?.rank!;

		if (rankA !== rankB) {
			dir = rankA > rankB ? -1 : 1;
		}
	}

	return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

const columnHelper = createColumnHelper<TopicOverviewResolved>();

const columns = [
	columnHelper.accessor('client', {
		id: 'client',
		header: 'Client',
		cell: (context) => h('div', {
			innerHTML: DOMPurify.sanitize(highlight(context.getValue(), context.table.getState().globalFilter ?? '')),
		}),
		size: 300,
		sortingFn: fuzzySort,
	}),
	columnHelper.accessor('project', {
		id: 'project',
		header: 'Projekt',
		cell: (context) => h('div', {
			innerHTML: DOMPurify.sanitize(highlight(context.getValue(), context.table.getState().globalFilter ?? '')),
		}),
		size: 300,
		sortingFn: fuzzySort,
	}),
	columnHelper.accessor('description', {
		id: 'description',
		header: 'Descriptions',
		cell: (context) => h('div', {
			innerHTML: DOMPurify.sanitize(highlight(context.getValue(), context.table.getState().globalFilter ?? '')),
		}),
		size: 600,
		sortingFn: fuzzySort,
	}),
];

const resolvedTopicProjects = computed<TopicOverviewResolved[]>(() => props.projects.map((project) => ({
	description: project.description ?? '',
	client: props.clients.find((client) => client.id === project.clientId)?.name ?? '',
	project: project.name,
})));

const resolvedTopicClients = computed<TopicOverviewResolved[]>(() => props.clients.map((client) => ({
	description: client.description ?? '',
	client: client.name ?? '',
	project: '-',
})));

const resolvedTopicOverview = computed<TopicOverviewResolved[]>(() => resolvedTopicClients.value.concat(resolvedTopicProjects.value));

const tableRef = ref<HTMLElement | null>(null);

const table = useVueTable({
	columns,
	initialState: {
		sorting: [{ id: 'client', desc: false }],
	},
	get data() {
		return resolvedTopicOverview.value;
	},
	getCoreRowModel: getCoreRowModel(),
	getFilteredRowModel: getFilteredRowModel(),
	getSortedRowModel: getSortedRowModel(),
	enableSortingRemoval: false,
	enableRowSelection: false,
	globalFilterFn: fuzzyFilter,
	sortingFns: {
		fuzzySort,
	},
});

watch(() => table.getState().sorting, async () => {
	const lastPostion = tableRef.value?.scrollTop;

	await nextTick();
	if (tableRef.value) {
		tableRef.value.scrollTop = lastPostion ?? 0;
	}
});

defineExpose({ table });
</script>
