import {
	ref, computed, MaybeRef, unref,
} from 'vue';
import { DateTime, Interval } from 'luxon';
import { onKeyStroke } from '@vueuse/core';
import { AbsenceMeta, isIsoDateInAbsences } from '../models/Absence';
import { useMainStore } from '../store/main';
import { CalendarDay } from '../models/CalendarDay';
import {
	isWeekendDayInDateTime, isWeekendDayInCalendar, getDiffBetweenTwoDates,
} from '../utilities/Helpers';

export const useAbsenceSelection = (absences: MaybeRef<AbsenceMeta[]>) => {
	const mainStore = useMainStore();
	const selectedAbsences = ref<AbsenceMeta[]>([]);
	const rangeSelectionStart = ref<AbsenceMeta|null>();
	const rangeSelectionEnd = ref<AbsenceMeta | null>();
	const isAbsenceEditable = ref(false);
	const isDeselectModeActive = ref(false);
	const storedAbsences = computed(() => unref(absences));

	const removeAbsenceFromAbsences = (date: string) => unref(absences).filter((element: AbsenceMeta) => element.date !== date);

	const isAbsenceNotSelectableInDateTime = (weekdayNumber: number, inputIsoDate: string, userId: string) => isIsoDateInAbsences({ isoDate: inputIsoDate, absences: storedAbsences.value, userId }) || isWeekendDayInDateTime(weekdayNumber);

	const isAbsenceNotSelectableInCalendar = (weekdayNumber: number, inputIsoDate: string, userId: string) => isIsoDateInAbsences({ isoDate: inputIsoDate, absences: storedAbsences.value, userId }) || isWeekendDayInCalendar(weekdayNumber);

	const getIntervalFromTwoDates = (startIsoDate: string, endIsoDate: string) => Interval.fromDateTimes(
		DateTime.fromISO(startIsoDate).startOf('day'),
		DateTime.fromISO(endIsoDate).endOf('day'),
	);

	const toggleAbsence = (absence: AbsenceMeta) => {
		if (!mainStore.user) {
			return;
		}
		if (isIsoDateInAbsences({ isoDate: absence.date, absences: selectedAbsences.value }) && !isIsoDateInAbsences({ isoDate: absence.date, absences: storedAbsences.value, userId: mainStore.user.id })) {
			selectedAbsences.value = removeAbsenceFromAbsences(absence.date);
			return;
		}
		selectedAbsences.value.push(absence);
	};

	const resetRangeSelection = () => {
		if (isDeselectModeActive.value) {
			isDeselectModeActive.value = false;
		}
		rangeSelectionStart.value = null;
		rangeSelectionEnd.value = null;
	};

	const getValidIsoDate = (date: DateTime) => {
		const isoDate = date.toISODate();
		if (!isoDate) {
			return '';
		}
		return isoDate;
	};

	const getValidStartDateFromInterval = (interval: Interval) => {
		const startDate = interval.start;
		if (!startDate) {
			return DateTime.now();
		}
		return startDate;
	};

	const rangeSelectionPreview = computed((): AbsenceMeta[] => {
		if (!isAbsenceEditable.value) {
			return [];
		}
		if (!rangeSelectionStart.value || !rangeSelectionEnd.value) {
			return [];
		}
		const diff = getDiffBetweenTwoDates(rangeSelectionStart.value.date, rangeSelectionEnd.value.date);

		if (!diff) {
			return [];
		}

		const selectionStart = diff > 0 ? rangeSelectionStart.value.date : rangeSelectionEnd.value.date;
		const selectionEnd = diff > 0 ? rangeSelectionEnd.value.date : rangeSelectionStart.value.date;

		if (isDeselectModeActive.value) {
			return getIntervalFromTwoDates(selectionStart, selectionEnd).splitBy({ day: 1 })
				.filter((d) => mainStore.user && !isAbsenceNotSelectableInDateTime(getValidStartDateFromInterval(d).weekday, getValidIsoDate(getValidStartDateFromInterval(d)), mainStore.user.id))
				.map((d) => ({
					date: getValidIsoDate(getValidStartDateFromInterval(d)),
					userId: mainStore.user ? mainStore.user.id : 'no user found',
				}));
		}

		return getIntervalFromTwoDates(selectionStart, selectionEnd).splitBy({ day: 1 })
			.filter((d) => mainStore.user && !isAbsenceNotSelectableInDateTime(getValidStartDateFromInterval(d).weekday, getValidIsoDate(getValidStartDateFromInterval(d)), mainStore.user.id))
			.filter((d) => !isIsoDateInAbsences({ isoDate: getValidIsoDate(getValidStartDateFromInterval(d)), absences: selectedAbsences.value }))
			.map((d) => ({
				date: getValidIsoDate(getValidStartDateFromInterval(d)),
				userId: mainStore.user ? mainStore.user.id : 'no user found',
			}));
	});
	const isRangeSelectionOngoing = computed(() => rangeSelectionPreview.value.length);

	const selectAbsenceRange = (day: CalendarDay) => {
		if (!mainStore.user) {
			return;
		}
		if (!isAbsenceEditable.value) {
			return;
		}
		if (!rangeSelectionStart.value && isIsoDateInAbsences({ isoDate: day.id, absences: selectedAbsences.value })) {
			isDeselectModeActive.value = true;
		}
		if (!rangeSelectionStart.value) {
			rangeSelectionStart.value = {
				date: day.id,
				userId: mainStore.user.id,
			};
			return;
		}
		if (!rangeSelectionEnd.value) {
			rangeSelectionEnd.value = {
				date: day.id,
				userId: mainStore.user.id,
			};
		}
		if (isDeselectModeActive.value) {
			const dates = new Set(rangeSelectionPreview.value.map(({ date }) => date));
			selectedAbsences.value = selectedAbsences.value.filter(({ date }) => !dates.has(date));
			resetRangeSelection();
			return;
		}
		selectedAbsences.value = [...selectedAbsences.value, ...rangeSelectionPreview.value];
		resetRangeSelection();
	};

	const tooltipContentRequest = (day: CalendarDay) => {
		if (!mainStore.user || !isAbsenceEditable.value) {
			return '';
		}
		if (isAbsenceNotSelectableInCalendar(day.weekday, day.id, mainStore.user.id)) {
			return '';
		}
		if (isDeselectModeActive.value) {
			return '<div class="flex flex-col"><div class="mb-1"><span class="font-bold">ESC </span><span>to leave range deselection</span></div><div><span class="font-bold mb-1">Click </span><span>to deselect range</span></div><div>';
		}
		if (isRangeSelectionOngoing.value) {
			return '<div class="flex flex-col"><div class="mb-1"><span class="font-bold">ESC </span><span>to leave range selection</span></div><div><span class="font-bold mb-1">Click </span><span>to select range</span></div><div>';
		}
		if (isIsoDateInAbsences({ isoDate: day.id, absences: selectedAbsences.value })) {
			return '<div class="flex flex-col"><div class="mb-1"><span class="font-bold">Click </span><span>to deselect single absence</span></div><div><span class="font-bold mb-1">⇧ + Click </span><span>to start range deselection</span></div><div>';
		}
		return '<div class="flex flex-col"><div class="mb-1"><span class="font-bold">Click </span><span>to select single absence</span></div><div><span class="font-bold mb-1">⇧ + Click </span><span>to start range selection</span></div><div>';
	};

	onKeyStroke('Escape', () => {
		if (!isAbsenceEditable.value) {
			return;
		}
		if (!rangeSelectionStart.value) {
			return;
		}
		if (!rangeSelectionEnd.value) {
			return;
		}
		resetRangeSelection();
	});

	const enableAbsenceEditMode = () => {
		isAbsenceEditable.value = true;
	};

	const disableAbsenceEditMode = () => {
		selectedAbsences.value = [];
		isAbsenceEditable.value = false;
		resetRangeSelection();
	};

	return {
		isIsoDateInAbsences,
		getIntervalFromTwoDates,
		toggleAbsence,
		enableAbsenceEditMode,
		disableAbsenceEditMode,
		selectAbsenceRange,
		isWeekendDayInCalendar,
		isAbsenceNotSelectableInCalendar,
		tooltipContentRequest,
		removeAbsenceFromAbsences,
		isAbsenceNotSelectableInDateTime,
		getDiffBetweenTwoDates,
		resetRangeSelection,

		selectedAbsences,
		rangeSelectionEnd,
		rangeSelectionStart,
		rangeSelectionPreview,
		storedAbsences,
		isAbsenceEditable,
		isDeselectModeActive,
		isRangeSelectionOngoing,
	};
};
