import { computed } from 'vue';
import { useAuthentication } from '@/store/auth';
import { keepPreviousData, useQuery } from '@tanstack/vue-query';
import { getProjects } from '@/api/project';

export const useProjectsQuery = () => {
	const { isAuthenticated } = useAuthentication();

	const enabled = computed(() => isAuthenticated);

	const query = useQuery({
		queryKey: ['projects'],
		queryFn: () => getProjects(),
		enabled,
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
		refetchInterval: 3600000,
		refetchIntervalInBackground: true,
	});

	return query;
};
