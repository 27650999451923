import { defineStore } from 'pinia';
import { DateTime } from 'luxon';
import { Component, computed, ref } from 'vue';
import * as Sentry from '@sentry/vue';
import { UserModel, UserData } from '../models/User';
import { appProgressLoader } from '../plugins/AppProgressLoader';
import { getUserDataById } from '../api/user';
import LayoutDefault from '../layouts/LayoutDefault.vue';
import LayoutBlank from '../layouts/LayoutBlank.vue';

export const useMainStore = defineStore('main', () => {
	const today = ref(DateTime.now());
	const user = ref<UserModel | null>(null);
	const userData = ref<UserData | null>(null);
	const isNewVersionAvailable = ref(false);
	const routerLoadingSegmentId = ref(0);
	const successfullyLog = ref(false);
	const showIconSnow = ref(false);
	const iconSnow = ref<string>('');
	const mouseOverSlider = ref(false);
	const routerFromMonth = ref<Date>(new Date());
	const viewLayout = ref('blank');
	const hasAnyModalsOpened = ref(false);
	const showArchived = false;
	const howConfidentBeatTimestamp = ref<DateTime | null>(null);

	const viewLayoutComponent = computed((): Component => {
		if (viewLayout.value === 'blank') {
			return LayoutBlank;
		}

		return LayoutDefault;
	});

	const fetchUserData = async () => {
		if (!user.value) {
			return;
		}

		if (routerLoadingSegmentId.value) {
			appProgressLoader.finish(routerLoadingSegmentId.value);
		}

		routerLoadingSegmentId.value = appProgressLoader.start();

		userData.value = await getUserDataById(user.value.id);

		const sentryUser = {
			userId: userData.value.userId,
			username: userData.value.user,
			email: userData.value.email,
		};
		Sentry.setUser(sentryUser);

		Sentry.addBreadcrumb({
			category: 'User',
			message: 'Fetched user data',
			level: 'info',
		});

		appProgressLoader.finish(routerLoadingSegmentId.value);
	};

	const startIconSnow = async (icon: string) => {
		iconSnow.value = icon;

		showIconSnow.value = true;

		setTimeout(() => {
			showIconSnow.value = false;
			iconSnow.value = '';
		}, 20000);
	};

	const resetStore = async () => {
		user.value = null;
		userData.value = null;
	};

	return {
		today,
		user,
		userData,
		isNewVersionAvailable,
		routerLoadingSegmentId,
		successfullyLog,
		showIconSnow,
		iconSnow,
		mouseOverSlider,
		routerFromMonth,
		viewLayout,
		hasAnyModalsOpened,
		startIconSnow,
		fetchUserData,
		viewLayoutComponent,
		resetStore,
		showArchived,
		howConfidentBeatTimestamp,
	};
});
