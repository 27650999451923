import { computed, ref, watch } from 'vue';

export const useLock = () => {
	const _reason = ref<string>();

	const lock = (reason: string) => {
		_reason.value = reason;
	};

	const unlock = () => {
		_reason.value = undefined;
	};

	const isLocked = computed(() => !!_reason.value);

	const isLockReason = (reason: string) => _reason.value === reason;

	const onLockChange = (callback: (newLockState: boolean) => void) => watch(isLocked, callback);

	return {
		lock,
		unlock,
		isLocked,
		isLockReason,
		onLockChange,
	};
};
