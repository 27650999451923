import type { IconType } from '@/models/Icon';
import type { RouteLocationRaw } from 'vue-router';
import { MaybeRef } from 'vue';
import { UserRight } from '@/api/rights';
import type { ColorTheme } from './ColorTheme';
import { FeatureFlag, isFeatureFlagEnabled } from './FeatureFlag';

export type LinkItem = string | RouteLocationRaw;

export type Check = 'isAdmin' | 'isDevelopment' | 'hasQuotaListAccess' | MaybeRef<boolean> | { userRight: UserRight };

export type NavigationItemBadge = {
	text: string,
	color: ColorTheme
}

export interface NavigationItem {
	link: LinkItem,
	icon?: IconType,
	label: string,
	check?: Check
	badge?: NavigationItemBadge;
}

export const navigationItems: NavigationItem[] = [
	{
		link: { name: 'Dashboard' },
		icon: 'plus',
		label: 'Dashboard',
	},
	{
		link: { name: 'History' },
		icon: 'heart',
		label: 'Calendar',
	},
	{
		link: { name: 'PersonalReport' },
		icon: 'doc',
		label: 'Personal Report',
	},
	{
		check: 'hasQuotaListAccess',
		link: { name: 'QuotaReport', params: { quotaListId: '' } },
		icon: 'quota-monitoring',
		label: 'Kontingent Monitoring',
	},
	{
		link: { name: 'TopicOverview' },
		icon: 'list',
		label: 'Projekt Übersicht',
		badge: {
			text: 'neu',
			color: 'success',
		},
	},
	{
		check: 'isAdmin',
		link: { name: 'Admin' },
		icon: 'warning',
		label: 'Admin',
	},
	{
		link: { name: 'ManageQuotaLists' },
		icon: 'warning',
		label: 'Manage Quota Lists',
		check: {
			userRight: 'MANAGE_QUOTA_LISTS',
		},
	},
];

export const userMenuItems: NavigationItem[] = [
	{
		label: 'Log out',
		link: { name: 'Logout' },
	},
	{
		label: 'Einstellungen',
		link: { name: 'User' },
		check: isFeatureFlagEnabled(FeatureFlag.LOG_MISSING_NOTIFICATION),
	},
];

export const hasUserRightsCheck = (check: Check): check is { userRight: UserRight } => typeof check === 'object' && Object.keys(check).includes('userRight');
