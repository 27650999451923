import { MaybeRef, computed, unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { useAuthentication } from '@/store/auth';
import { UserRight, checkCurrentUserRight } from '@/api/rights';

export const useUserRightsQuery = (right: MaybeRef<UserRight>) => {
	const authStore = useAuthentication();

	const enabled = computed(() => authStore.isAuthenticated);

	const query = useQuery({
		queryKey: ['userRights', authStore.user?.id, right],
		queryFn: async () => checkCurrentUserRight(unref(right)),
		initialData: false,
		enabled,
	});

	return query;
};
