import { DateTime } from 'luxon';
import { Database } from './Supabase';
import { BeatMeta } from './Beats';

export type SupabaseSharedBeat = Database['public']['Tables']['beats_shared']['Row'];

export interface SharedBeatMeta {
	userId: string,
	clientId: string,
	projectId: string | null,
	activityId: string,
	timeIntervalInMs: number,
	note: string,
	timestamp: DateTime,
}

export interface SharedBeat extends SharedBeatMeta {
	sharedBeatId: number,
	shortCode: string,
}

export const toSupabaseData = (sharedBeatMeta: SharedBeatMeta): Omit<SupabaseSharedBeat, 'beat_shared_id' | 'short_code'> => ({
	user_id: sharedBeatMeta.userId,
	client_id: sharedBeatMeta.clientId,
	project_id: sharedBeatMeta.projectId,
	activity_id: sharedBeatMeta.activityId,
	time_interval_in_ms: sharedBeatMeta.timeIntervalInMs,
	timestamp: sharedBeatMeta.timestamp.toISO() ?? '',
	note: sharedBeatMeta.note,
});

export const createSharedBeatFromSupabase = (supabaseSharedBeat: SupabaseSharedBeat): SharedBeat => ({
	sharedBeatId: supabaseSharedBeat.beat_shared_id,
	shortCode: supabaseSharedBeat.short_code ?? '',
	userId: supabaseSharedBeat.user_id,
	clientId: supabaseSharedBeat.client_id,
	projectId: supabaseSharedBeat.project_id,
	activityId: supabaseSharedBeat.activity_id,
	timeIntervalInMs: supabaseSharedBeat.time_interval_in_ms,
	note: supabaseSharedBeat.note ?? '',
	timestamp: DateTime.fromISO(supabaseSharedBeat.timestamp),
});

export const createSharedBeatMetaFromBeatMeta = (beat: BeatMeta): SharedBeatMeta => ({
	userId: beat.userId,
	clientId: beat.clientId,
	projectId: beat.projectId ?? null,
	activityId: beat.activityId,
	timeIntervalInMs: beat.timeIntervalInMs,
	note: beat.note,
	timestamp: beat.timestamp,
});
