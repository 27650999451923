interface TimerCallback {
	forced?: boolean;
	timerId: number;
}

interface ActivityBasedTimer {
	callback: ({ forced, timerId }: TimerCallback) => void;
	interval: number;
	forcedInterval?: number;
	forcedIntervalId?: number;
	lastExecution: number;
}

export const createActivityBasedTimer = () => {
	let globalTimerId = 0;
	const timers = new Map<number, ActivityBasedTimer>();

	const maybeExecuteTimerCallback = ({ timerId, forced = false }: TimerCallback) => {
		const timer = timers.get(timerId);

		if (timer === undefined) {
			return;
		}

		const {
			callback,
			interval,
			forcedInterval,
			forcedIntervalId,
			lastExecution,
		} = timer;

		const intervalToCheckFor = forced === true && forcedInterval
			? forcedInterval
			: interval;
		const now = Date.now();

		if (now - lastExecution < intervalToCheckFor) {
			return;
		}

		const newTimer = {
			...timer,
			lastExecution: now,
		};

		if (forcedIntervalId !== undefined) {
			window.clearInterval(forcedIntervalId);
			newTimer.forcedIntervalId = window.setInterval(() => {
				maybeExecuteTimerCallback({ timerId, forced: true });
			}, forcedInterval);
		}

		timers.set(timerId, newTimer);
		callback({ forced, timerId });
	};

	const setInterval = ({ callback, interval, forcedInterval }: Omit<ActivityBasedTimer, 'lastExecution'>) => {
		const timerId = globalTimerId;

		if (typeof callback !== 'function' || typeof interval !== 'number') {
			return undefined;
		}

		const timer: ActivityBasedTimer = {
			callback,
			interval,
			lastExecution: Date.now(),
		};

		if (forcedInterval !== undefined) {
			timer.forcedInterval = forcedInterval;
			timer.forcedIntervalId = window.setInterval(() => {
				maybeExecuteTimerCallback({ timerId, forced: true });
			}, forcedInterval);
		}

		timers.set(timerId, timer);
		globalTimerId += 1;
		return timerId;
	};

	const clearInterval = (timerId: number) => {
		const timer = timers.get(timerId);

		if (timer === undefined) {
			return;
		}

		const { forcedIntervalId } = timer;

		if (forcedIntervalId !== undefined) {
			window.clearInterval(forcedIntervalId);
		}

		timers.delete(timerId);
	};

	const runTimersCheck = () => {
		timers.forEach((_timer, timerId) => {
			maybeExecuteTimerCallback({ timerId });
		});
	};

	return {
		setInterval,
		clearInterval,
		runTimersCheck,
	};
};
