import { supabase } from '@/lib/supabase';
import { ConfidenceMeta } from '@/models/Confident';

export const createConfidenceEntry = async (confidenceMeta: ConfidenceMeta) => supabase
	.schema('tracking')
	.from('how_confident')
	.insert({
		activity_id: confidenceMeta.activityId,
		client_id: confidenceMeta.clientId,
		confidence: confidenceMeta.confidence,
		project_id: confidenceMeta.projectId,
		beat_duration: confidenceMeta.beatDuration,
		timing: confidenceMeta.timing,
	});
