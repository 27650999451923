<template>
	<BoardWrapper>
		<div class="flex mb-24 justify-between">
			<div class="flex items-start font-semibold text-[20px] phablet:text-[24px]">
				<div class="w-[20px] phablet:w-12 mt-2.5 phablet:mt-3 bg-primary-100 h-1 rounded-full mr-4" />
				<div>
					Client-/Projectmanagement
				</div>
			</div>
			<div class="flex items-center">
				<RouterLink :to="{ name: 'CreateClient' }">
					<AppButton
						size="sm"
						class="py-3"
					>
						Add Client
					</AppButton>
				</RouterLink>
				<RouterLink :to="{ name: 'CreateActivity' }">
					<AppButton
						size="sm"
						color="outline"
						class="ml-3 py-3"
					>
						Add Activity
					</AppButton>
				</RouterLink>
			</div>
		</div>
		<div class="flex w-full space-x-8">
			<div class="w-full fullhd:pl-16">
				<div class="space-y-4">
					<div class="mb-14">
						<h2>
							Search Clients & Projects more efficiently
						</h2>
						<AppSearch
							v-model="searchValue"
							class="mb-4"
						/>
						<AppCheckbox
							:model-value="mainStore.showArchived"
							@update:model-value="mainStore.showArchived = !mainStore.showArchived"
						>
							Show archived
						</AppCheckbox>
					</div>
					<div
						v-for="(letter) in Object.keys(filteredClients)"
						:key="letter"
					>
						<div
							v-if="(filteredClients[letter].filter(client => client.client.archived === false).length || mainStore.showArchived)"
							class="text-2xl font-semibold"
						>
							{{ letter }}
						</div>
						<ClientListItem
							v-for="client in filteredClients[letter]"
							:key="client.client.id"
							class="p-2 pl-0 pr-0 z-0 sticky"
							:class="{ hidden: client.client.archived && !mainStore.showArchived }"
							:client="client.client"
							:is-active="route.params.clientId === client.client.id"
							:search-value="searchValue"
							@click="showClientPanel(client.client)"
							@add-project="onAddProjectForClient(client.client)"
						>
							<div
								v-if="client.client.projects"
							>
								<ProjectListItem
									v-for="project in client.client.projects"
									:key="project.id"
									:client="client.client"
									:project="project"
									:search-value="searchValue"
									:is-active="route.params.projectId === project.id"
									@click="showProjectPanel(project)"
								/>
							</div>
						</ClientListItem>
					</div>
				</div>
			</div>
			<div class="w-[460px] fullhd:w-[470px] flex-shrink-0">
				<div class="space-y-4 sticky top-10 h-[calc(100vh-80px)] overflow-auto">
					<RouterView />
				</div>
			</div>
		</div>
	</BoardWrapper>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useMainStore } from '@/store/main';
import { useClientsStore } from '@/store/clients';
import { useProjectsStore } from '@/store/projects';
import { useRouter, useRoute } from 'vue-router';
import AppButton from '@/components/AppButton.vue';
import BoardWrapper from '@/components/BoardWrapper.vue';
import ClientListItem from '@/components/ClientListItem.vue';
import ProjectListItem from '@/components/ProjectListItem.vue';
import AppCheckbox from '@/components/AppCheckbox.vue';
import AppSearch from '@/components/AppSearch.vue';
import { Client } from '@/models/Client';
import { Project } from '@/models/Project';
import { groupBy } from '@/../src/utilities/Helpers';
import { useUnsavedChanges } from '@/composables/useUnsavedChanges';
import { useRouteLeaveConfirmation } from '@/composables/useRouteLeaveConfirmation';

const mainStore = useMainStore();
const clientsStore = useClientsStore();
const projectsStore = useProjectsStore();
const searchValue = ref('');

const route = useRoute();

const getClientProjects = (clientId: string) => projectsStore.allProjects.filter((project) => project.clientId === clientId);

interface ClientWithProjects extends Client {
	projects: Project[],
}

interface ListedClients {
	letter: string,
	client: ClientWithProjects
}

const clientData = computed<ClientWithProjects[]>(() => clientsStore.allClients.reduce((prevValue: ClientWithProjects[], currentValue) => {
	if (!searchValue.value) {
		const clientWithProjects: ClientWithProjects = {
			...currentValue,
			projects: getClientProjects(currentValue.id),
		};
		return [...prevValue, clientWithProjects];
	}

	if (currentValue.name.toLowerCase().includes(searchValue.value.toLowerCase())) {
		const clientWithProjects: ClientWithProjects = {
			...currentValue,
			projects: getClientProjects(currentValue.id),
		};
		return [...prevValue, clientWithProjects];
	}

	const filteredProjects = getClientProjects(currentValue.id).filter((project) => project.name.toLowerCase().includes(searchValue.value.toLowerCase()));

	if (filteredProjects.length > 0) {
		const clientWithProjects: ClientWithProjects = {
			...currentValue,
			projects: filteredProjects,
		};
		return [...prevValue, clientWithProjects];
	}

	return [...prevValue];
}, []));

const filteredClients = computed(() => {
	const orderedClients: ListedClients[] = clientData.value.map((client) => {
		const letter = client.name.toUpperCase().substring(0, 1);
		return {
			letter,
			client,
		};
	});
	orderedClients.sort((a, b) => a.client.name.localeCompare(b.client.name));
	return groupBy(orderedClients, 'letter');
});

const router = useRouter();

const showClientPanel = (client: Client) => {
	const hasClientPanelAlreadyOpen = router.currentRoute.value.matched.some((_route) => _route.name === 'Client');
	const hasProjectQuotasAlreadyOpen = router.currentRoute.value.matched.some((_route) => _route.name === 'ProjectQuotas');

	// Make sure the same child routes is used while navigating
	if (hasClientPanelAlreadyOpen) {
		router.push({ ...router.currentRoute.value, params: { clientId: client?.id } });
	} else if (hasProjectQuotasAlreadyOpen) {
		router.push({ ...router.currentRoute.value, name: 'ClientQuotas', params: { clientId: client?.id } });
	} else {
		router.push({ name: 'Client', params: { clientId: client?.id } });
	}
};

const showProjectPanel = (project: Project) => {
	const hasProjectPanelAlreadyOpen = router.currentRoute.value.matched.some((_route) => _route.name === 'Project');
	const hasClientQuotasAlreadyOpen = router.currentRoute.value.matched.some((_route) => _route.name === 'ClientQuotas');

	// Make sure the same child routes is used while navigating
	if (hasProjectPanelAlreadyOpen) {
		router.push({ ...router.currentRoute.value, params: { projectId: project?.id } });
	} else if (hasClientQuotasAlreadyOpen) {
		router.push({ ...router.currentRoute.value, name: 'ProjectQuotas', params: { projectId: project?.id } });
	} else {
		router.push({ name: 'Project', params: { projectId: project?.id } });
	}
};

const onAddProjectForClient = (client: Client) => {
	router.push({ name: 'CreateClientProject', params: { clientId: client.id } });
};

const { hasAnyUnsavedChanges } = useUnsavedChanges();

useRouteLeaveConfirmation({
	modal: {
		title: 'Warning: There are some unsaved changes',
		description: 'Are you sure you want to exit without saving them?',
		confirmLabel: 'Exit without saving',
		cancelLabel: 'Go back',
	},
	showModal: hasAnyUnsavedChanges,
	showModalOnRouteUpdate: true,
});
</script>
