import { computed } from 'vue';
import { keepPreviousData, useQuery } from '@tanstack/vue-query';
import { useAuthentication } from '@/store/auth';
import { getUserNotificationSettings, getUserSettings } from '@/api/user';
import { UserSettings } from '@/models/UserSettings';
import { getSortedTimeStrings } from '@/utilities/Helpers';
import { FeatureFlag, isFeatureFlagEnabled } from '@/models/FeatureFlag';

export const useUserSettingsQuery = () => {
	const authStore = useAuthentication();

	const enabled = computed(() => authStore.isAuthenticated && isFeatureFlagEnabled(FeatureFlag.LOG_MISSING_NOTIFICATION));

	const query = useQuery<UserSettings>({
		queryKey: ['userSettings', authStore.user?.id],
		queryFn: async () => {
			const settings = await getUserSettings();
			const notificationSettings = await getUserNotificationSettings();

			return {
				logNotificationsEnabled: settings.data?.log_notifications_enabled ?? false,
				logNotificationVoice: settings.data?.voice ?? 'neutral',
				logNotifications: getSortedTimeStrings(notificationSettings.map((notification) => notification.time))
					.map((time) => ({ time })),
				activityOrdering: settings.data?.activity_ordering ?? 'alpha',
			};
		},
		enabled,
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: true,
	});

	return query;
};
