import { DateTime } from 'luxon';
import { Database } from './Supabase';
import { SupabaseQuotaInfo } from './QuotaInfo';

export interface QuotaMeta {
	quotaInfoId: string;
	monthlyHours: number;
	description?: string;
	isExtra: boolean;
	from: DateTime;
	till?: DateTime;
	userId: string;
}

export interface Quota extends QuotaMeta {
	quotaId: string;
}

export type QuotaSubmitData = Pick<QuotaMeta, 'description' | 'from' | 'till' | 'isExtra' | 'monthlyHours'> & Partial<Pick<QuotaMeta, 'quotaInfoId'>>

export type SupabaseQuota = Database['public']['Tables']['quotas']['Row'];

export const createQuotaFromSupabase = (quota: SupabaseQuota, quotaInfo: SupabaseQuotaInfo): Quota => ({
	quotaId: quota.quota_id,
	from: quota.from ? DateTime.fromISO(quota.from) : DateTime.fromISO(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`),
	till: quota.till ? DateTime.fromISO(quota.till) : undefined,
	isExtra: quotaInfo.is_extra ?? false,
	monthlyHours: quota.monthly_hours,
	description: quota.description ?? undefined,
	userId: quota.user_id,
	quotaInfoId: quota.quota_info_id,
});

export const toSupabaseData = (quotaMeta: QuotaMeta): Omit<SupabaseQuota, 'quota_id'> => ({
	quota_info_id: quotaMeta.quotaInfoId,
	description: quotaMeta.description ?? null,
	monthly_hours: quotaMeta.monthlyHours,
	from: quotaMeta.from.toISODate() ?? '',
	till: quotaMeta.till?.toISODate() ?? null,
	user_id: quotaMeta.userId,
});

export const startsAfterSpecificDate = (date: DateTime, quota: Quota) => {
	const startOfDate = date.startOf('day');
	const quotaStart = quota.from.startOf('day');

	return startOfDate < quotaStart;
};

export const startsBeforeSpecificDate = (date: DateTime, quota: Quota) => {
	const startOfDate = date.startOf('day');
	const quotaStart = quota.from.startOf('day');

	return startOfDate > quotaStart;
};

export const startsOnSameDate = (date: DateTime, quota: Quota) => {
	const startOfDate = date.startOf('day');
	const quotaStart = quota.from.startOf('day');

	return startOfDate.equals(quotaStart);
};

export const endsOnSameDate = (date: DateTime, quota: Quota) => {
	const startOfDate = date.startOf('day');
	const quotaEnd = quota.till?.startOf('day');

	if (!quotaEnd) {
		return false;
	}

	return startOfDate.equals(quotaEnd);
};

export const isActiveDuringSpecificDate = (date: DateTime, quota: Quota) => {
	const startOfDate = date.startOf('day');
	const quotaEnd = quota.till?.startOf('day');

	if (startsOnSameDate(date, quota) || endsOnSameDate(date, quota)) {
		return true;
	}

	if (!startsBeforeSpecificDate(date, quota)) {
		return false;
	}

	return quotaEnd ? quotaEnd > startOfDate : true;
};

export const isActiveDuringSpecificDateRange = (startDate: DateTime, endDate: DateTime, quota: Quota) => {
	const startOfDate = startDate.startOf('day');
	const endOfDate = endDate.startOf('day');

	return isActiveDuringSpecificDate(startOfDate, quota) || isActiveDuringSpecificDate(endOfDate, quota);
};

export const getRemainingQuotaHoursForSpecificDate = (clientQuotas: Quota[], projectQuotas: Quota[], date: DateTime): number | null => {
	const activeClientQuota = clientQuotas.find((quota) => isActiveDuringSpecificDate(date, quota));

	if (!activeClientQuota) {
		return null;
	}

	const activeProjectQuotas = projectQuotas.filter((quota) => isActiveDuringSpecificDate(date, quota));

	const totalActiveProjectQuotaHours = activeProjectQuotas.reduce((prev, curr) => prev + curr.monthlyHours, 0);

	return activeClientQuota.monthlyHours - totalActiveProjectQuotaHours;
};

export const getActiveQuotaDuringTimeRange = (
	range: { from: DateTime, till?: DateTime },
	quotas: Quota[],
): Quota | null => (quotas
	.find((q) => (range.till
		? isActiveDuringSpecificDateRange(range.from, range.till, q)
		: isActiveDuringSpecificDate(range.from, q))))
        ?? null;

export const removeQuotaFromArray = (quota: Quota, quotas: Quota[]): Quota[] => quotas.filter((q) => q.quotaId !== quota.quotaId);
