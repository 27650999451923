import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { UserRight } from '@/api/rights';
import { useUserRightsQuery } from '@/composables/queries/useUserRightsQuery';

/**
 * This is just a quick solution. We only use this for checking during Navigation
 * resolution if an item can be displayed based on the current users rights. If
 * we would scale this store to use more user rights we would need to make a query
 * for every single right. That might not be efficient. Therefore we could think
 * about either a supabase function providing us all rights. Or maybe a frontend
 * function for "requesting" rights and storing them in this store. In any case,
 * feel free to refactor this part.
 */

export const useUserRightsStore = defineStore('userRights', () => {
	const userRights = ref<Partial<Record<UserRight, boolean>>>({});

	const hasUserRight = (userRight: UserRight) => userRights.value[userRight] ?? false;

	const { data: manageQuotaListsRight } = useUserRightsQuery('MANAGE_QUOTA_LISTS');

	watch(manageQuotaListsRight, (hasRight) => {
		userRights.value = { ...userRights.value, MANAGE_QUOTA_LISTS: hasRight };
	});

	return {
		userRights,
		hasUserRight,
	};
});
