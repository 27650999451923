export type TableRowData<
	T extends Record<PropertyKey, any> = Record<PropertyKey, any>
> = T;

export interface TableAction<T extends TableRowData = TableRowData> {
	label: string;
	onClick: (rowData: T) => void | Promise<void>;
}

export interface TableSelectedAction {
	label: string;
	icon: string;
	onClick: (selectedTrackBy: (string | number)[]) => void;
}

export type TableSortOrder = { sortField: string; direction: 'asc' | 'desc' };

export const addRowToSelection = (
	rows: TableRowData[],
	row: TableRowData,
): TableRowData[] => [...rows, row];

export const removeRowFromSelection = <T extends TableRowData = TableRowData>(
	rows: T[],
	row: T,
	trackBy: keyof T,
): T[] => {
	const newSelectedRows = rows.filter((r) => r[trackBy] !== row[trackBy]);
	return newSelectedRows;
};
