<template>
	<form
		class="group relative border-none dashed-border rounded-lg flex flex-col justify-between text-center p-6 z-0"
		@submit.prevent="submitNewBeat()"
	>
		<div
			class="absolute z-10 top-0 left-1/2 tranform -translate-y-1/2 -translate-x-1/2"
		>
			<TodaysLogCardMenu
				:items="menuActions"
				:is-saving="beatCreationTriggered"
			/>
		</div>
		<div>
			<FirstLevelSelectInput
				id="FirstLevelSelectInput"
				v-model="selectedFirstLevelOption"
				:project-options="projectsStore.projects"
				:client-options="clientsStore.clients"
				placeholder="Which client/project?"
				class="text-white text-opacity-100"
				appearance="small"
			/>
			<ActivitySelectInput
				id="ActivitySelectInput"
				v-model="selectedActivity"
				value-prop="id"
				label="name"
				placeholder="What did you do?"
				class="text-white text-opacity-100"
				:options="activitiesStore.activities"
				appearance="smallRounded"
			/>
		</div>
		<div
			class="md:mt-0 md:mb-0 mb-7 mt-8"
		>
			<div class="text-sm opacity-50">
				{{ beatTrackingtime }}
			</div>
			<TimeTrackInput
				v-model="beat.timeIntervalInMs"
				appearance="sm"
			/>
		</div>
		<div
			class="flex items-center space-x-4 text-white text-opacity-50 text-center mx-auto text-[14px] tracking-wider"
		>
			<div class="relative">
				<SatisfactionEmotes
					ref="satisfactionRef"
					v-model="satisfactionValue"
					:is-satisfaction-happy="beat.satisfaction === 1"
					:is-satisfaction-sad="beat.satisfaction === 0"
					is-editable
					@select="onSatisfactionSelect"
				/>
			</div>
			<div class="relative">
				<BeatInlineNote
					ref="noteRef"
					v-model="beat.note"
					is-editable
					in-card
				/>
			</div>
		</div>
		<div
			class="absolute bottom-[-12px] left-0 right-0"
		>
			<div
				class="w-max px-1 bg-primary-600 mx-auto text-primary-300 text-[14px]"
			>
				Add a new log
			</div>
		</div>
	</form>
</template>

<script setup lang="ts">
import {
	PropType,
	computed, onMounted, reactive, ref, watchEffect,
} from 'vue';
import { ResultAsync } from 'neverthrow';
import hotkeys from 'hotkeys-js';
import { DateTime } from 'luxon';
import { LogCardMenuItem } from '@/models/LogCard';
import { useBeatsStore } from '@/store/beats';
import { useToast } from 'vue-toastification';
import { Beat, createEmptyBeatMeta, isBeatValid } from '../models/Beats';
import SatisfactionEmotes from './SatisfactionEmotes.vue';
import FirstLevelSelectInput from './FirstLevelSelectInput.vue';
import ActivitySelectInput from './ActivitySelectInput.vue';
import TimeTrackInput from './TimeTrackInput.vue';
import BeatInlineNote from './BeatInlineNote.vue';
import TodaysLogCardMenu from './TodaysLogCardMenu.vue';
import { dateTimeToLocaleTimeFormat } from '../shared/times';
import { useMainStore } from '../store/main';
import CheckIcon from '../assets/check-icon.svg?component';
import TimesIcon from '../assets/times-icon.svg?component';
import { useActivitiesStore } from '../store/activities';
import { useProjectsStore } from '../store/projects';
import { useClientsStore } from '../store/clients';
import { Client } from '../models/Client';
import { Project } from '../models/Project';
import { Activity } from '../models/Activity';
import { FirstLevelOption, fromClientAndMaybeProject } from '../models/FirstLevelOption';

const props = defineProps({
	day: {
		type: Object as PropType<DateTime>,
		required: true,
	},
});
const mainStore = useMainStore();
const beatsStore = useBeatsStore();
const activitiesStore = useActivitiesStore();
const projectsStore = useProjectsStore();
const clientsStore = useClientsStore();
const noteRef = ref<typeof BeatInlineNote>();
const satisfactionValue = ref<number | null>(null);
const selectedClient = ref<Client | null>(null);
const selectedActivity = ref<Activity | null>(null);
const selectedProject = ref<Project | null>(null);

const selectedFirstLevelOption = computed<FirstLevelOption | null>({
	get() {
		if (!selectedClient.value) {
			return null;
		}

		return fromClientAndMaybeProject(selectedClient.value, selectedProject.value);
	},
	set(option) {
		selectedClient.value = option ? option.client : null;
		selectedProject.value = option ? option.project : null;
	},
});

const dayWithCurrentTime = computed(() => DateTime.now().set({ day: props.day.day, month: props.day.month, year: props.day.year }));

const beat = reactive(createEmptyBeatMeta(mainStore.user!.id, dayWithCurrentTime.value));
const beatTrackingtime = computed(() => dateTimeToLocaleTimeFormat(dayWithCurrentTime.value));

watchEffect(() => {
	beat.activityId = selectedActivity.value?.id ?? undefined;
	beat.clientId = selectedFirstLevelOption.value?.client.id ?? undefined;
	beat.projectId = selectedFirstLevelOption.value?.project?.id ?? undefined;
});

watchEffect(() => {
	beat.satisfaction = satisfactionValue.value === beat.satisfaction ? null : satisfactionValue.value;
});

const resetForm = () => {
	Object.assign(beat, createEmptyBeatMeta(mainStore.user!.id, dayWithCurrentTime.value));
	satisfactionValue.value = null;
};

const beatCreationTriggered = ref(false);

const isFormValid = computed(() => isBeatValid(beat));

const emit = defineEmits<{
	(e: 'beatCreated', beat: Beat): void;
	(e: 'cancel'): void;
}>();

const toast = useToast();

const submitNewBeat = async () => {
	beatCreationTriggered.value = true;
	if (!isFormValid.value) {
		beatCreationTriggered.value = false;
		toast.warning('Sorry, your log isn\'t valid.');
		return;
	}

	try {
		beat.timestamp = dayWithCurrentTime.value;

		if (!isBeatValid(beat)) {
			throw new Error('Beat is not valid');
		}

		const result = await beatsStore.createBeat(beat);

		if (result.isErr()) {
			return;
		}

		emit('beatCreated', result.value);
		resetForm();

		toast.success('You\'ve successfully logged time!');
	} catch (error) {
		toast.error('Oops, something went wrong.');
	} finally {
		beatCreationTriggered.value = false;
	}
};

const saveChanges = async () => {
	if (beatCreationTriggered.value) {
		return Promise.reject();
	}
	await submitNewBeat();
	return Promise.resolve();
};

const cancelAddNewBeat = () => {
	resetForm();
	emit('cancel');
	return Promise.resolve();
};

const menuActions: LogCardMenuItem[] = [
	{
		id: 'Confirm',
		icon: CheckIcon,
		needsConfirmation: false,
		tippyContent: 'Log time',
		onSubmit() {
			return ResultAsync.fromPromise(saveChanges(), () => new Error('Multi submit error'));
		},
	},
	{
		id: 'Cancel',
		icon: TimesIcon,
		needsConfirmation: false,
		tippyContent: 'Cancel',
		onSubmit() {
			return ResultAsync.fromSafePromise(cancelAddNewBeat());
		},
	},
];

const onSatisfactionSelect = () => {
	noteRef.value?.focus();
};

onMounted(() => {
	hotkeys('ESC', () => {
		cancelAddNewBeat();
	});
});
</script>

<style lang="scss" scoped>
@keyframes dash {
	to {
		background-position: 100% 0%, 0% 100%, 0% 0%, 100% 100%;
	}
}

.dashed-border {
	height: 100%;
	width: 100%;
	background: linear-gradient(90deg, #2a2656 50%, transparent 50%),
				linear-gradient(90deg, #2a2656 50%, transparent 50%),
				linear-gradient(0deg, #2a2656 50%, transparent 50%),
				linear-gradient(0deg, #2a2656 50%, transparent 50%);
	background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
	background-size: 10px 2px, 10px 2px, 2px 10px, 2px 10px;
	background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0px;
	animation: dash 8s linear infinite;
}
</style>
