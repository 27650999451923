import {
	toRefs,
	watch,
	nextTick,
	onBeforeUnmount,
	reactive,
	type Ref,
	onMounted,
} from 'vue';
import {
	createPopper,
	type Instance,
	type Options,
	type Placement,
	preventOverflow,
	type VirtualElement,
} from '@popperjs/core';
import { toInt } from '../utilities/Helpers';

interface PopperState {
	isOpen: boolean;
	popperInstance: Instance | null;
}

interface PopperOptions {
	offsetDistance: Ref<string>;
	offsetSkid: Ref<string>;
	placement: Ref<Placement>;
	popperNode: Ref<HTMLElement | undefined>;
	flipNode?: Ref<HTMLElement | null>;
	triggerNode: Ref<HTMLElement | VirtualElement | undefined>;
	options?: Partial<Options>;
}

export const usePopper = ({
	offsetDistance,
	offsetSkid,
	placement,
	popperNode,
	triggerNode,
	flipNode,
	options,
}: PopperOptions) => {
	const state = reactive<PopperState>({
		isOpen: false,
		popperInstance: null,
	});

	const close = () => {
		if (!state.isOpen) {
			return;
		}

		state.isOpen = false;
	};

	const open = () => {
		if (state.isOpen) {
			return;
		}

		state.isOpen = true;
	};

	const toggle = () => {
		if (state.isOpen) {
			return close();
		}

		return open();
	};

	const initializePopper = async () => {
		if (!triggerNode.value || !popperNode.value) {
			return;
		}

		await nextTick();

		state.popperInstance = createPopper(
			triggerNode.value,
			popperNode.value,
			{
				placement: placement.value,
				modifiers: [
					...(options?.modifiers || []),
					preventOverflow,
					{
						name: 'offset',
						options: {
							offset: [
								toInt(offsetSkid.value),
								toInt(offsetDistance.value),
							],
						},
					},
					...(flipNode && flipNode.value
						? [
							{
								name: 'flip',
								options: {
									boundary: flipNode.value,
								},
							},
						]
						: []),
				],
			},
		);

		// Update its position
		state.popperInstance.forceUpdate();
	};

	// When isOpen or placement change
	watch([() => state.isOpen, placement], async ([isOpen]) => {
		if (isOpen) {
			await initializePopper();
		}
	});

	onMounted(() => {
		initializePopper();
	});

	onBeforeUnmount(() => {
		state.popperInstance?.destroy();
	});

	return {
		...toRefs(state),
		open,
		close,
		toggle,
	};
};
