<template>
	<div class="group relative border-2 grid grid-cols-3 p-6 z-0 border-primary-300 h-full border-solid rounded-2xl">
		<div>
			<p class="font-bold text-lg">
				{{ user?.name }}
			</p>
			<p>{{ firstLevelOption?.client.name }} | {{ firstLevelOption?.project?.name }} | {{ activity?.name }}</p>
		</div>
		<div>
			<div class="text-sm opacity-50">
				{{ beatTrackingtime }}
			</div>
			<div
				class="text-4xl font-bold opacity-75"
			>
				{{ getTimeIntervalInHm }}
			</div>
		</div>
		<div
			class="flex items-center justify-end self-end text-white text-opacity-50 text-[14px] tracking-wider space-x-4"
		>
			<p>{{ modifiedBeat.note }}</p>
			<div class="relative">
				<SatisfactionEmotes
					ref="satisfactionRef"
					v-model="satisfactionValue"
					:is-satisfaction-happy="beat.satisfaction === 1"
					:is-satisfaction-sad="beat.satisfaction === 0"
					:is-editable="isCardEditable && onBeatEditing"
				/>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed, PropType, ref } from 'vue';
import {
	Beat, getActivityFromBeat, getProjectFromBeat, getClientFromBeat,
} from '../models/Beats';
import SatisfactionEmotes from './SatisfactionEmotes.vue';
import { msToDisplayTime, dateTimeToLocaleTimeFormat } from '../shared/times';
import { useMainStore } from '../store/main';
import { useActivitiesStore } from '../store/activities';
import { useProjectsStore } from '../store/projects';
import { useClientsStore } from '../store/clients';
import { FirstLevelOption, fromClientAndMaybeProject } from '../models/FirstLevelOption';
import { Activity } from '../models/Activity';

const props = defineProps({
	beat: {
		type: Object as PropType<Beat>,
		required: true,
	},
	user: {
		type: Object as PropType<{ name: string } | null>,
		default: null,
	},
	isDark: {
		type: Boolean,
		default: false,
	},
});

const mainStore = useMainStore();
const activitiesStore = useActivitiesStore();
const projectsStore = useProjectsStore();
const clientsStore = useClientsStore();

const getTimeIntervalInHm = computed(() => msToDisplayTime(props.beat.timeIntervalInMs));
const beatTrackingtime = computed(() => dateTimeToLocaleTimeFormat(props.beat.timestamp));
const onBeatEditing = ref(false);

const modifiedBeat = ref<Beat>({ ...props.beat });

const client = computed(() => getClientFromBeat(clientsStore.allClients, modifiedBeat.value));
const project = computed(() => getProjectFromBeat(projectsStore.allProjects, modifiedBeat.value));

const activity = computed<Activity | null>(() => getActivityFromBeat(activitiesStore.activities, modifiedBeat.value));

const firstLevelOption = computed<FirstLevelOption | null>(() => {
	if (!client.value) {
		return null;
	}
	return fromClientAndMaybeProject(client.value, project.value);
});

const isCardEditable = computed(() => {
	if (props.beat.timestamp > mainStore.today.startOf('day').minus({ days: 7 })) {
		return true;
	}
	return false;
});

const satisfactionValue = computed<number | null>({
	get() {
		return modifiedBeat.value.satisfaction;
	},
	set(value) {
		modifiedBeat.value.satisfaction = value;
	},
});
</script>

<style lang="scss" scoped>
@keyframes dash {
    to {
        background-position: 100% 0%, 0% 100%, 0% 0%, 100% 100%;
    }
}

.dashed-border {
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #2a2656 50%, transparent 50%),
                linear-gradient(90deg, #2a2656 50%, transparent 50%),
                linear-gradient(0deg, #2a2656 50%, transparent 50%),
                linear-gradient(0deg, #2a2656 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 10px 2px, 10px 2px, 2px 10px, 2px 10px;
    background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0px;
    animation: dash 8s linear infinite;
}
</style>
