<template>
	<AppTable
		:table="table"
		class="w-full"
	/>
</template>

<script lang="ts" setup>
import {
	createColumnHelper,
	getCoreRowModel, getExpandedRowModel, getSortedRowModel, useVueTable,
} from '@tanstack/vue-table';
import { PropType } from 'vue';
import { msToDisplayTime } from '@/shared/times';
import { BeatTableEntry } from '@/models/BeatTableEntry';
import AppTable from './AppTable.vue';

const props = defineProps({
	data: {
		type: Array as PropType<BeatTableEntry[]>,
		default: () => ([]),
	},
});

const columnHelper = createColumnHelper<BeatTableEntry>();

const columns = [
	columnHelper.accessor('name', {
		header: 'Topic',
		size: 300,
	}),
	columnHelper.accessor('logCount', {
		header: 'Logs',
		size: 0,
		cell: (context) => context.getValue(),
	}),
	columnHelper.accessor('totalDurationInMs', {
		header: 'Dauer',
		size: 0,
		cell: (context) => msToDisplayTime(context.getValue()),
	}),
];

const table = useVueTable({
	columns,
	get data() {
		return props.data;
	},
	getCoreRowModel: getCoreRowModel(),
	getExpandedRowModel: getExpandedRowModel(),
	getSortedRowModel: getSortedRowModel(),
});

defineExpose({ table });
</script>
