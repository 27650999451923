<template>
	<VueFinalModal
		:modal-id="modalId"
		class="fixed h-full w-full"
		overlay-class="backdrop-filter backdrop-brightness-20 backdrop-blur-[10px] !bg-primary-300 !bg-opacity-50 h-full w-full"
		content-class="absolute mt-[206px] bg-primary-300 w-[560px] rounded-lg p-8 shadow-modal mx-4 top-0 mx-auto left-0 right-0"
		:content-transition="{
			enterFromClass: 'mobile:opacity-0 translate-y-full mobile:translate-y-0 mobile:scale-90',
			enterActiveClass: 'transition-all transform-gpu ease-smooth mobile:ease-bounce duration-300',
			enterToClass: 'translate-y-0',
			leaveFromClass: 'translate-y-0',
			leaveActiveClass: 'transition-all transform-gpu ease-smooth mobile:ease-bounce duration-200',
			leaveToClass: 'mobile:opacity-0 translate-y-full mobile:translate-y-0 mobile:scale-90',
		}"
		:overlay-transition="{
			enterFromClass: 'mobile:opacity-0',
			enterActiveClass: 'transition-all transform-gpu ease-smooth mobile:ease-bounce duration-300',
			enterToClass: 'opacity-100',
			leaveFromClass: 'opacity-100',
			leaveActiveClass: 'transition-all transform-gpu ease-smooth mobile:ease-bounce duration-200',
			leaveToClass: 'mobile:opacity-0',
		}"
		:esc-to-close="true"
		:teleport-to="false"
		@before-open="onBeforeOpen"
		@before-close="onBeforeClose"
		@opened="onOpened"
		@closed="onClosed"
		@update:model-value="$emit('update:modelValue', $event)"
	>
		<div class="relative h-full">
			<div class="flex items-start mobile:items-center space-x-2 mt-4 mobile:mt-0">
				<div v-show="hasIcon">
					<IconWarning class="text-error mb-5 mobile:mb-1 min-w-5" />
				</div>
				<div class="text-white text-opacity-75 font-bold text-base mobile:text-xl">
					{{ title }}
				</div>
			</div>
			<slot>
				<div class="text-white text-opacity-50 text-md mobile:text-base font-medium ml-7 mobile:mt-2 tracking-wide">
					{{ description }}
				</div>
				<div class="mobile:flex items-center justify-center mobile:space-x-[32px] mt-8 mx-auto w-full">
					<AppButton
						class="max-h-[44px] text-white text-opacity-50"
						width="full"
						color="transparent"
						@click="onConfirm"
					>
						{{ confirmLabel }}
					</AppButton>
					<AppButton
						class="max-h-[44px]"
						width="full"
						@click="emit('cancel')"
					>
						{{ cancelLabel }}
					</AppButton>
				</div>
			</slot>
			<TimesIcon
				class="absolute -top-8 -right-4 mobile:-top-16 mobile:-right-8 fill-current cursor-pointer"
				@click="emit('cancel')"
			/>
		</div>
	</VueFinalModal>
</template>

<script setup lang="ts">
import { useVfm, VueFinalModal } from 'vue-final-modal';
import AppButton from './AppButton.vue';
import TimesIcon from '../assets/times-icon.svg?component';
import IconWarning from '../assets/icon-warning.svg?component';
import { useMainStore } from '../store/main';

defineProps({
	hasIcon: {
		type: Boolean,
		default: true,
	},
	title: {
		type: String,
		required: true,
	},
	description: {
		type: String,
		required: true,
	},
	confirmLabel: {
		type: String,
		required: true,
	},
	cancelLabel: {
		type: String,
		required: true,
	},
	modalId: {
		type: String,
		required: true,
	},
});

const emit = defineEmits(['confirm', 'cancel', 'update:modelValue', 'update:loading', 'before-open', 'opened', 'before-close', 'opened', 'closed']);

const mainStore = useMainStore();

const close = () => {
	emit('update:modelValue', false);
};

const onBeforeOpen = () => {
	emit('before-open');
	mainStore.hasAnyModalsOpened = true;
};

const onBeforeClose = () => {
	emit('before-close');
};

const onOpened = () => {
	emit('opened');
};

const { modals } = useVfm();

const onClosed = () => {
	emit('closed');

	if (modals.length === 0) {
		mainStore.hasAnyModalsOpened = false;
	}

	emit('update:loading', false);
};

const onConfirm = () => {
	emit('update:loading', true);
	emit('confirm', close);
};
</script>
