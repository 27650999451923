import { supabase } from '@/lib/supabase';
import { ReasonWithDescription } from '@/models/Reason';
import * as yup from 'yup';

export const getTopReasons = async (userId: string): Promise<ReasonWithDescription[]> => {
	const { data } = await supabase
		.from('top_client_chips')
		.select('*')
		.eq('user_id', userId)
		.limit(10)
		.order('total_time', { ascending: false });

	const topReasonsSchema = yup.object({
		activity_id: yup.string().defined(),
		activity_name: yup.string().defined(),
		client_id: yup.string().defined(),
		client_name: yup.string().defined(),
		project_id: yup.string().defined().nullable(),
		project_name: yup.string().defined().nullable(),
		total_time: yup.number().defined(),
		user_id: yup.string().defined(),
	});

	const isValidData = (input: NonNullable<typeof data>[0]): input is yup.InferType<typeof topReasonsSchema> => topReasonsSchema.isValidSync(input);

	return data
		?.filter(isValidData)
		.map((entry): ReasonWithDescription => ({
			activity: { id: entry.activity_id, name: entry.activity_name, archived: null },
			activityId: entry.activity_id,
			client: { id: entry.client_id, name: entry.client_name, isInternal: false },
			clientId: entry.client_id,
			project: entry.project_id && entry.project_name ? { id: entry.project_id, clientId: entry.client_id, name: entry.project_name } : null,
			projectId: entry.project_id ?? null,
		})) ?? [];
};
