import { getTopReasons } from '@/api/topReasons';
import { UserModel } from '@/models/User';
import { useAuthentication } from '@/store/auth';
import { keepPreviousData, useQuery } from '@tanstack/vue-query';
import { MaybeRef, computed, unref } from 'vue';

export interface TopReasonsSearchParams {
	user: UserModel | null;
}

export const useTopReasonsQuery = (
	searchParams: MaybeRef<TopReasonsSearchParams>,
) => {
	const { isAuthenticated } = useAuthentication();

	const enabled = computed(() => isAuthenticated && unref(searchParams).user !== null);

	const query = useQuery({
		queryKey: ['topReasons', searchParams],
		queryFn: () => {
			const { user } = unref(searchParams);

			if (!user) {
				return Promise.reject();
			}

			return getTopReasons(user.id);
		},
		enabled,
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: true,
	});

	return query;
};
