import { Client } from './Client';
import { Project } from './Project';

export interface FirstLevelOption {
	value: string;
	label: string;
	client: Client;
	project: Project | null;
}

export const fromClientAndMaybeProject = (client: Client, project?: Project | null): FirstLevelOption => ({
	value: project ? `${client.id},${project.id}` : client.id,
	label: project ? `${project.name}, ${client.name}` : client.name,
	client,
	project: project ?? null,
});
