import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { err, ok } from 'neverthrow';
import { createNewQuota, updateQuotaById, deleteQuotaById } from '@/api/quota';
import {
	Quota, QuotaMeta, QuotaSubmitData, createQuotaFromSupabase, toSupabaseData,
} from '@/models/Quota';
import { UserModel } from '@/models/User';
import { Client } from '@/models/Client';
import { Project } from '@/models/Project';

export const useQuotasMutation = () => {
	const queryClient = useQueryClient();

	const getProjectQuotaInfoId = (project: Project) => project.quotaInfoId;
	const getClientQuotaInfoId = (client: Client) => client.quotaInfoId;

	const createQuota = useMutation({
		async mutationFn(
			variables: {
				quota: QuotaSubmitData,
				client: Client,
				project?: Project,
				user: UserModel,
			},
		) {
			const {
				project, client, quota, user,
			} = variables;

			const quotaInfoId = project
				? getProjectQuotaInfoId(project)
				: getClientQuotaInfoId(client);

			if (!quotaInfoId) {
				return err({ message: 'quotaInfoId can not be null' });
			}

			const response = await createNewQuota({
				...quota,
				quotaInfoId,
				userId: user.id,
			});

			if (response.error) {
				return err(response.error);
			}

			return ok(response.data);
		},
		onSuccess(result, data) {
			if (result.isErr()) {
				return result;
			}

			return Promise.all([
				queryClient.invalidateQueries({ queryKey: ['quotas', { clientId: data.client.id }] }),
				queryClient.invalidateQueries({ queryKey: ['quotas', { quotaInfoId: result.value.quota_info_id }] }),
				queryClient.invalidateQueries({ queryKey: ['quotaInfo', { quotaInfoId: result.value.quota_info_id }] }),
			]);
		},
	});

	const deleteQuota = useMutation({
		async mutationFn(
			variables: {
				quota: Quota,
			},
		) {
			const response = await deleteQuotaById(variables.quota.quotaId);

			if (response.error) {
				return err(response.error);
			}

			return ok(variables.quota);
		},
		onSuccess(result, data) {
			if (result.isErr()) {
				return result;
			}

			return Promise.all([
				queryClient.invalidateQueries({ queryKey: ['quotas', { quotaInfoId: data.quota.quotaInfoId }] }),
				queryClient.invalidateQueries({ queryKey: ['quotaInfo', { quotaInfoId: data.quota.quotaInfoId }] }),
			]);
		},
	});

	const updateQuota = useMutation({
		async mutationFn(
			variables: {
				quotaId: string,
				quota: QuotaMeta,
			},
		) {
			const response = await updateQuotaById(variables.quotaId, toSupabaseData(variables.quota));

			if (response.error) {
				return err(response.error);
			}

			return ok(createQuotaFromSupabase(response.data, { is_extra: variables.quota.isExtra, quota_info_id: variables.quota.quotaInfoId }));
		},
		onSuccess(result, data) {
			if (result.isErr()) {
				return result;
			}

			return Promise.all([
				queryClient.invalidateQueries({ queryKey: ['quotas', { quotaInfoId: data.quota.quotaInfoId }] }),
				queryClient.invalidateQueries({ queryKey: ['quotaInfo', { quotaInfoId: data.quota.quotaInfoId }] }),
			]);
		},
	});

	return {
		createQuota,
		deleteQuota,
		updateQuota,
	};
};
