<template>
	<SidepanelWrapper>
		<SidepanelNavigation
			class="mb-10"
			:navigation="navigation"
		/>
		<RouterView
			v-if="client"
			:client="client"
		/>
	</SidepanelWrapper>
</template>

<script setup lang="ts">
import SidepanelNavigation from '@/components/SidepanelNavigation.vue';
import SidepanelWrapper from '@/components/SidepanelWrapper.vue';
import { SidepanelNavigationItem } from '@/models/SidepanelNavigationItem';
import { useClientsStore } from '@/store/clients';
import { computed } from 'vue';

const props = defineProps({
	clientId: {
		type: String,
		required: true,
	},
});

const clientsStore = useClientsStore();

const client = computed(() => clientsStore.allClients.find((_client) => _client.id === props.clientId));

const navigation: SidepanelNavigationItem[] = [
	{
		name: 'General',
		to: { name: 'ClientGeneral' },
	},
	{
		name: 'Quotas',
		to: { name: 'ClientQuotas' },
	},
];
</script>
