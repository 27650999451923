import { MaybeRef, computed, unref } from 'vue';
import { useAuthentication } from '@/store/auth';
import { keepPreviousData, useQuery } from '@tanstack/vue-query';
import { fetchQuotaBeats } from '@/api/quotaBeats';
import { DateTime } from 'luxon';

export interface QuotaBeatsSearchParams {
	fromDate: MaybeRef<DateTime>;
	excludedTeams?: MaybeRef<string[]>;
}

export const useQuotaBeatsQuery = (
	searchParams: QuotaBeatsSearchParams,
) => {
	const { isAuthenticated } = useAuthentication();

	const enabled = computed(() => isAuthenticated);

	const query = useQuery({
		queryKey: ['quota_beats', searchParams],
		queryFn: async () => {
			const fromDate = unref(searchParams.fromDate);
			return fetchQuotaBeats(fromDate, unref(searchParams.excludedTeams) ?? []);
		},
		enabled,
		initialData: [],
		placeholderData: (previousData) => keepPreviousData(previousData ?? []),
		refetchOnWindowFocus: false,
		refetchInterval: 1800000,
		refetchIntervalInBackground: true,
	});

	return query;
};
