// ------------- Convert Values -------------

import { DateTime } from 'luxon';

export const displayTimeToMs = (timeIntervalAsString: string) => {
	const splitTime: string[] = timeIntervalAsString.split(':');
	const timeHours = Math.ceil(parseInt(splitTime[0], 10) * 60 * 60 * 1000);
	const timeMinutes = Math.ceil(parseInt(splitTime[1], 10) * 60 * 1000);
	const timeIntervalInMs = Math.ceil(timeHours + timeMinutes);

	return timeIntervalInMs;
};

export const msToDisplayTime = (duration: number, minHourDigits = 2) => {
	const minutes = Math.floor((duration / (1000 * 60)) % 60);
	const hours = Math.floor((duration / (1000 * 60 * 60)));

	const minutesAsString = String(minutes).padStart(2, '0');
	const hoursAsString = String(hours).padStart(minHourDigits, '0');

	return `${hoursAsString}:${minutesAsString}`;
};

export const secondsToLocaleTimeFormat = (seconds: number) => DateTime.fromSeconds(seconds).toLocaleString(DateTime.TIME_SIMPLE);
export const dateTimeToLocaleTimeFormat = (dateTime: DateTime) => dateTime.toLocaleString(DateTime.TIME_SIMPLE);

export const hourToMs = (hours: number): number => hours * 60 * 60 * 1000;
export const msToHourOnly = (timeInMs: number): number => parseInt(msToDisplayTime(timeInMs).split(':')[0], 10);

export const msToHours = (milliseconds: number) => milliseconds / 3600000;

export const minutesToMs = (timeInMinutes: number): number => timeInMinutes * 60 * 1000;
export const msToMinutes = (timeInMs: number): number => parseInt(msToDisplayTime(timeInMs).split(':')[1], 10);

export const convertTimestampToDate = (seconds: number): Date => new Date(seconds * 1000);

export const hoursToDisplayTime = (hours: number) => msToDisplayTime(hourToMs(hours));

export const minutesToDisplayTime = (minutes: number) => msToDisplayTime(minutesToMs(minutes));
