import { useAuthentication } from '@/store/auth';
import { useQuery } from '@tanstack/vue-query';
import {
	MaybeRef, computed, unref,
} from 'vue';
import { DateTime } from 'luxon';
import { getUserWorkSchedule } from '@/api/absence';

export interface UserWorkScheduleSearchParams {
	date: DateTime;
}

export const useUserWorkScheduleQuery = (
	searchParams: MaybeRef<UserWorkScheduleSearchParams>,
) => {
	const auth = useAuthentication();
	const enabled = computed(() => auth.isAuthenticated);

	return useQuery({
		queryKey: [
			'userWorkSchedule',
			{ date: computed(() => unref(searchParams).date) },
		],
		queryFn: async () => {
			const { date } = unref(searchParams);

			if (!date) {
				return Promise.reject();
			}

			const userWorkSchedule = await getUserWorkSchedule(date);

			if (!userWorkSchedule) {
				return Promise.reject();
			}

			return userWorkSchedule;
		},
		enabled,
		initialData: [],
		refetchOnWindowFocus: false,
	});
};
