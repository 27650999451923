import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { err, ok } from 'neverthrow';
import { UserModel } from '@/models/User';
import { AbsenceMeta } from '@/models/Absence';
import { createNewAbsence, deleteAbsence as deleteAbsenceApi } from '@/api/absence';

export const useAbsencesMutation = () => {
	const queryClient = useQueryClient();

	const createAbsence = useMutation({
		async mutationFn(
			variables: {
				absence: AbsenceMeta,
				user: UserModel,
			},
		) {
			try {
				const response = await createNewAbsence(variables.absence, variables.user);

				return ok(response);
			} catch (error) {
				return err(error);
			}
		},
		onSuccess(result) {
			if (result.isErr()) {
				return null;
			}

			return Promise.all([
				queryClient.invalidateQueries({ queryKey: ['absences'] }),
			]);
		},
	});

	const deleteAbsence = useMutation({
		async mutationFn(
			variables: {
				absenceId: string,
			},
		) {
			try {
				const response = await deleteAbsenceApi(variables.absenceId);

				return ok(response);
			} catch (error) {
				return err(error);
			}
		},
		onSuccess(result) {
			if (result.isErr()) {
				return null;
			}

			return Promise.all([
				queryClient.invalidateQueries({ queryKey: ['absences'] }),
			]);
		},
	});

	return {
		createAbsence,
		deleteAbsence,
	};
};
