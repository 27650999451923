import { computed } from 'vue';
import { MINUTES_STEPS_IN_MS } from '../utilities/Constants';
import { displayTimeToMs, msToDisplayTime, msToMinutes } from '../shared/times';

export interface Coordinates {
	x: number;
	y: number;
}

export const arrayOfMinutes = computed(() => {
	const isMinuteStepFromHour = (time: number): boolean => time % msToMinutes(MINUTES_STEPS_IN_MS) === 0;
	const arr = [];

	for (let i = 0; i < 60; i++) {
		if (isMinuteStepFromHour(i)) {
			arr.push(i);
		}
	}

	return arr.reverse();
});

export const arrayOfCoordinateY = (graphStepY: number) => {
	const arrayOfCoordinate = [];

	for (let i = 0; i < arrayOfMinutes.value.length; i++) {
		arrayOfCoordinate.push(i);
	}

	const result = arrayOfCoordinate.map((x) => x * graphStepY);

	return result;
};

export const minutesToPosition = (displayTime: number, graphStepY: number) => {
	const indexFromMinutes = arrayOfMinutes.value.indexOf(displayTime);

	const arr = [];
	for (let i = 0; i < arrayOfMinutes.value.length; i++) {
		arr.push(i);
	}

	const result = arr.map((x) => x * graphStepY);

	return result[indexFromMinutes];
};

export const positionToMinutes = (coordinate: number, graphStep: number) => {
	const arrayOfCoordinate = [];

	for (let i = 0; i < arrayOfMinutes.value.length; i++) {
		arrayOfCoordinate.push(i);
	}

	const result = arrayOfCoordinate.map((x) => x * graphStep);
	const indexFromCoordinate = result.indexOf(coordinate);

	return arrayOfMinutes.value[indexFromCoordinate];
};

export const displayTimeToCoordinates = (displayTime: string, graphStep: number) => {
	const getCoordinateX = (time: string) => {
		const value = Math.round(Number(time.split(':')[0]) * graphStep);
		return value;
	};

	const getCoordinateY = (time: string) => {
		const value = minutesToPosition(parseInt(time.split(':')[1], 10), graphStep);
		return value;
	};

	const x = getCoordinateX(displayTime);
	const y = getCoordinateY(displayTime);

	return { x, y };
};

export const msToCoordinates = (ms: number, graphStepX: number, graphStepY: number) => {
	const { x } = displayTimeToCoordinates(msToDisplayTime(ms), graphStepX);
	const { y } = displayTimeToCoordinates(msToDisplayTime(ms), graphStepY);

	return { x, y };
};

export const coordinatesToMs = (coordinates: Coordinates, graphStepX: number, graphStepY: number) => {
	const ms = displayTimeToMs(`0${Math.round(coordinates.x / graphStepX)}:${positionToMinutes(coordinates.y, graphStepY)}`);
	return ms;
};
