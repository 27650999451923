<template>
	<div>
		<div
			v-if="hasAbsence"
			class="mb-8"
		>
			<div
				v-if="hasAbsence"
				class="font-medium tracking-wider bg-gray p-4 rounded-2xl text-white text-opacity-100 hidden desktop:block"
			>
				{{ `Note: You are set as absent on
				${mainStore.today.toFormat('ccc')},
				${mainStore.today.toFormat('DDD')}.` }}
			</div>
		</div>
		<WidgetTimeTrack />
		<div class="grid middle:grid-cols-12 space-y-1 desktop:space-y-0 desktop:space-x-0">
			<WidgetTodaysLog
				class="desktop:col-span-6 middle:col-span-8 desktop:border-r border-solid border-primary-300"
				@beat-edited="onBeatEdited"
			/>
			<WidgetStats class="desktop:col-span-6 middle:col-span-4" />
		</div>
	</div>
</template>

<script setup lang="ts">
import {
	onMounted, computed,
} from 'vue';
import { DateTime } from 'luxon';
import { useUnsavedChanges } from '@/composables/useUnsavedChanges';
import { useRouteLeaveConfirmation } from '@/composables/useRouteLeaveConfirmation';
import { useAbsencesStore } from '@/store/absences';
import { useRoute, useRouter } from 'vue-router';
import { useShareableModal } from '@/composables/useShareableModal';
import { getSharedBeatByShortcode } from '@/api/sharedBeats';
import { usePlausible } from '../plugins/plausible/usePlausible';
import { useMainStore } from '../store/main';
import WidgetTimeTrack from '../components/WidgetTimeTrack.vue';
import WidgetTodaysLog from '../components/WidgetTodaysLog.vue';
import WidgetStats from '../components/WidgetStats.vue';

const mainStore = useMainStore();
const absencesStore = useAbsencesStore();
const route = useRoute();
const router = useRouter();

// Absence Feature
// Checks if active date you are on, is matching any existing absence
const hasAbsence = computed(() => absencesStore.absences.some((absenceData) => absenceData.date === mainStore.today.toISODate()));

const plausible = usePlausible();

const onBeatEdited = () => {
	plausible.trackEvent('edit-log', { props: { source: 'dashboard' } });
};

const urlParams = route.query;

const sharedBeatModal = useShareableModal();

onMounted(async () => {
	mainStore.today = DateTime.now();

	if (urlParams.sharedBeatShortCode) {
		const sharedBeat = await getSharedBeatByShortcode(urlParams.sharedBeatShortCode.toString());
		await sharedBeatModal.show(sharedBeat);
		router.push({ name: 'Dashboard' });
	}
});

const { hasAnyUnsavedChanges } = useUnsavedChanges();

useRouteLeaveConfirmation({
	modal: {
		title: 'Warning: There are some unsaved changes',
		description: 'Are you sure you want to exit without saving them?',
		confirmLabel: 'Exit without saving',
		cancelLabel: 'Go back',
	},
	showModal: hasAnyUnsavedChanges,
});
</script>
