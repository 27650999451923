<template>
	<button
		class="py-4 px-10 bg-primary-300 cursor-pointer w-full p-2 rounded-2xl"
		type="button"
	>
		<slot />
	</button>
</template>

<script setup lang="ts">

</script>
