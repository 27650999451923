import { supabase } from '@/lib/supabase';
import {
	QuotaMeta, SupabaseQuota, toSupabaseData,
} from '../models/Quota';
import { updateQuotaInfoIsExtra } from './quotaInfo';

export const selectQuotasByInfoId = async (quotaInfoId: string) => supabase
	.from('quotas')
	.select('*')
	.eq('quota_info_id', quotaInfoId);

export const selectProjectsQuotasByClientId = async (clientId: string) => supabase
	.from('projects')
	.select('client_id, quota_infos(*, quotas(*))')
	.eq('archived', false)
	.eq('client_id', clientId);

export const deleteQuotaById = async (quotaId: string) => supabase
	.from('quotas')
	.delete()
	.eq('quota_id', quotaId);

export const updateQuotaById = async (quotaId: string, properties: Partial<SupabaseQuota>) => supabase
	.from('quotas')
	.update(properties)
	.eq('quota_id', quotaId)
	.select()
	.single();

export const createNewQuota = async (meta: QuotaMeta) => {
	updateQuotaInfoIsExtra(meta.quotaInfoId, meta.isExtra);

	const supabaseQuota = toSupabaseData(meta);
	return supabase
		.from('quotas')
		.insert(
			supabaseQuota,
		)
		.select()
		.single();
};
