import { useUserSettingsMutation } from '@/composables/queries/useUserSettingsMutation';
import { useUserSettingsQuery } from '@/composables/queries/useUserSettingsQuery';
import { Activity } from '@/models/Activity';
import { UserSettings } from '@/models/UserSettings';
import { useAuthentication } from '@/store/auth';
import { computed } from 'vue';

export const useUserSettings = () => {
	const authStore = useAuthentication();

	const { data } = useUserSettingsQuery();

	const {
		updateAllUserSettings: updateAllUserSettingsMutation,
		updateUserActivityRanking: updateUserActivityRankingMutation,
	} = useUserSettingsMutation();

	const updateUserSettings = (settings: Partial<UserSettings>) => {
		if (!authStore.user || !data.value) {
			return Promise.reject();
		}

		const mergedSettings = { ...data.value, ...settings };

		return updateAllUserSettingsMutation.mutateAsync({ userId: authStore.user.id, settings: mergedSettings });
	};

	const logNotifications = computed(() => data.value?.logNotifications ?? []);

	const updateUserActivityRanking = (activities: Activity[]) => {
		if (!authStore.user) {
			return Promise.reject();
		}

		return updateUserActivityRankingMutation.mutateAsync({ userId: authStore.user.id, activities });
	};

	return {
		settings: data,
		updateUserSettings,
		updateUserActivityRanking,
		logNotifications,
	};
};
