<template>
	<button
		class="flex gap-5 items-center w-fit disabled:opacity-50 cursor-pointer disabled:cursor-default outline-none"
		:class="{ 'flex-row-reverse': positionReverse }"
		:disabled="readonly"
		:tabindex="-1"
		type="button"
		@click="onClick"
	>
		<button
			class="group transition w-18 h-10 rounded-full flex items-center justify-center pointer-events-none focus:outline focus:outline-gray-500 focus:outline-1 outline-offset-2"
			:class="{
				'bg-white/10': !modelValue,
				'bg-primary-100': modelValue,
			}"
			:disabled="readonly"
			type="button"
			:aria-disabled="readonly"
			:aria-checked="modelValue"
			role="switch"
		>
			<div
				class="w-7 h-7 rounded-full transform transition bg-white"
				:class="{
					'-translate-x-[15px]': !modelValue,
					'translate-x-[15px]': modelValue,
				}"
			/>
		</button>
		<div
			class="text-xl font-bold text-current"
			:class="[{ 'cursor-pointer': !readonly }]"
		>
			<slot>
				{{ label }}
			</slot>
		</div>
	</button>
</template>

<script lang="ts" setup>
const props = defineProps({
	modelValue: {
		type: Boolean,
		required: true,
	},
	readonly: {
		type: Boolean,
		default: false,
	},
	positionReverse: {
		type: Boolean,
		default: false,
	},
	label: {
		type: String,
		default: '',
	},
});

const emit = defineEmits(['update:modelValue']);

const onClick = () => {
	if (props.readonly) {
		return;
	}
	emit('update:modelValue', !props.modelValue);
};
</script>
