import { AuthUser } from '@/models/AuthUser';
import { supabase } from '@/lib/supabase';
import { UserSettings } from '@/models/UserSettings';
import { Activity } from '@/models/Activity';
import { Database } from '@/models/Supabase';
import { UserData } from '../models/User';

export const getUserDataById = async (userId: string): Promise<UserData> => {
	const { data } = await supabase
		.from('users')
		.select('name, user_id, email')
		.eq('user_id', userId)
		.limit(1)
		.single();

	if (!data) {
		return Promise.reject();
	}

	return { email: data.email, user: data.name, userId: data.user_id };
};

export const getUsers = async () => supabase
	.from('users')
	.select('*')
	.order('name');

export const createUserData = async (authUser: AuthUser): Promise<boolean> => {
	const doesUserExist = await getUserDataById(authUser.id)
		.then(() => true)
		.catch(() => false);

	if (!doesUserExist) {
		await supabase
			.from('users')
			.insert({
				email: authUser.email,
				name: authUser.displayName,
				user_id: authUser.id,
			});
	}

	return true;
};

export const getUserSettings = () => supabase
	.from('user_settings')
	.select('*')
	.maybeSingle();

export const getUserNotificationSettings = async () => {
	const { data } = await supabase
		.from('log_notification_settings')
		.select('*');

	return data ?? [];
};

export const updateSettingsForUser = (userId: string, settings: UserSettings) => supabase
	.from('user_settings')
	.upsert({
		user_id: userId,
		log_notifications_enabled: settings.logNotificationsEnabled,
		voice: settings.logNotificationVoice,
		activity_ordering: settings.activityOrdering,
	})
	.eq('user_id', userId)
	.select()
	.single();

export const updateNotificationSettingsForUser = async (userId: string, settings: UserSettings) => {
	await supabase.from('log_notification_settings').delete().eq('user_id', userId);

	if (!settings.logNotifications.length) {
		return [];
	}

	const { data } = await supabase
		.from('log_notification_settings')
		.insert(settings.logNotifications.map((notification) => ({ time: notification.time, user_id: userId })))
		.eq('user_id', userId)
		.select();

	return data ?? [];
};

export const updateActivityOrderForUser = async (userId: string, activities: Activity[]) => {
	const activitiesWithUserIdAndRank = activities.map<Database['public']['Tables']['user_settings_activity_rank']['Row']>((activity, index) => ({
		activity_id: activity.id,
		rank: index,
		user_id: userId,
	}));

	/**
     * We first need to delete all rankings for this specific users, because the upsert/insert
     * will throw an error if the rank is already in use even though it would be changed with the upsert
     */
	await supabase
		.from('user_settings_activity_rank')
		.delete()
		.eq('user_id', userId);

	return supabase
		.from('user_settings_activity_rank')
		.upsert(activitiesWithUserIdAndRank);
};

export const getUserFavoriteClients = () => supabase
	.from('user_settings_favorite_clients')
	.select('*');

export const getUserFavoriteProjects = () => supabase
	.from('user_settings_favorite_projects')
	.select('*');

export const addClientToUserFavorites = (userId: string, clientId: string) => supabase
	.from('user_settings_favorite_clients')
	.insert({ user_id: userId, client_id: clientId });

export const addProjectToUserFavorites = (userId: string, projectId: string) => supabase
	.from('user_settings_favorite_projects')
	.insert({ user_id: userId, project_id: projectId });

export const removeClientFromUserFavorites = (userId: string, clientId: string) => supabase
	.from('user_settings_favorite_clients')
	.delete()
	.eq('user_id', userId)
	.eq('client_id', clientId);

export const removeProjectFromUserFavorites = (userId: string, projectId: string) => supabase
	.from('user_settings_favorite_projects')
	.delete()
	.eq('user_id', userId)
	.eq('project_id', projectId);
