<template>
	<div class="w-10 h-10 bg-primary-400 rounded-full flex items-center justify-center">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="15.58"
			height="12"
			viewBox="0 0 23.773 18"
		>
			<path
				id="check-light_1_"
				data-name="check-light (1)"
				class="w-4 h-3 fill-primary-100"
				d="M24.761,88.625l-15.04,15.04L4.4,98.348a.645.645,0,0,0-.912,0l-.608.608a.645.645,0,0,0,0,.912l6.381,6.381a.645.645,0,0,0,.912,0l16.1-16.1a.645.645,0,0,0,0-.912l-.608-.608a.645.645,0,0,0-.911,0Z"
				transform="translate(-2.697 -88.437)"
			/>
		</svg>
	</div>
</template>

<script setup lang="ts">
</script>
