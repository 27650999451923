import { errAsync, okAsync, ResultAsync } from 'neverthrow';
import * as Sentry from '@sentry/vue';
import axios from 'axios';

interface AppVersion {
	version: string;
}

const APP_VERSION: string = __COMMIT_REF__ || 'undefined';

export const createAppVersionChecker = ({ interval }: { interval: number }) => {
	let lastVersionCheck: number;

	const getCurrentAppVersion = (): ResultAsync<AppVersion, Error> => {
		const data: Promise<AppVersion> = axios.get('/.netlify/functions/version').then((responseData) => responseData.data);
		lastVersionCheck = Date.now();

		return ResultAsync.fromPromise(data, (error) => {
			if (!(error instanceof Error)) {
				return new Error('An unexpected error occured while getting the current app version');
			}

			return error;
		}).andThen((response) => {
			if (!response.version) {
				return errAsync(new Error('No version defined in version.json'));
			}
			return okAsync(response);
		});
	};

	const canCheckVersion = () => Date.now() - lastVersionCheck < interval;

	const isNewerVersionAvailable = async (enforce = false): Promise<boolean> => {
		if (!enforce && !canCheckVersion()) {
			return false;
		}

		const currentAppVersionResult = await getCurrentAppVersion();

		if (currentAppVersionResult.isErr()) {
			Sentry.withScope((scope) => {
				scope.setLevel('warning');
				scope.setTransactionName('Could not get current app version');
				scope.setFingerprint(['AppVersionError']);
				Sentry.captureException(currentAppVersionResult.error);
			});

			return false;
		}

		Sentry.addBreadcrumb({
			category: 'AppVersionCheck',
			message: `Used app version is '${APP_VERSION}' and the version on the server is '${currentAppVersionResult.value.version}'`,
			level: 'info',
		});

		return currentAppVersionResult.value.version !== APP_VERSION;
	};

	const ensureLatestAppVersion = async (location: string): Promise<boolean> => {
		Sentry.addBreadcrumb({
			category: 'AppVersionCheck',
			message: 'Call function to check current app version',
			level: 'info',
		});

		return new Promise((resolve) => {
			isNewerVersionAvailable()
				.then((isNewVersionAvailable) => {
					Sentry.addBreadcrumb({
						category: 'AppVersionCheck',
						message: isNewVersionAvailable ? 'The app version was not up to date' : 'The app version is up to date',
						level: isNewVersionAvailable ? 'warning' : 'info',
					});

					if (isNewVersionAvailable) {
						window.location.replace(location);
						return;
					}

					resolve(true);
				});
		});
	};

	return {
		isNewerVersionAvailable,
		ensureLatestAppVersion,
	};
};
