import {
	type ComponentInternalInstance,
	getCurrentInstance,
	type Ref,
} from 'vue';
import {
	getOptionValue,
	getOptionLabel,
	type SelectOption,
} from '../models/Select';

export const useSelect = (currentValue: Ref<SelectOption | null>) => {
	const { emit } = getCurrentInstance() as ComponentInternalInstance;

	const isSelected = (option: SelectOption) => (currentValue.value
		? getOptionValue(option) === getOptionValue(currentValue.value)
		: false);

	const onSelectOption = (select: boolean, option: SelectOption) => {
		emit('update:modelValue', getOptionValue(option));
	};

	const toggleOption = (option: SelectOption) => {
		const isOptionSelected = isSelected(option);
		onSelectOption(!isOptionSelected, option);
	};

	return {
		getOptionValue,
		getOptionLabel,
		isSelected,
		onSelectOption,
		toggleOption,
	};
};
