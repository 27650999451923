import { supabase } from '@/lib/supabase';
import { TimeToBeatEntry } from '@/models/TimeToBeat';

export const createTimeToBeatTrackingEntry = async (entry: TimeToBeatEntry) => supabase
	.schema('tracking')
	.from('time_to_beat')
	.insert({
		duration_seconds: entry.durationInSeconds,
		type: entry.type,
	});
