import { DateTime, Duration } from 'luxon';
import { Result, ResultAsync } from 'neverthrow';
import { z } from 'zod';

export const percentageRatio = (total: number, partOfTotal: number) => (total <= 0 ? false : Math.round((partOfTotal * 100) / total));

export const firstGlyph = (input: string) => (input.match(/^[0-9a-zA-Z]/) ?? [false])[0];

export const groupBy = <T>(array: T[], key: keyof T & string): { [key: string]: T[] } => array.reduce((result, item) => {
	const groupKey = `${item[key]}`;

	return {
		...result,
		[groupKey]: [...(result[groupKey] || []), item],
	};
}, {} as { [key: string]: T[] });

export const isTrueValue = (string: string): boolean => string.toLowerCase() === 'true';

export const getFilteredArrayWithSameObjectKeyValues = <T, K extends keyof T>(
	array1: T[],
	array2: ({ [key in K]: T[K] })[],
	id: K,
): T[] => array1.filter((object1) => array2.find((object2) => object1[id] === object2[id]));

export const isTurnOfYearWithinPastPeriod = (duration: Duration, startDate?: DateTime): Boolean => {
	const _date = startDate ?? DateTime.now();
	return _date.startOf('day').minus(duration).year !== _date.year;
};

export const getUsedOperatorSystem = (navApp: any) => {
	let systemName = 'Not known';
	if (navApp.indexOf('Win') !== -1) {
		systemName = 'Windows OS';
	}
	if (navApp.indexOf('Mac') !== -1) {
		systemName = 'MacOS';
	}
	if (navApp.indexOf('X11') !== -1) {
		systemName = 'UNIX OS';
	}
	if (navApp.indexOf('Linux') !== -1) {
		systemName = 'Linux OS';
	}
	return systemName;
};

export const removeEmptyProperties = <T extends {}>(object: T): T => {
	const objectCopy = { ...object };

	Object.keys(objectCopy).forEach((key) => {
		if (objectCopy[key as keyof T] === undefined) {
			delete objectCopy[key as keyof T];
		}
	});

	return objectCopy;
};

export const isWeekendDayInDateTime = (weekdayNumber: number) => weekdayNumber === 6 || weekdayNumber === 7;

export const isWeekendDayInCalendar = (weekdayNumber: number) => weekdayNumber === 1 || weekdayNumber === 7;

export const maxLetterLength = (string: string, maxLength: number) => {
	if (string.length > maxLength) {
		return `${string.substring(0, maxLength)}...`;
	}
	return string;
};

export const getDiffBetweenTwoDates = (isoDateStart: string, isoDateEnd: string): number|null => {
	const start = DateTime.fromISO(isoDateStart);
	const end = DateTime.fromISO(isoDateEnd);
	const diff = end.diff(start, 'days').toObject();
	if (!diff.days) {
		return null;
	}
	return diff.days;
};

export const toInt = (string: string) => parseInt(string, 10);

export const highlight = (value: string, searchValue: string) => {
	const result = value;
	if (searchValue) {
		const matchPos = result.toLowerCase().indexOf(searchValue.toLowerCase());
		if (matchPos > -1) {
			const matchStr = result.substring(matchPos, matchPos + searchValue.length);
			return result.replace(
				matchStr,
				`<span style="font-weight: bold; background-color: #733BFF;">${matchStr}</span>`,
			);
		}
	}
	return result;
};

export const getDateTime = (date: DateTime | Date | string): DateTime => {
	if (DateTime.isDateTime(date)) {
		return date;
	}

	if (typeof date === 'string') {
		return DateTime.fromFormat(date, 'dd.LL.yyyy');
	}

	return DateTime.fromJSDate(date);
};

export const wrapResultAsyncFunction = <A extends any[], T, E>(
	func: (...args: A) => Promise<Result<T, E>>,
): (...args: A) => ResultAsync<T, E> => (...args): ResultAsync<T, E> => new ResultAsync(func(...args));

export const getSafeArray = <T>(input: T[] | undefined): T[] => input ?? [];

export const notEmpty = <TValue>(
	value: TValue | null | undefined,
): value is TValue => value !== null && value !== undefined;

export const luxonDateTimeSchema = () => z.custom<DateTime>(DateTime.isDateTime, { message: 'Invalid Luxon DateTime' });

export const getNearestLowerMultipleOf = (input: number, divisor: number): number => {
	const remainder = input % divisor;

	if (remainder === 0) {
		return input;
	}

	return input - remainder;
};

export const getSortedTimeStrings = (timeArray: string[]): string[] => timeArray
	.map((time) => {
		const [hour = 0, minute = 0] = time.split(':').map((value) => parseInt(value, 10));
		return DateTime.now().set({ hour, minute });
	})
	.toSorted((a, b) => a.toMillis() - b.toMillis())
	.map((value) => value.toLocaleString({ hour: '2-digit', minute: '2-digit', hourCycle: 'h24' }));
