<template>
	<div>
		<div class="grid gap-8 fullhd:flex fullhd:gap-4 fullhd:items-center">
			<div class="p-3 bg-primary-400 w-fit rounded-full">
				<div
					class="h-60 w-60 relative"
				>
					<div class="absolute top-0 z-20 inset-0 flex items-center justify-center pointer-events-none">
						<div class="transform h-[122px] mb-[108px] w-1.5 origin-bottom rotate-[288deg]">
							<div class="h-[74px] bg-white" />
						</div>
					</div>
					<div
						v-if="selectedData"
						class="absolute top-0 z-20 inset-0 flex items-center flex-col justify-center pointer-events-none"
					>
						<p class="text-sm">
							{{ selectedData.label }}
						</p>
						<div class="text-2xl font-bold flex">
							<p
								:key="selectedData.type"
								v-animated-number:[formatNumber].single.skip="selectedData.value"
							/>%
						</div>
						<SatisfactionHappy
							class="mt-0.5 transition-all duration-300"
							:class="{
								'w-0': selectedData.value < 80,
								'w-5': selectedData.value >= 80,
							}"
						/>
					</div>
					<VChart
						class="relative z-10"
						:option="option"
						@click="(params) => setStatsOnMouseOver(params as LoggedTimeECElementEvent)"
					/>
					<VChart
						class="absolute top-0 z-0"
						:option="backgroundOption"
					/>
				</div>
			</div>
			<div class="fullhd:block grid grid-cols-2 gap-y-4 fullhd:space-y-2  gap-x-8">
				<div class="flex items-center gap-3">
					<div class="bg-white h-5 w-8 rounded-full" />
					<p class="whitespace-nowrap fullhd:w-16">
						80% Goal
					</p>
				</div>
				<div
					v-for="entry in data"
					:key="entry.type"
					class="flex items-center gap-3"
				>
					<div
						class="h-5 w-8 rounded-full"
						:style="{ backgroundColor: entry.color }"
					/>
					<p class="whitespace-nowrap fullhd:w-16">
						{{ entry.label }}
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { PropType, computed, ref } from 'vue';
import VChart from 'vue-echarts';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart } from 'echarts/charts';
import {
	LoggedTimeStatsItem, LoggedTimeECElementEvent,
} from '@/composables/useLoggedTimeStatsChart';
import { EChartsOption } from 'echarts';
import { LoggedDataType } from '@/models/LoggedData';
import SatisfactionHappy from '../assets/satisfaction-happy.svg?component';
import vAnimatedNumber from '../plugins/animatedNumberDirective';

use([
	CanvasRenderer,
	PieChart,
]);

const props = defineProps({
	data: {
		type: Array as PropType<LoggedTimeStatsItem[]>,
		default: () => ([]),
	},
});

const selectedDataType = ref<LoggedDataType>('user');

const selectedData = computed(() => props.data.find((item) => item.type === selectedDataType.value));

const setStatsOnMouseOver = (params: LoggedTimeECElementEvent) => {
	selectedDataType.value = params.data.name;
};

const formatNumber = (value: number) => Math.round(value);

const getRadiusByIndex = (index: number) => [`${94 - (26 * (index - 1))}`, `${72 - (26 * (index - 1))}`];

const option = computed<EChartsOption>(() => ({
	series: props.data.map((config, index) => ({
		type: 'pie',
		radius: getRadiusByIndex(index),
		label: undefined,
		itemStyle: { borderRadius: 30 },
		animationDuration: 600,
		animationDurationUpdate: 600,
		data: [
			{
				name: config.type,
				label: {
					tag: config.label,
					shown: false,
				},
				value: config.value,
				itemStyle: { color: config.color },
			},
			{
				value: 100 - config.value,
				itemStyle: {
					color: 'none',
				},
			},
		],
		emphasis: {
			scale: false,
			itemStyle: {
				opacity: 1,
				shadowColor: config.color,
				shadowBlur: 5,
			},
		},
	})),
}));

const backgroundOption = computed(() => ({
	series: props.data.map((config, index) => ({
		name: config.label,
		type: 'pie',
		radius: getRadiusByIndex(index),
		label: undefined,
		itemStyle: {
			borderRadius: 30,
			opacity: 0.4,
			color: config.color,
		},
		data: [{ value: 100 }],
		animation: false,
	})),
}));
</script>

<style lang="scss" scoped>
@keyframes expand {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}
.animation-bar {
  animation: expand 1000ms ease-in-out;
  transform-origin: left;
}
</style>
