<template>
	<div
		class="rounded-full bg-opacity-20 overflow-hidden"
		:class="themeClass"
	>
		<div
			class="h-full min-h-1 rounded-full transition-all ease-in-out duration-1000 animation-bar"
			:class="themeClass"
			:style=" { width: currentProgressInPercent } "
		/>
	</div>
</template>

<script setup lang="ts">
import { useProgress } from '@/composables/useProgress';
import type { ColorTheme } from '@/models/ColorTheme';
import { match } from 'ts-pattern';
import { type PropType, computed, toRef } from 'vue';

const props = defineProps({
	progress: {
		type: [Number, Array] as PropType<number | number[]>,
		default: 0,
	},
	theme: {
		type: String as PropType<ColorTheme>,
		default: 'success',
	},
});

const themeClass = computed(() => match(props.theme)
	.with('success', () => 'bg-success')
	.with('warning', () => 'bg-warning')
	.with('error', () => 'bg-error')
	.with('secondary', () => 'bg-secondary')
	.exhaustive());

const { currentProgressInPercent } = useProgress(toRef(props, 'progress'));
</script>
