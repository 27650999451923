import { useModal } from 'vue-final-modal';
import { Beat } from '@/models/Beats';
import { ResultAsync } from 'neverthrow';
import HowConfidentModal from '@/components/modals/HowConfidentModal.vue';
import { createConfidenceEntry } from '@/api/howConfident';
import { useTimeoutFn } from '@vueuse/core';
import { ConfidentState, millisToDuration } from '@/models/Confident';
import { useRouter } from 'vue-router';
import { DateTime } from 'luxon';

export type HowConfidentModalOptions = {
	beat: Beat | null;
}

export const useHowConfidentModal = (options: HowConfidentModalOptions) => {
	const modal = useModal({
		component: HowConfidentModal,
	});

	const router = useRouter();

	const show = (overwriteOptions?: HowConfidentModalOptions) => ResultAsync.fromPromise(new Promise((resolve) => {
		const beat = overwriteOptions?.beat || options.beat;

		if (!beat) {
			return;
		}

		const createConfidenceEntryWith = (confidence: ConfidentState) => createConfidenceEntry({
			activityId: beat.activityId,
			clientId: beat.clientId,
			projectId: beat.projectId ?? undefined,
			confidence,
			beatDuration: millisToDuration(beat.timeIntervalInMs),
			timing: beat.timestamp.hasSame(DateTime.now(), 'day') ? 'same_day' : 'past_day',
		});

		const removeNavigationGuard = router.beforeEach(() => {
			createConfidenceEntryWith('ignored');
			removeNavigationGuard();
		});

		/**
         * If the user does not interact with the modal within 10 minutes it will
         * automatically be closed and comitted as "ignored"
         */
		const { stop: stopIgnoreTimeout, start: startIgnoreTimeout } = useTimeoutFn(() => {
			createConfidenceEntryWith('ignored');
			modal.close();
		}, 1000 * 60 * 10, { immediate: false });

		modal.patchOptions({
			attrs: {
				beat,
				modalId: 'how-confident',
				onConfirm() {
					createConfidenceEntryWith('confident');
					resolve(true);
					modal.close();
				},
				onCancel() {
					createConfidenceEntryWith('dismissed');
					resolve(true);
					modal.close();
				},
				onDismiss() {
					createConfidenceEntryWith('dismissed');
					resolve(true);
					modal.close();
				},
				onUnsure() {
					createConfidenceEntryWith('unsure');
					resolve(true);
					modal.close();
				},
				onClosed() {
					stopIgnoreTimeout();
					removeNavigationGuard();
				},
				onOpened: startIgnoreTimeout,
			},
		});

		modal.open();
	}), () => new Error());

	return {
		show,
	};
};
