import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useProjectsQuery } from '@/composables/queries/useProjectsQuery';
import { useProjectsMutation } from '@/composables/queries/useProjectsMutation';
import { isProjectActive, ProjectMeta } from '../models/Project';

export const useProjectsStore = defineStore('projects', () => {
	const { data, isFetching } = useProjectsQuery();

	const allProjects = computed(() => data.value ?? []);

	const projects = computed(() => allProjects.value.filter(isProjectActive));

	const {
		createProject: createProjectMutation,
		archiveProject: archiveProjectMutation,
		updateProject: updateProjectMutation,
	} = useProjectsMutation();

	const createProject = (meta: ProjectMeta) => createProjectMutation.mutateAsync({ project: meta });

	const archiveProject = (projectId: string) => archiveProjectMutation.mutateAsync({ projectId });

	const updateProject = (projectId: string, project: ProjectMeta) => updateProjectMutation.mutateAsync({ projectId, project });

	return {
		projects,
		isFetching,
		allProjects,
		createProject,
		archiveProject,
		updateProject,
	};
});
